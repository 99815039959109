import React from 'react';
import { greys, mainColors } from '../../../styling/theme';
import { ESGChartProps } from './EsgChartsData';

const BusinessInvolvementChart = (props: ESGChartProps) => {
  const { value } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const boxHeight = value ? ((100 - value) / 100) * 24 : 0;
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="cut-off-business-involvement">
          <rect x="0" y="0" width="200" height={boxHeight} />
        </clipPath>
      </defs>

      <path
        id="business_involvement"
        d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"
        fill={greys.grey400}
      />
      <path
        id="business_involvement"
        d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"
        fill={mainColors.mainBlue_lighter}
      />

      <use
        clipPath="url(#cut-off-business-involvement)"
        xlinkHref="#business_involvement"
      />
    </svg>
  );
};

export default React.memo(BusinessInvolvementChart);
