import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Table } from '@tanstack/react-table';
import { FunctionComponent } from 'react';

export const useStyles = makeStyles(() => ({
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'flex-end',
    gap: '.25rem',
    marginLeft: 'auto',
    marginBlock: '1rem',
  },

  selector: {
    '& div': {
      padding: '.25rem',
    },
  },

  pageText: {
    textWrap: 'nowrap',
  },
  pageInputLabel: {
    position: 'absolute',
  },
}));

interface TablePaginatorProps {
  table: Table<any>;
}

const TablePaginator: FunctionComponent<TablePaginatorProps> = ({ table }) => {
  const classes = useStyles();
  const totalRows = table.getRowCount();
  const pageSizes = [];

  for (let size = 10000; size <= Math.min(totalRows, 10000); size += 10000) {
    pageSizes.push(size);
  }

  // If you need to include the total row count as the last page size (if it's not a multiple of 100)
  if (totalRows % 10000 !== 0 || totalRows > 50000) {
    pageSizes.push(totalRows);
  }

  // This is not solid logic, but it's a quick fix to avoid the page size selector showing the wrong value
  const pageValue =
    table.getState().pagination.pageSize <= 100000
      ? totalRows
      : table.getState().pagination.pageSize;

  return (
    <div className={classes.paginationContainer}>
      <FormControl fullWidth variant="standard">
        <Select
          labelId="page-selector"
          id="pageSelector"
          value={pageValue}
          label="Age"
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          className={classes.selector}
        >
          {pageSizes.map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <IconButton
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <FirstPage />
      </IconButton>

      <IconButton
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <NavigateBefore />
      </IconButton>
      <p className={classes.pageText}>
        Page {table.getState().pagination.pageIndex + 1} of{' '}
        {table.getPageCount().toLocaleString()}
      </p>
      <IconButton
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <NavigateNext />
      </IconButton>
      <IconButton
        onClick={() => table.lastPage()}
        disabled={!table.getCanNextPage()}
      >
        <LastPage />
      </IconButton>

      <FormControl className="relative" variant="standard">
        <Input
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
        />
      </FormControl>
    </div>
  );
};

export default TablePaginator;
