import NoDataMessage from '../../feedback/NoDataMessage.component';
import { SettingsComponentProps } from '../SettingsRouter.component';

export const Forbidden: React.FC<SettingsComponentProps> = () => {
  return (
    <NoDataMessage
      message="You are unauthorised to view this page. If you think this is a mistake
      please contact RiskSystem support."
    />
  );
};
