import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import EsgExposureContainer from './subComponents/EsgExposureContainer.component';
import { Grid } from '@mui/material';
import { es } from 'date-fns/locale';

export const esgRuleSortValue = (value: string): number => {
  // Return a numeric value depending on what the string starts with.
  if (value.startsWith('M-EE')) return 0;
  else if (value.startsWith('EE')) return 1;
  else if (value.startsWith('M-ES')) return 2;
  else if (value.startsWith('ES')) return 3;
  else if (value.startsWith('M-EG')) return 4;
  else if (value.startsWith('EG')) return 5;
  else if (value.startsWith('A')) return 6;
  else if (value.startsWith('P')) return 7;
  else if (value.startsWith('SFDR')) return 8;
  else return 9;
};

const ESGExposure: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  const esgData = useFetchData({
    url: `get_fund_esg_data/${fundId}/${positionDate}`,
    keyName: `${fundId}_esg_fund_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const rulesData = useFetchData({
    keyName: 'esg_code_descriptions',
    url: 'get_esg_code_descriptions',
    makeFetch: true,
  });

  // const esgDataExposureData = useFetchData({
  //   url: `category_data/esg/${fundName}/${positionDate}`,
  //   keyName: `category_data/esg/${fundName}/${positionDate}`,
  //   makeFetch: positionDate ? true : false,
  // });

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgData, rulesData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found'}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <EsgExposureContainer
          fundId={fundId}
          fundName={fundName}
          positionDate={positionDate}
          esgData={esgData}
          rulesData={rulesData}
        />
        {/* [2023-11-02] Tom Walsh - This was the old esg exposure table, can be removed completely in time if there are no issues with the new one */}
        {/* <ESGExposurePanel
          data={esgData}
          fundId={fundId}
          rulesData={rulesData}
        /> */}
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default ESGExposure;
