import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface ILiquidityFactorAnalysisData {
  asset: string;
  country: string;
  sector: string;
  strategy: string;
  valuation: number;
  discountAdjustedValue: number;
  discountAdjustedVar: number;
  discountVarAdjustedValue: number;
  liquidityFactorAdjustedValuation: number;
}

interface Props {
  data: any;
}

export const DiscountRateColumns: CustomColumn<ILiquidityFactorAnalysisData>[] =
  [
    {
      title: 'Asset',
      field: 'asset',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Country',
      field: 'country',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Sector',
      field: 'sector',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Strategy',
      field: 'strategy',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Valuation',
      field: 'valuation',
      render: (rowData) => addCommasToNumbersAndRound(rowData.valuation),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Liquidity Discount',
      field: 'discountAdjustedValue',
      render: (rowData) =>
        percentageToTwoDecimalPlaces(rowData.discountAdjustedValue),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Liquidity Discount Adjusted Valuation',
      field: 'liquidityFactorAdjustedValuation',
      render: (rowData) =>
        addCommasToNumbersAndRound(rowData.discountVarAdjustedValue),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

export function builLiquidityFactorAnalysisTableData(inputData: any) {
  if (!inputData.data.length) return [];
  if (!('table' in inputData.data[0])) return [];
  if (!inputData.data[0].discount_metrics.length) return [];
  const LiquidityFactorData = inputData.data[0].table;
  const tableData: ILiquidityFactorAnalysisData[] = [];
  // const data = inputData[0];
  // scenario: string;
  // status: string;
  // value: number;
  // tooltip: string;

  LiquidityFactorData.forEach((value: any) => {
    tableData.push({
      asset: value[0],
      country: value[1],
      sector: value[2],
      strategy: value[3],
      valuation: value[4],
      discountAdjustedValue: value[5],
      discountAdjustedVar: value[8],
      discountVarAdjustedValue: value[7],
      liquidityFactorAdjustedValuation: value[9],
    });
  });

  return tableData;
}

function LiquidityFactorAnalysisTable({ data }: Props): ReactElement | null {
  const tableData = builLiquidityFactorAnalysisTableData(data);
  // const columns = createFundAssetColumns(stressLevel as StressLevel);
  // const fundsSelector = createSectionFundsSelector('liquidity');
  // const funds = useSelector(fundsSelector);
  // const fundTitle =
  //     funds && funds.length
  //         ? funds.find((fund: any) => fund.id === activeFund)?.name + ' - Breakdown'
  //         : '';
  return (
    <GridItem xs={12} card>
      <CustomTable<ILiquidityFactorAnalysisData>
        showToolbar={true}
        // loading={fundAssetData.isFetching}
        // csvFields={csvFundAssetLiquidationTime}
        options={{
          paging: false,
          sorting: false,
          showTitle: true,
        }}
        data={tableData}
        columns={DiscountRateColumns}
        title={'Liquidity Factor Analysis'}
        // toolbarComponents={{
        //     toolbarTitle: fundTitle,
        // }}
      />
    </GridItem>
  );
}

export default LiquidityFactorAnalysisTable;
