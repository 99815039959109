import React, { FC } from 'react';
import CustomTable from '../../../../../../tables/CustomTable';
import {
  EquityTableData,
  generateEquityTableData,
  generateEquityTableDataColumns,
} from './table.data';
import { DataObject } from '../../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../../layout/GridComponents/GridItem';
import useActivefund from '../../../../../../../hooks/useActiveFund';

export enum EquityDataTypeOption {
  NET_BASE_CURRENCY = 'Net Exposure, Base Currency',
  NET_PC = 'Net Exposure, % NAV',
  GROSS_BASE_CURRENCY = 'Gross Exposure, Base Currency',
  GROSS_PC = 'Gross Exposure, % of NAV',
}
export interface EquityComponentProps {
  inputData: DataObject;
  selectedEquityPage: string;
}

export type EquityTableProps = EquityComponentProps;

const EquityTable: FC<EquityTableProps> = (props) => {
  const { inputData } = props;

  const activeFund = useActivefund();
  const tableColumns = generateEquityTableDataColumns();
  const tableData = generateEquityTableData(inputData.data);

  return (
    <GridItem xs={12} card>
      <CustomTable<EquityTableData>
        loading={inputData.isFetching}
        data={tableData}
        columns={tableColumns}
        csvFields={tableColumns.map((col) => col.field as string)}
        toolbarComponents={{
          toolbarTitle: activeFund?.name,
        }}
        options={{
          paging: true,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200, 500],
          emptyRowsWhenPaging: false,
          maxBodyHeight: 'calc(100vh - 16rem)',
          exportButton: true,
          search: true,
        }}
        showToolbar={true}
      />
    </GridItem>
  );
};

export default EquityTable;
