// This component deals with both the PRIIPS KIDS and UCITS KIIDs Viewers. It is used to view the KIID in a PDF format.

import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { FC, FunctionComponent, useEffect, useState } from 'react';
import client from '../../../../../utilities/requestClient';
import useRouteParams from '../../../../../hooks/useRouteParams';
import { useDispatch } from 'react-redux';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import { useHistory } from 'react-router-dom';
import PriipsKidsEditorGridItem from '../layout/PriipsKidsEditorGridItem.component';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import RevertVersionNumberButton from '../buttons/RevertVersionNumberButton.component';
import { Typography } from '@mui/material';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { useRaidrKids } from '../context/RaidrKidsContext';

const useStyles = makeStyles(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    gap: '2rem',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  button: {
    height: '3rem',
    // marginTop: '0.2rem',
    marginRight: '1rem',
    width: '10rem',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  iframeContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    width: '100%',
    height: '85vh',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  fundNameContainer: {
    width: '100%',
    // overflow: 'hidden',
    maxHeight: '3rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
  },
  versionNumberContainer: {
    width: '100%',
    // overflow: 'hidden',
    maxHeight: '3rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
    backgroundColor: mainColors.mainBlue,
    borderRadius: '5px',
    color: 'white',
    boxSizing: 'border-box',
    paddingRight: '5px',
    paddingLeft: '5px',
    fontSize: '1.2rem',
    display: 'flex',
    fontWeight: 600,
  },
}));

const KidsView: FC = () => {
  const classes = useStyles();
  const requestClient = client();
  const history = useHistory();
  const dispatch = useDispatch();

  const { backToOverviewLink, iframeTitle } = useRaidrKids();
  const [reviewContentData, setReviewContentData] = useState<any[]>([]);
  const [pdfDocLoading, setPdfDocLoading] = useState(false);

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];
  const version = useRouteParams('version')['version'];
  const latestVersion = useRouteParams('latestVersion')['latestVersion'];

  const getReviewData = async () => {
    requestClient
      .get(
        `kiid_generator_get_kiid_data/${fundId}/${isin}/${documentLanguage}/${
          version ? version : ''
        }`,
      )
      .then((response: any) => {
        setReviewContentData(response.data);
      });
  };

  useEffect(() => {
    if (fundId && isin && documentLanguage) {
      getReviewData();
    }
  }, [fundId, isin, documentLanguage]);

  const getPDF = async () => {
    // Check if the required fields have been filled in.
    if (!reviewContentData || !reviewContentData.length) {
      return;
    }

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    // sort reviewContentData by kiid_index
    reviewContentData.sort((a, b) => {
      return a.kiid_index - b.kiid_index;
    });
    const priipsKidsJson = JSON.stringify(reviewContentData);
    const fundId = reviewContentData[0].fund_id_string;
    const fundName = reviewContentData[0].fund_name;
    const isin = reviewContentData[0].share_class;
    const documentLanguage = reviewContentData[0].document_language;
    // Update the formData object.
    formData.append('contents', priipsKidsJson);
    formData.append('fund_id', fundId);
    formData.append('fund_name', fundName);
    formData.append('isin', isin);
    formData.append('document_language', documentLanguage);
    formData.append(
      'params',
      JSON.stringify([
        'contents',
        'fund_id',
        'fund_name',
        'isin',
        'document_language',
      ]),
    );

    // Display the loading symbol
    setPdfDocLoading(true);

    // Create the request
    requestClient
      .post('risksystem_pdf_generator/generate_new_kiid', formData, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      })
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = iframeTitle;
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    if (reviewContentData.length) {
      getPDF();
    }
  }, [reviewContentData]);

  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    history.push(backToOverviewLink);
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (reviewContentData.length > 0) {
      const componentsList = [
        <BackToOverviewBtn
          backToOverview={backToOverview}
          dontSetPosition
          key="back_to_overview"
        />,
        <div className={classes.fundNameContainer}>
          <Typography variant="h6">
            {`${reviewContentData[0].fund_name} - ${isin}`}
          </Typography>
        </div>,
        <div className={classes.versionNumberContainer}>
          <Typography variant="h6">{`Version: ${version}`}</Typography>
        </div>,
      ];
      if (version !== latestVersion) {
        componentsList.push(
          <RevertVersionNumberButton
            fundId={fundId || ''}
            shareClass={isin || ''}
            version={version || ''}
            documentLanguage={documentLanguage || ''}
            backToOverviewLink={backToOverviewLink}
          />,
        );
      }
      dispatch(updateControlBarComponents(componentsList));
      dispatch(toggleControlBarShowing(true));
    }
  }, [reviewContentData]);

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.viewerContainer}>
        <div className={classes.iframeContainer}>
          <iframe
            src=""
            width={pdfDocLoading ? '0%' : '100%'}
            height="100%"
            title={'kid.pdf'}
          ></iframe>
          {pdfDocLoading ? (
            <Raptor2Loading centerWrap messages={['Generating Document...']} />
          ) : null}
        </div>
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default KidsView;
