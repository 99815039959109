import React, { FC } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import {
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';

interface PositionTableProps {
  data: DataObject;
}

interface PositionTableData {
  asset: string;
  isin: string;
  marketPrice: number;
  currency: string;
  position: number;
  // grossExposure: number;
  // grossExposurePercent: number;
  // commitment: number;
  // commitmentPercent: number;
}

function buildPositionTableData(data: DataObject): PositionTableData[] {
  if (!data.data.length) return [];
  const positions = data.data[0].positions;
  if (typeof positions === 'string') return [];

  const tableData: PositionTableData[] = [];
  positions.forEach((position: any) => {
    tableData.push({
      asset: position.gm_name_long,
      isin: position.isin,
      marketPrice: position.market_price,
      currency: position.base_currency,
      position: position.mr_position,
    });
  });

  return tableData;
}

const tableColumns: CustomColumn<PositionTableData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  // {
  //     title: 'ISIN',
  //     field: 'isin',
  //     headerStyle: {
  //         textAlign: 'center',
  //     },
  //     cellStyle: {
  //         textAlign: 'center',
  //     },

  // },
  {
    title: 'Market Price',
    field: 'marketPrice',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: PositionTableData) =>
      roundNumberToNDecimalPlaces(rowData.marketPrice, 4),
  },
  {
    title: 'Currency',
    field: 'currency',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Position',
    field: 'position',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: PositionTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.position),
  },
];

const PositionsTable: FC<PositionTableProps> = (props) => {
  const tableData = buildPositionTableData(props.data);
  return tableData.length ? (
    <GridItem card xs={12}>
      <CustomTable<PositionTableData>
        pdfNoClearFirstRow
        options={{
          paging: false,
          exportButton: true,
        }}
        showToolbar={true}
        data={tableData}
        // toolbarComponents={{
        //   toolbarTitle: fundDetails.name,
        // }}
        columns={tableColumns}
      />
    </GridItem>
  ) : null;
};

export default PositionsTable;
