import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';

interface RelativeRiskTableProps {
  data: any;
}

interface RelativeRiskTableData {
  index: string;
  indexVaR: number;
  fundVaR: string;
  relativeRisk: number;
}

const columns: CustomColumn<RelativeRiskTableData>[] = [
  {
    title: 'Index',
    field: 'index',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: `Index VaR`,
    field: 'indexVaR',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render(rowData: RelativeRiskTableData) {
      return percentageToNdecialPlaces(rowData.indexVaR, 2);
    },
  },
  {
    title: `Fund VaR`,
    field: 'fundVaR',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render(rowData: RelativeRiskTableData) {
      return percentageToNdecialPlaces(rowData.fundVaR, 2);
    },
  },
  {
    title: `Relative Risk`,
    field: 'relativeRisk',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const buildTableData = (inputData: DataObject): RelativeRiskTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: RelativeRiskTableData[] = [];
    inputData.data[0].index_data.map((index: any) => {
      returnData.push({
        index: index.index,
        indexVaR: index.index_var,
        fundVaR: index.fund_var,
        relativeRisk: index.relative_risk,
      });
    });

    return returnData;
  }
};

const RelativeRiskTable: React.FC<RelativeRiskTableProps> = ({ data }) => {
  const builtTableData = buildTableData(data);

  return (
    <GridItem xs={12} card>
      <CustomTable<RelativeRiskTableData>
        columns={columns}
        showToolbar
        data={builtTableData}
        title={`Relative Value At Risk Breakdown`}
        options={{
          paging: false,
          search: true,
          exportButton: true,
          draggable: false,
        }}
      />
    </GridItem>
  );
};

export default RelativeRiskTable;
