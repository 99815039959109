import makeStyles from '@mui/styles/makeStyles';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../styling/theme';
import { EditorTileProps } from '../../priips/editor/editorTiles/PriipsKidsEditorColumn.component';
import { FC } from 'react';
import PriipsKidsEditorGridItem from '../../priips/layout/PriipsKidsEditorGridItem.component';
import CustomTable from '../../../../tables/CustomTable';
import UtilityButtons from '../buttons/UtilityButtons.component';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    // border: '1px solid',
    // borderColor: mainColors.mainBlue,
    // borderColor: 'black',
    marginTop: '2rem',
    // backgroundColor: 'white',
    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
}));

interface CompositionOfCostsTableData {
  costs: string;
  description: string;
  value: string;
  secondaryDescription: string;
}

const generalCompositionOfCostsTableData: CompositionOfCostsTableData[] = [
  {
    costs: 'One-Off Costs',
    description: 'Entry costs',
    value: '',
    secondaryDescription: '',
  },
  {
    costs: '',
    description: 'Exit costs',
    value: '',
    secondaryDescription: '',
  },
  {
    costs: 'Ongoing Costs',
    description: 'Portfolio transaction costs',
    value: '',
    secondaryDescription: '',
  },
  {
    costs: '',
    description: 'Other ongoing costs',
    value: '',
    secondaryDescription: '',
  },
  {
    costs: 'Incidental Costs',
    description: 'Performance fees',
    value: '',
    secondaryDescription: '',
  },
  {
    costs: '',
    description: 'Carried interests',
    value: '',
    secondaryDescription: '',
  },
];

function buildCompositionOfCostsTableData(
  content: any[],
): CompositionOfCostsTableData[] {
  const tableData: CompositionOfCostsTableData[] = [
    ...generalCompositionOfCostsTableData,
  ];
  content.forEach((element: any, index: number) => {
    tableData[index].value = element[0];
    tableData[index].secondaryDescription = element[1];
  });
  return tableData;
}

const columns: CustomColumn<CompositionOfCostsTableData>[] = [
  {
    title: '',
    field: 'costs',
    cellStyle: {
      width: '20%',
      border: '1px solid #1875d2',
      padding: '0.5rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      width: '20%',
      // border: '1px solid white',
      padding: '0.5rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
  {
    title: '',
    field: 'description',
    cellStyle: {
      width: '20%',
      border: '1px solid #1875d2',
      padding: '0.5rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      width: '20%',
      // border: '1px solid white',
      padding: '0.5rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
  {
    title: '',
    field: 'value',
    cellStyle: {
      border: '1px solid #1875d2',
      padding: '0.5rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      // border: '1px solid white',
      padding: '0.5rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
  {
    title: '',
    field: 'secondaryDescription',
    cellStyle: {
      border: '1px solid #1875d2',
      padding: '0.5rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      // border: '1px solid white',
      padding: '0.5rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
];

const CompositionOfCostsTableEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field id of the element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object
  const content = JSON.parse(priipsKidsElement.content);
  // Build the table data
  const tableData = buildCompositionOfCostsTableData(content);

  const classes = useStyles();

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.editorContainer}>
        <div className={classes.textEditorBox}>
          <CustomTable<CompositionOfCostsTableData>
            columns={columns}
            id={'performance_scenarios_table'}
            showToolbar={false}
            data={tableData}
            options={{
              paging: false,
              search: false,
              exportButton: false,
              sorting: false,
              draggable: false,
              toolbar: false,
            }}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
          disableDelete
          disableDeleteMessage="You cannot delete the composition of costs table."
        />
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default CompositionOfCostsTableEditorTile;
