import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';

interface FxRatesTableProps {
  data: DataObject;
  fundName: string;
  positionDate: string | undefined;
}

export interface FxRatesTableData {
  fx_pair: string;
  value: number;
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<FxRatesTableData>[] = [
  {
    title: 'FX Pair',
    field: 'fx_pair',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const buildTableData = (inputData: any): FxRatesTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    return Object.keys(inputData.data[0]).map((pair: string) => ({
      fx_pair: pair,
      value: inputData.data[0][pair],
    }));
  }
};

const FxRatesTable: React.FC<FxRatesTableProps> = ({
  data,
  fundName,
  positionDate,
}) => {
  const builtTableData = buildTableData(data);

  const title = `FX Rate for ${fundName} on ${positionDate}`;

  return (
    <GridItem xs={12} card>
      <CustomTable<FxRatesTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={title}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default FxRatesTable;
