import { useSelector } from 'react-redux';
import { createSpecificTitleBarDatePickerSelector } from '../redux/pages/selectors';

const useTitleBarDatePicker = (selectKey: string) => {
  const datePickerValSelector =
    createSpecificTitleBarDatePickerSelector(selectKey);
  const relevantDatePicker = useSelector(datePickerValSelector);
  return relevantDatePicker.length > 0
    ? relevantDatePicker[0].currentValue
    : null;
};

export default useTitleBarDatePicker;
