import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import HistoryIcon from '@mui/icons-material/History';
import RevertVersionNumberDialogBox from './RevertVersionNumberDialogBox.component';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  conrolContainer: {
    display: 'flex',
  },
  toggleIcon: {
    color: mainColors.mainBlue,
  },
  toggleIconMessage: {
    transform: 'translateY(8rem)',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

interface RefreshFiguresButtonProps {
  fundId: string;
  shareClass: string;
  version: string;
  documentLanguage: string;
  backToOverviewLink: string;
}

const RevertVersionNumberButton: FC<RefreshFiguresButtonProps> = (props) => {
  const { fundId, shareClass, documentLanguage, version } = props;

  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.conrolContainer}>
      {showDialog ? (
        <RevertVersionNumberDialogBox
          setDialogIsShowing={setShowDialog}
          fundId={fundId}
          shareClass={shareClass}
          documentLanguage={documentLanguage}
          versionNumber={version}
          backToOverviewLink={props.backToOverviewLink}
        />
      ) : null}
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title={'Revert document back to this version.'}
        placement="top"
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: '1.5rem',
            width: '18rem',
            color: 'white',
            backgroundColor: mainColors.mainBlue,
            borderRadius: '0.2rem',
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.mainBlue_lighter,
            },
            fontWeight: 600,
            textTransform: 'none',
          }}
          disableElevation={true}
          endIcon={
            <HistoryIcon
              sx={{
                padding: '0.2rem',
                fontSize: '3rem',
                width: '3rem',
                height: '2.5rem',
                marginRight: '0.5rem',
              }}
            />
          }
          onClick={() => setShowDialog(true)}
        >
          Revert Version
        </Button>
      </Tooltip>
    </div>
  );
};

export default RevertVersionNumberButton;
