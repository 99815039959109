import { FC } from 'react';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { roundNumberToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import { Typography } from '@mui/material';

interface EsgVarTableProps {
  data: any;
}

// const useStyles = makeStyles<RaptorTheme>(() => ({
//     button: {
//       height: '3rem',
//       // marginTop: '0.2rem',
//       marginRight: '1rem',
//       width: '10rem',
//     },
//   }));

export interface EsgVarTableData {
  name: string;
  e: number;
  s: number;
  g: number;
  esg: number;
}

function buildPeriodLabel(data: any): string {
  if (!data.data.length) return '';
  return `${data.data[0].dates[0]} - ${
    data.data[0].dates[data.data[0].dates.length - 1]
  }`;
}

export function buildEsgVartTableData(data: any): EsgVarTableData[] {
  if (!data.data.length) return [];
  const varData = data.data[0];
  const tableData: EsgVarTableData[] = [
    {
      name: 'Min Score In Period',
      e: varData.e_data.min,
      s: varData.s_data.min,
      g: varData.g_data.min,
      esg: varData.esg_data.min,
    },
    {
      name: 'Max Score In Period',
      e: varData.e_data.max,
      s: varData.s_data.max,
      g: varData.g_data.max,
      esg: varData.esg_data.max,
    },
    {
      name: 'Current Score',
      e: varData.e_data.value,
      s: varData.s_data.value,
      g: varData.g_data.value,
      esg: varData.esg_data.value,
    },
    {
      name: 'VaR Value',
      e: varData.e_data.var,
      s: varData.s_data.var,
      g: varData.g_data.var,
      esg: varData.esg_data.var,
    },
    {
      name: 'Volatility Value',
      e: varData.e_data.std,
      s: varData.s_data.std,
      g: varData.g_data.std,
      esg: varData.esg_data.std,
    },
  ];

  return tableData;
}

const detailColumns: CustomColumn<EsgVarTableData>[] = [
  {
    title: 'Metric',
    field: 'name',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Environmental',
    field: 'e',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: EsgVarTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.e),
  },
  {
    title: 'Social',
    field: 's',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: EsgVarTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.s),
  },
  {
    title: 'Corporate Governance',
    field: 'g',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: EsgVarTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.g),
  },
  {
    title: 'ESG',
    field: 'esg',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: EsgVarTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.esg),
  },
];

const EsgVarTable: FC<EsgVarTableProps> = (props) => {
  const { data } = props;
  const tableData = buildEsgVartTableData(data);
  const periodLabel = buildPeriodLabel(data);
  // const timSeriesData = buildOverviewTimeseriesData(data);
  return (
    <div>
      <Typography
        style={{
          marginTop: '1rem',
          marginLeft: '2rem',
        }}
        variant="h3"
      >{`Period Covered: ${periodLabel}`}</Typography>
      <CustomTable<EsgVarTableData>
        columns={detailColumns}
        showToolbar={false}
        data={tableData}
        title={'Summary'}
        options={{
          paging: false,
          search: false,
          exportButton: false,
        }}
      />
    </div>
  );
};

export default EsgVarTable;
