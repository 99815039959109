import React from 'react';

interface CustomTickProps {
  x: number;
  y: number;
  stroke: string;
  value: string;
  payload: any;
  rotationValue: number;
  fontSize: number;
  textAnchor?: string;
  tickFormatter?: any;
}

const CustomTick = (props: CustomTickProps) => {
  const { x, y, fontSize, stroke, payload, rotationValue, textAnchor } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor={textAnchor ? textAnchor : 'middle'}
        fill="#666"
        fontSize={fontSize}
        transform={`rotate(${rotationValue})`}
      >
        {props.tickFormatter
          ? props.tickFormatter(payload.value)
          : payload.value}
      </text>
    </g>
  );
};

CustomTick.defaultProps = {
  x: 0,
  y: 0,
  stroke: 'red',
  value: 'no_value',
  payload: {},
  rotationValue: -35,
  fontSize: 10,
} as Partial<CustomTickProps>;

export default CustomTick;
