import React from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import PeProxyModelGraph from './subComponents/PeProxyModelGraph.component';
import useFetchData from '../../../../hooks/useFetchData';
import PeProxyModelTable from './subComponents/PeProxyModelTable.component';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';

const PeProxyModel: React.FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const peFundNav = useFetchData({
    url: `/get_private_equity_fund_nav/${fundId}/${positionDate}`,
    keyName: `pe_fund_nav_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });
  const peExposureConfigurations = useFetchData({
    url: `/getPeExposureConfigurations/${fundId}/${positionDate}`,
    keyName: `pe_exposure_configurations_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[peFundNav, peExposureConfigurations]}
    >
      <PeProxyModelGraph data={peFundNav} />
      <PeProxyModelTable data={peExposureConfigurations} />
    </GeneralComponentErrorShield>
  );
};

export default PeProxyModel;
