import { ReactElement } from 'react';
import InvestorTable from './investorBased.table';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../../redux/auth/selectors';
import { Typography } from '@mui/material';

interface Props {
  investorData: DataObject;
  section: string;
}

function InvestorBased({ investorData, section }: Props): ReactElement {
  const { data, isFetching } = investorData;

  // Get clientName to give custom titles for Gemini
  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const testNameFirstScenario = clientName === 'gemini' ? '5x4%' : '3%';
  const testNameSecondScenario = clientName === 'gemini' ? '1x20%' : '10%';
  const firstScenarioValue = clientName === 'gemini' ? 4 : 3;
  const secondScenarioValue = clientName === 'gemini' ? 20 : 10;
  return (
    <>
      <GridItem xs={12} style={{ margin: '2rem 0 0 0', paddingBottom: 0 }}>
        <Typography
          variant="h1"
          style={{ paddingLeft: '1rem', fontSize: '2.4rem' }}
        >
          Investor Based Liquidity Reverse Stress Test
        </Typography>
      </GridItem>
      <InvestorTable
        section={section}
        loading={isFetching}
        data={data}
        inputKey="biz3"
        title={`Fund Status Under ${testNameFirstScenario} Redemptions - Business as usual`}
        redemptionLevel={firstScenarioValue}
      />
      <InvestorTable
        section={section}
        loading={isFetching}
        data={data}
        inputKey="biz10"
        title={`Fund Status Under ${testNameSecondScenario} Redemptions - Business as usual`}
        redemptionLevel={secondScenarioValue}
      />
      <InvestorTable
        section={section}
        loading={isFetching}
        data={data}
        inputKey="stress3"
        title={`Fund Status Under ${testNameFirstScenario} Redemptions - Super stressed`}
        redemptionLevel={firstScenarioValue}
      />
      <InvestorTable
        section={section}
        loading={isFetching}
        data={data}
        inputKey="stress10"
        title={`Fund Status Under ${testNameSecondScenario} Redemptions - Super stressed`}
        redemptionLevel={secondScenarioValue}
      />
      {clientName === 'gem' ? (
        <>
          <InvestorTable
            section={section}
            loading={isFetching}
            data={data}
            inputKey="sov3"
            title="Fund Status Under 3% Redemptions - EU Sovern Debt Crisis"
            redemptionLevel={firstScenarioValue}
            pdfDontMoveToNewPage={false}
          />
          <InvestorTable
            section={section}
            loading={isFetching}
            data={data}
            inputKey="sov10"
            title="Fund Status Under 10% Redemptions - EU Sovern Debt Crisis"
            redemptionLevel={secondScenarioValue}
          />
        </>
      ) : null}
    </>
  );
}

export default InvestorBased;
