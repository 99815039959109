import React from 'react';
import PageLinkButton, {
  PageLinkButtonProps,
} from '../../../../../../buttons/PageLinkButton';

export type AifmdReportType =
  | 'market'
  | 'counterparty'
  | 'credit'
  | 'liquidity';

interface ReportsLinkButtonProps extends PageLinkButtonProps {
  reportType?: AifmdReportType;
}
const ReportsLinkButton = (props: ReportsLinkButtonProps) => {
  const updateReportTypeHandler = () => {
    if (props.reportType) {
      localStorage.setItem('AIF_REPORT_TYPE', props.reportType);
    }
  };
  return (
    <PageLinkButton {...props} additionalHandler={updateReportTypeHandler} />
  );
};

export default ReportsLinkButton;
/*
 */
