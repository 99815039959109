import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../styling/theme';
import { formatMillions } from '../../../../utilities/numberFormatters';

interface CashFlowStressTestDetailPanelProps {
  timeseriesData: any[];
}

const CashFlowStressTestDetailPanel: React.FC<
  CashFlowStressTestDetailPanelProps
> = ({ timeseriesData }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={timeseriesData}
        margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
        //   ref={ref}
      >
        <XAxis
          dataKey="year"
          // angle={-20}
          textAnchor="end"
          height={80}
          tick={{
            stroke: mainColors.mainBlue,
            strokeWidth: 1,
            fontSize: '1.3rem',
          }}
          tickLine={true}
        >
          <Label
            style={{
              textAnchor: 'middle',
              fontSize: '150%',
              fontWeight: 400,
              color: mainColors.mainBlue,
            }}
            value={'Year'}
            position={'insideBottom'}
          />
        </XAxis>
        <YAxis
          width={70}
          tickFormatter={(tickItem) => {
            return formatMillions(tickItem);
          }}
          tick={{
            stroke: mainColors.mainBlue,
            strokeWidth: 1,
            fontSize: '1.3rem',
          }}
          tickLine={true}
          // domain={[
          //   (dataMin: number) =>
          //     roundToNDecimalPlacesReturnNumber(dataMin - 0.2, 2),
          //   (dataMax: number) =>
          //     roundToNDecimalPlacesReturnNumber(dataMax + 0.2, 2),
          // ]}
        >
          <Label
            style={{
              textAnchor: 'middle',
              fontSize: '150%',
              fontWeight: 400,
              color: mainColors.mainBlue,
            }}
            angle={270}
            value={'P/L'}
            position={'insideLeft'}
          />
        </YAxis>
        <Tooltip
        //   content={<CustomTooltip />}
        />
        <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
        <ReferenceLine y={0} stroke={greys.grey600} />
        <Line
          type="linear"
          dataKey="Free Cash Flow"
          stroke={mainColors.Pass}
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="Discounted Free Cash Flow"
          stroke={mainColors.Fail}
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="EBIT"
          stroke={mainColors.fourthPurple}
          strokeWidth={2}
        />
        <Legend
          verticalAlign="top"
          height={30}
          wrapperStyle={{ fontSize: '1.6rem' }}
          // formatter={(item) => {
          //   switch (item) {
          //     case 'pl':
          //       return 'Profit / Loss %';
          //     default:
          //       return item;
          //   }
          // }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CashFlowStressTestDetailPanel;
