import React, { FC } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import GenericLineChart from '../../../charts/GenericLineChart';
import {
  addCommasToNumbersAndRound,
  formatMillions,
} from '../../../../utilities/numberFormatters';
import { mainColors } from '../../../../styling/theme';

interface DCFOverviewGraphProps {
  data: any;
  selectedPosition: string | null;
}

function buildChartData(data: any, selectedPosition: string | null) {
  if (selectedPosition === 'Portfolio') {
    if (!data || !data.data || !data.data[0]) return [];
    if (!data.data.length) return [];
  }
  if (!selectedPosition) return [];
  const peDataPositions =
    selectedPosition === 'Portfolio' ? data.data[0] : data;
  const timeSeries =
    selectedPosition === 'Portfolio'
      ? peDataPositions[selectedPosition].timeseries
      : data;
  const chartData: any[] = [];
  for (let i = 0; i < 10; i++) {
    chartData.push({
      year: i,
      'Discounted Free Cash Flow': timeSeries['Discounted Free Cash Flow'][i],
      EBIT: timeSeries['EBIT'][i],
      'Free Cash Flow': timeSeries['Free Cash Flow'][i],
    });
  }
  return chartData;
}

const DiscountCashflowOverviewGraph: FC<DCFOverviewGraphProps> = (props) => {
  // const theme = useTheme();
  const { data, selectedPosition } = props;
  // const tableData = isDefaultScenario ? buildSubscriptionsRedemptionData(data, selectedPosition, percentValueOption, nav) : buildDCFDataForComparison(data, selectedPosition, percentValueOption, nav, defaultData);
  // Allow user to switch between scenrio, change and both
  // const detailColumns = buildDetailColumns(
  //   props.setBarChartFilter,
  //   props.barChartValue,
  //   props.percentValueOption
  // );
  // const pdfColumns = buildDetailColumnsPDF(props.percentValueOption);
  // const nav = getNav(data);
  // const tableData = buildDCFConfigurationData(
  //   data,
  //   selectedPosition,
  //   props.percentValueOption,
  //   nav
  // );
  // const tableData = buildTableData(data, selectedPosition);
  const chartData = buildChartData(data, selectedPosition);

  return selectedPosition === 'Portfolio' ? (
    <GridItem
      xs={9}
      card
      style={{
        padding: 8,
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // minHeight: 600
      }}
      cardStyle={{ minHeight: 700 }}
    >
      <div style={{ padding: '1rem' }}>
        <GenericLineChart
          title={`Discount Cashflow - ${selectedPosition || ''}`}
          showTitle
          data={chartData}
          // export
          id={'discount_cahflow_overview_line_chart'}
          xAxisDataKey="year"
          // yAxisDomain={[
          //   minvalue === 0 ? -0.01 : minvalue - Math.abs(minvalue) * 0.1,
          //   maxvalue + Math.abs(maxvalue) * 0.1,
          // ]}
          yAxisTickFormatter={(val: number) =>
            //   percentValueOption === 'percent'
            //     ? percentageToNdecialPlaces(val, 0)
            //     :
            formatMillions(val)
          }
          tooltipFormatter={(val: number, name: any) => [
            //   percentValueOption === 'percent'
            //     ? percentageToTwoDecimalPlaces(val)
            //     :
            addCommasToNumbersAndRound(val),
            //   name === 'value' ? 'Current Scenario' : 'Default Scenario',
            name,
          ]}
          lines={[
            {
              dataKey: 'Discounted Free Cash Flow',
              color: mainColors.Pass,
            },
            {
              dataKey: 'EBIT',
              color: mainColors.Fail,
            },
            {
              dataKey: 'Free Cash Flow',
              color: mainColors.fourthPurple,
            },
          ]}
          width={'100%'}
          height={600}
          // showDot={false}
          margin={{
            top: 10,
            bottom: 10,
            left: 10,
            right: 40,
          }}
          error={'No DCF Data Available'}
          monotone
          xAxisLabel={{ value: 'Year', dy: 10 }}
        />
      </div>
    </GridItem>
  ) : (
    <div style={{ padding: '1rem' }}>
      <GenericLineChart
        title={`Discount Cashflow - ${selectedPosition || ''}`}
        showTitle
        data={chartData}
        // export
        id={'discount_cahflow_overview_line_chart'}
        xAxisDataKey="year"
        // yAxisDomain={[
        //   minvalue === 0 ? -0.01 : minvalue - Math.abs(minvalue) * 0.1,
        //   maxvalue + Math.abs(maxvalue) * 0.1,
        // ]}
        yAxisTickFormatter={(val: number) =>
          //   percentValueOption === 'percent'
          //     ? percentageToNdecialPlaces(val, 0)
          //     :
          formatMillions(val)
        }
        tooltipFormatter={(val: number, name: any) => [
          //   percentValueOption === 'percent'
          //     ? percentageToTwoDecimalPlaces(val)
          //     :
          addCommasToNumbersAndRound(val),
          //   name === 'value' ? 'Current Scenario' : 'Default Scenario',
          name,
        ]}
        lines={[
          {
            dataKey: 'Discounted Free Cash Flow',
            color: mainColors.Pass,
          },
          {
            dataKey: 'EBIT',
            color: mainColors.Fail,
          },
          {
            dataKey: 'Free Cash Flow',
            color: mainColors.fourthPurple,
          },
        ]}
        width={'100%'}
        height={400}
        // showDot={false}
        margin={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 40,
        }}
        error={'No DCF Data Available'}
        monotone
        xAxisLabel={{ value: 'Year', dy: 10 }}
      />
    </div>
  );
};

export default DiscountCashflowOverviewGraph;
