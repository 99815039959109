import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataFromStoreWithKey } from '../redux/data/selectors';
import { fetchData } from '../redux/data/actions';
import { isLoggedInSelector } from '../redux/auth/selectors';
import axios from 'axios';

export interface useFetchDataProps {
  url: string;
  keyName: string;
  // makeFetch is here to block the call if necessary
  makeFetch: boolean;
  formData?: FormData;
}

/**
 * The useFetchData hook checks if a key is present in the data store.
 * If so, the data will be returned.
 * Otherwise, the data will be fetched.
 *
 * @param url - the url to fetch the data
 * @param keyName - the key for the data in the store
 * @param makeFetch - a boolean to determine whether to fetch the data or not
 * @param formData - the optional key for adding form data to the request
 *
 * Use the useFetchDataWithDates hook if the data that you need has a position date
 * at the end of the url
 * For example, general_exposure becomes general_exposurer/2020-09-20
 */
const useFetchData = ({
  url,
  keyName,
  makeFetch,
  formData,
}: useFetchDataProps) => {
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const isAuthenticated = useSelector(isLoggedInSelector);
  const fetchedData = useSelector(fetchDataFromStoreWithKey(keyName));
  useEffect(() => {
    if (isAuthenticated && makeFetch) {
      if (!fetchedData) {
        dispatch(fetchData({ url, keyName, formData, cancelSource: source }));
      }
    }
    return () => {
      // source.cancel('cancelling useFetchData: ' + keyName);
    };
  }, [isAuthenticated, makeFetch, keyName]);

  return fetchedData;
};

export default useFetchData;
