import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import EsgVarTable from './EsgVarTable.component';
import EsgVarChart from './EsgVarChart.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Grid } from '@mui/material';

function getChartTitle(value: string) {
  switch (value) {
    case 'e_data':
      return 'Environmental Score Timeseries';
    case 's_data':
      return 'Social Score Timeseries';
    case 'g_data':
      return 'Corporate Governance Score Timeseries';
    default:
      return 'ESG Score Timeseries';
  }
}

const EsgVAR: FC<FundInfoComponentProps> = (props) => {
  const esgVarData = useFetchData({
    keyName: props.positionDate
      ? `${props.fundId}_esg_var_data_${props.positionDate}`
      : `${props.fundId}_esg_var_data`,
    url: props.positionDate
      ? `manco_level_esg_implied_historical_ts_data/${props.fundId}/${props.positionDate}`
      : `manco_level_esg_implied_historical_ts_data/${props.fundId}`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgVarData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found'}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem
          style={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
          card
          xs={12}
        >
          <EsgVarTable data={esgVarData} />
        </GridItem>
        <GridItem xs={12} style={{ minHeight: 750 }} card>
          <EsgVarChart data={esgVarData} />
        </GridItem>
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default EsgVAR;
