import React from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import { ESGChartProps } from './ESGChart';

const CorporateGovernanceChart = (props: ESGChartProps) => {
  const { value } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const boxHeight = ((100 - value) / 100) * 22;
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="1 1 22 22"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="cut-off-corporate">
          <rect x="1" y="1" width="22" height={boxHeight} />
        </clipPath>
      </defs>
      {/* <rect x="1" y="1" width="22" height="22" fill="yellow" /> */}
      <path
        id="corpoate"
        d="M5.2496 8.0688l2.83-2.8268 14.134 14.15-2.83 2.8268zM9.4857 3.8272l2.828-2.8288 5.6576 5.656-2.828 2.8288zM.9989 12.3147l2.8284-2.8284L9.484 15.143l-2.8284 2.8284zM1 21h12v2H1z"
        fill={greys.grey400}
      />
      <path
        id="corpoate"
        d="M5.2496 8.0688l2.83-2.8268 14.134 14.15-2.83 2.8268zM9.4857 3.8272l2.828-2.8288 5.6576 5.656-2.828 2.8288zM.9989 12.3147l2.8284-2.8284L9.484 15.143l-2.8284 2.8284zM1 21h12v2H1z"
        fill={mainColors.fourthPurple}
      />
      {/* <path
                id="social"
                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
                fill="#bababa"
            />
            <path
                id="social"
                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
                fill="#FF8E72"
            /> */}

      <use clipPath="url(#cut-off-corporate)" xlinkHref="#corpoate" />
    </svg>
  );
};

export default React.memo(CorporateGovernanceChart);
