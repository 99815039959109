import React from 'react';
import { mapStatusToColor } from '../../../utilities/colorUtilities';
import { ArticleProps } from './EsgChartsData';

const Article9Chart = (props: ArticleProps) => {
  const { status } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const colour = mapStatusToColor(status);
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 122.88"
      xmlSpace="preserve"
    >
      <path
        id="article9_circle"
        fillRule="evenodd"
        d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0Z"
        fill={colour}
      />
      <path
        id="number"
        fill="white"
        d="M60.68,37.88a22.61,22.61,0,0,1,7.57,1.26,17,17,0,0,1,6.32,4,18.75,18.75,0,0,1,4.33,7.1,31.62,31.62,0,0,1,1.59,10.59A33.77,33.77,0,0,1,79.06,70.9a22.39,22.39,0,0,1-4,7.63,17.37,17.37,0,0,1-6.26,4.8A19.48,19.48,0,0,1,60.6,85a19.35,19.35,0,0,1-8.9-2,16.1,16.1,0,0,1-6.12-5.21,15.13,15.13,0,0,1-2.65-7.3H55.15a4.45,4.45,0,0,0,2,3,6.66,6.66,0,0,0,3.49.92,6,6,0,0,0,5.73-3.4A19.35,19.35,0,0,0,68.18,62h-.26a9.56,9.56,0,0,1-2.64,3.6,12.87,12.87,0,0,1-4.11,2.29,15.29,15.29,0,0,1-5,.8A13.77,13.77,0,0,1,49,66.8a12.8,12.8,0,0,1-4.87-5.13,15.52,15.52,0,0,1-1.72-7.46,15.17,15.17,0,0,1,8.74-14.28,21.2,21.2,0,0,1,9.55-2Zm.1,9.37a6.37,6.37,0,0,0-3.29.85,5.86,5.86,0,0,0-2.26,2.32,7.48,7.48,0,0,0,0,6.69,6,6,0,0,0,2.26,2.32,6.37,6.37,0,0,0,3.29.85,6.49,6.49,0,0,0,2.51-.49,6.26,6.26,0,0,0,2-1.37,6.36,6.36,0,0,0,1.33-2.06,7.07,7.07,0,0,0,.5-2.59,6.72,6.72,0,0,0-.85-3.35A6,6,0,0,0,64,48.1a6.25,6.25,0,0,0-3.23-.85Z"
      />
    </svg>
  );
};

export default React.memo(Article9Chart);
