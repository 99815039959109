import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import DeceasedTable from './table';
import { useSelector } from 'react-redux';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';

const Deceased: React.FC<GeneralOverviewPageRenderedComponentProps> = ({
  section,
  positionDate,
}) => {
  const sectionDetails = useSelector(createSectionByIdSelector(section));
  const fundTypes = sectionDetails?.fundTypes;

  const schemas = sectionDetails?.fundTypes.length
    ? `&schemas=${sectionDetails?.fundTypes}`
    : '';
  const deceasedReports = useFetchData({
    url: `reports_list?status=deceased` + schemas,
    keyName: `${fundTypes}_deceased_reports_list`,
    makeFetch: true,
  });
  const deceasedData = useFetchData({
    url: `schema_overview_monitor/${positionDate}?status=deceased` + schemas,
    keyName: `${fundTypes}_overview_data_${positionDate}_deceased`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[deceasedReports, deceasedData]}>
      <DeceasedTable reportsData={deceasedReports} tableData={deceasedData} />
    </GeneralComponentErrorShield>
  );
};

export default Deceased;
