import { makeGeneralDataFetch } from '../../utilities/requestClient';
import {
  DELETE_FROM_STATE,
  FETCH_FAILURE,
  FETCH_START,
  FETCH_SUCCESS,
  IFetchStart,
} from '../../types/redux/data/dataTypes';
import { CancelTokenSource } from 'axios';

export const beginDataFetch = ({
  keyName,
}: {
  keyName: string;
}): IFetchStart => {
  return {
    type: FETCH_START,
    payload: keyName,
  };
};

export const dataFetchSuccessful = ({
  keyName,
  data,
}: {
  keyName: string;
  data: any;
}) => {
  return {
    type: FETCH_SUCCESS,
    payload: {
      keyName,
      data,
    },
  };
};

export const dataFetchFailure = ({
  keyName,
  errorMessage,
}: {
  keyName: string;
  errorMessage: string;
}) => {
  return {
    type: FETCH_FAILURE,
    payload: {
      keyName,
      errorMessage,
    },
  };
};

export const deleteDataFromState = ({ keyName }: { keyName: string }) => {
  return {
    type: DELETE_FROM_STATE,
    payload: {
      keyName,
    },
  };
};

export const fetchData = ({
  url,
  keyName,
  formData,
  cancelSource,
}: {
  url: string;
  keyName: string;
  formData?: FormData;
  cancelSource: CancelTokenSource;
}) => {
  return makeGeneralDataFetch({
    startAction: beginDataFetch,
    successAction: dataFetchSuccessful,
    failureAction: dataFetchFailure,
    cancelSource,
    props: {
      url,
      keyName,
      formData,
    },
  });
};
