import { greys, mainColors } from '../styling/theme';
import { Status } from '../types/redux/data/dataTypes';

const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st: string, chunkSize: number) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr: string) =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);

export const hexToRGBA = (hex: string, alpha: number) => {
  if (!isValidHex(hex)) {
    throw new Error(`Invalid HEX: ${hex}`);
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b] = hexArr!.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const mapStatusToColor = (status: Status) => {
  switch (status) {
    case Status.Alert:
    case Status.PassAlert:
      return mainColors.Alert;
    case Status.Fail:
    case Status.removedPosition:
      return mainColors.Fail;
    case Status.Pass:
      return mainColors.Pass;
    case Status.NA:
      return mainColors.NA;
    case Status.alteredPosition:
      return mainColors.Pass;
    case Status.noChange:
      return mainColors.NA;
    case Status.newPosition:
      return mainColors.fourthPurple;
    case Status.multiplePositions:
      return mainColors.mainGold;
    default:
      return mainColors.NA;
  }
};

export type RequestStatusType = 'success' | 'fail' | 'loading' | 'idle';

export const getAuthMessageColor = (authStatus: RequestStatusType): string => {
  switch (authStatus) {
    case 'success':
      return mainColors.Pass;
    case 'fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};
