import React, { ReactElement } from 'react';
import GeneralCard from '../../general/GeneralCard';
import GeneralLoadingCover from '../../general/GeneralLoadingCover';
import { Grid, GridProps } from '@mui/material';

interface Props extends GridProps {
  height?: string | number;
  width?: string | number;
  style?: React.CSSProperties;
  card?: boolean;
  cardStyle?: React.CSSProperties;
  loading?: boolean;
  className?: string;
}

function GridItem({
  children,
  height,
  width,
  style,
  card,
  loading,
  cardStyle,
  ...props
}: React.PropsWithChildren<Props>): ReactElement {
  return (
    <Grid
      item
      style={{
        height: height || '',
        width: width || '',
        ...style,
      }}
      {...props}
      // was spreading props here
    >
      {card ? (
        <GeneralCard className={props.className} cardStyle={cardStyle}>
          {loading !== undefined ? (
            <GeneralLoadingCover
              loading={loading}
              style={{ height: 'inherit' }}
            >
              {children}
            </GeneralLoadingCover>
          ) : (
            children
          )}
        </GeneralCard>
      ) : (
        children
      )}
    </Grid>
  );
}

export default GridItem;
