import { SvgIcon, SvgIconProps } from '@mui/material';

const PoundIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ strokeWidth: 27 }}
      viewBox="0 0 475.293 475.293"
    >
      <path
        className="cls-1"
        d="M234.85,59a99.29,99.29,0,0,0-99.19,99.19v99.14H96V297h37.33a215.59,215.59,0,0,1-34,88.16A19.84,19.84,0,0,0,115.83,416H334V376.33l-184,0A254.24,254.24,0,0,0,173.2,297h81.46V257.33H175.32V158.19a59.52,59.52,0,1,1,119,.11l0,19.66L334,178l0-19.66A99.17,99.17,0,0,0,234.85,59Z"
      />
    </SvgIcon>
  );
};

export default PoundIcon;
