import React, { ReactElement } from 'react';
import {
  IOutSampleTableData,
  buildOutSampleStatistics,
  outSampleColumns,
} from './outSample.data';
import CustomTable from '../../../../../tables/CustomTable';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';

interface Props {
  varBacktestData: DataObject;
}

function OutSampleTable({ varBacktestData }: Props): ReactElement {
  const tableData = buildOutSampleStatistics(varBacktestData.data);

  return (
    <GridItem xs={3} card>
      <CustomTable<IOutSampleTableData>
        columns={outSampleColumns}
        showToolbar={true}
        title="Out-sample Statistics"
        options={{
          paging: false,
          exportButton: true,
        }}
        loading={varBacktestData.isFetching}
        data={tableData}
      />
    </GridItem>
  );
}

export default OutSampleTable;
