import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import BusinessInvolvementPanel from './subComponents/BusinessInvolvementPanel.comonent';
import ExposureBySectorPanel from './subComponents/ExposureBySectorPanel.component';
import ESGOverviewPanel from './subComponents/FundScores.component';
import GeographicalExposurePanel from './subComponents/GeographicalExposurePanel.component';
import ESGPortfolioOverviewPanel from './subComponents/OverviewPanel.component';
import { Grid, Typography } from '@mui/material';

const ESGPortfolioSummary: FC<FundInfoComponentProps> = ({
  fundName,
  fundId,
  positionDate,
}) => {
  const esgData = useFetchData({
    url: `get_fund_esg_data/${fundId}/${positionDate}`,
    keyName: `${fundId}_esg_fund_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found'}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <PortfolioSummary
          esgData={esgData}
          fundId={fundId}
          fundName={fundName}
          positionDate={positionDate}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

interface PortfolioSummaryProps {
  esgData: DataObject;
  fundId: string;
  fundName: string;
  positionDate?: string;
}

function checkDataIsAvailable(data: DataObject): boolean {
  if (!data.data.length) return false;
  if ('no data' in data.data[0]) return false;
  return true;
}

// This is brought into a sperate component so we can check for cases when tehre is no data
const PortfolioSummary: FC<PortfolioSummaryProps> = ({
  esgData,
  fundId,
  fundName,
  positionDate,
}) => {
  // Check if data is available.
  const check = checkDataIsAvailable(esgData);

  return check ? (
    <>
      <ESGPortfolioOverviewPanel
        data={esgData}
        fundId={fundId}
        fundName={fundName}
        positionDate={positionDate}
      />
      <ESGOverviewPanel data={esgData} fundId={fundId} />
      <BusinessInvolvementPanel data={esgData} />
      <ExposureBySectorPanel esgData={esgData} />
      <GeographicalExposurePanel esgData={esgData} />
    </>
  ) : (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h1">{'No Data Available.'}</Typography>{' '}
    </DisplayAreaCenteredWrapper>
  );
};

export default ESGPortfolioSummary;
