import React from 'react';
import { mapStatusToColor } from '../../../utilities/colorUtilities';
import { ArticleProps } from './EsgChartsData';

const Article8Chart = (props: ArticleProps) => {
  const { status } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const colour = mapStatusToColor(status);
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 122.88"
      xmlSpace="preserve"
    >
      <path
        id="article9_circle"
        fillRule="evenodd"
        d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0Z"
        fill={colour}
      />
      <path
        id="number"
        fill="white"
        d="M61.44,85.22a26.56,26.56,0,0,1-9.87-1.72,17,17,0,0,1-6.83-4.66,10.06,10.06,0,0,1-2.48-6.65A9.82,9.82,0,0,1,43.67,67a12.15,12.15,0,0,1,3.82-3.9,13.44,13.44,0,0,1,5.39-2v-.37a10.72,10.72,0,0,1-6.42-3.46A9.53,9.53,0,0,1,44,50.68a9.82,9.82,0,0,1,2.27-6.38,15.18,15.18,0,0,1,6.22-4.41,23.74,23.74,0,0,1,9-1.6,23.51,23.51,0,0,1,9,1.61,15.29,15.29,0,0,1,6.22,4.42,9.78,9.78,0,0,1,2.27,6.36,9.44,9.44,0,0,1-2.53,6.63A10.83,10.83,0,0,1,70,60.76v.37a13.31,13.31,0,0,1,5.36,2A12.21,12.21,0,0,1,79.2,67a9.74,9.74,0,0,1,1.42,5.17,10.06,10.06,0,0,1-2.48,6.65,17,17,0,0,1-6.83,4.66,26.53,26.53,0,0,1-9.87,1.72Zm0-8.57a6.55,6.55,0,0,0,3.12-.71,5.63,5.63,0,0,0,2.16-2,5.34,5.34,0,0,0,.79-2.85,5.13,5.13,0,0,0-.79-2.81,5.53,5.53,0,0,0-2.16-1.93,7.28,7.28,0,0,0-6.23,0,5.56,5.56,0,0,0-2.17,1.93,5.13,5.13,0,0,0-.79,2.81A5.28,5.28,0,0,0,56.16,74a5.72,5.72,0,0,0,2.17,2,6.46,6.46,0,0,0,3.11.73Zm0-19.54a5.71,5.71,0,0,0,2.76-.65,4.8,4.8,0,0,0,2.59-4.34,4.69,4.69,0,0,0-.69-2.54,4.75,4.75,0,0,0-1.9-1.73,6.25,6.25,0,0,0-5.51,0,4.64,4.64,0,0,0-2.6,4.27,4.78,4.78,0,0,0,.69,2.55,4.9,4.9,0,0,0,1.91,1.78,5.62,5.62,0,0,0,2.75.66Z"
      />
    </svg>
  );
};

export default React.memo(Article8Chart);
