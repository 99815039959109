const getLocalAccessToken = () => {
  const access_token = localStorage.getItem('access_token');
  return access_token;
};

const setAccessToken = (new_token) => {
  localStorage.setItem('access_token', new_token);
};

const TokenService = {
  getLocalAccessToken,
  setAccessToken,
};

export default TokenService;
