import React from 'react';
import { SettingsComponentProps } from '../../SettingsRouter.component';
import makeStyles from '@mui/styles/makeStyles';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import AccountDetails from './subComponents/AccountDetails.component';

const useStyles = makeStyles(() => ({
  pageRoot: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    gap: '1rem',
  },
}));

const MyAccount: React.FC<SettingsComponentProps> = () => {
  const classes = useStyles();

  return (
    <GeneralComponentErrorShield
      dataObjects={[]}
      customLoadingMessages={[
        'Loading Account Data...',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <div className={classes.pageRoot}>
        <AccountDetails />
      </div>
    </GeneralComponentErrorShield>
  );
};

export default MyAccount;
