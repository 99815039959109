import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import PeExposureSecondLevelTable from './PeExposureSecondLevelTable.component';
import { IPeExposureTableData } from './PeExposureTable.component';

const PeExposureSecondLevelTableWrapper = () => [
  (rowData: IPeExposureTableData) => {
    const icon = () =>
      rowData.underlyingAssets.length ? <UnfoldMore /> : <></>;
    const render = () => (
      <PeExposureSecondLevelTable data={rowData} gmid={rowData.gmid} />
    );
    return {
      disabled: !rowData.underlyingAssets.length,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default PeExposureSecondLevelTableWrapper;
