import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import DashboardContainers from '../../dashboard/subComponents/DashboardContainers.component';
import { useSelector } from 'react-redux';
import { clientConfigurationSelector } from '../../../../redux/pages/selectors';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { Grid } from '@mui/material';

const MancoDashboard: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  const clientConfiguration = useSelector(clientConfigurationSelector);

  const data = new FormData();
  data.append('sections', JSON.stringify(clientConfiguration?.sections));

  const DashboardData = useFetchData({
    url: `/dashboard_data`,
    keyName: `dashboard_data`,
    makeFetch: clientConfiguration?.sections ? true : false,
    formData: data,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[DashboardData]}
      customLoadingMessages={['Loading Dashboard Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <DashboardContainers inputData={DashboardData} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default MancoDashboard;
