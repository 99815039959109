import React, { ReactElement } from 'react';
import withChartWrapper from '../withChartWrapper';
import { buildEvolutionChart } from '../portfolioLiquidation/portfolioLiquidation.data';
import { toTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';
import { mainColors } from '../../../../../../styling/theme';
import { Dimensions } from '../portfolioLiquidation/portfolioLiquidation.component';
import GenericLineChart from '../../../../../charts/GenericLineChart';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';

interface Props extends Dimensions {
  inputData: DataObject;
  height: number;
}

function LiquidationCost({
  inputData: { isFetching, error, data },
  height,
}: Props): ReactElement {
  const chartData = buildEvolutionChart(data, 'q3');
  const formatter = (value: number, name: string) => [
    `${toTwoDecimalPlaces(value)}%`,
    name === 'bau' ? 'BAU' : 'Stressed',
  ];
  const axisFormatter = (value: number) => value;
  return (
    <>
      <GenericLineChart
        error={error}
        loading={isFetching}
        export
        download
        width="90%"
        margin={{ top: 10, left: 0, right: 10, bottom: 10 }}
        title="Liquidation Cost"
        showTitle
        showLegend
        legendFormatter={(value: any, entry: any) => {
          const { color } = entry;
          let name;
          if (value === 'bau') {
            name = 'BAU';
          } else if (value === 'stressed') {
            name = 'Stressed';
          }

          return <span style={{ color }}>{name}</span>;
        }}
        yAxisTickFormatter={axisFormatter}
        yAxisLabel={{
          style: { textAnchor: 'middle' },
          value: '% of NAV',
          angle: -90,
          position: 'insideLeft',
          offset: 10,
        }}
        height={height}
        yAxisTicks={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6]}
        data={chartData}
        tooltipFormatter={formatter}
        lines={[
          {
            dataKey: 'bau',
            color: mainColors.mainBlue,
          },
          {
            dataKey: 'stressed',
            color: mainColors.secondaryRed,
          },
        ]}
      />
    </>
  );
}

export default withChartWrapper(LiquidationCost);
