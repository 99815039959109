import Introduction from './pages/introduction/introduction.md';
import Risk from './pages/risk/risk.md';
import RiskOverview from './pages/risk/overview.md';
import Exposure from './pages/risk/exposure.md';
import ValueAtRisk from './pages/risk/value_at_risk.md';
import ProspectusRestrictions from './pages/risk/prospectus_restrictions.md';
import CounterpartyExposure from './pages/risk/counterparty_exposure.md';

export type LinkType = {
  name: string;
  url: string;
};

export type PageType = {
  name: string;
  route: string;
  component: any;
  links?: LinkType[];
};

export type SectionType = {
  name: string;
  route: string;
  component: any;
  pages: PageType[];
  links?: LinkType[];
};

export const SectionsAndPages: SectionType[] = [
  {
    name: 'Introduction',
    route: 'introduction',
    component: Introduction,
    pages: [],
  },
  // {
  //   name: 'Getting Started',
  //   route: 'getting-started',
  //   component: GettingStarted,
  //   pages: [
  //     {
  //       name: 'Onboarding',
  //       route: 'onboarding',
  //       component: Onboarding,
  //     },
  //     {
  //       name: 'Orientation',
  //       route: 'orientation',
  //       component: Orientation,
  //     },
  //     {
  //       name: "What's On Offer",
  //       route: 'whats-on-offer',
  //       component: WhatsOnOffer,
  //     },
  //   ],
  // },
  {
    name: 'Risk',
    route: 'risk',
    component: Risk,
    pages: [
      {
        name: 'Overview',
        route: 'overview',
        component: RiskOverview,
        links: [
          {
            name: 'Sample Report 1',
            url: 'https://www.google.com',
          },
          {
            name: 'Sample Report 2',
            url: 'https://www.google.com',
          },
          {
            name: 'Sample Report 3',
            url: 'https://www.google.com',
          },
        ],
      },
      {
        name: 'Exposure',
        route: 'exposure',
        component: Exposure,
      },
      {
        name: 'Value At Risk',
        route: 'value-at-risk',
        component: ValueAtRisk,
      },
      {
        name: 'Prospectus Restrictions',
        route: 'prospectus-restrictions',
        component: ProspectusRestrictions,
      },
      {
        name: 'Counterparty Exposure',
        route: 'counterparty-exposure',
        component: CounterpartyExposure,
      },
    ],
  },
  // {
  //   name: 'Liquidity',
  //   route: 'liquidity',
  //   component: Liquidity,
  //   pages: [],
  // },
  // {
  //   name: 'Private Equity',
  //   route: 'private-equity',
  //   component: PrivateEquity,
  //   pages: [],
  // },
  // {
  //   name: 'Performance Attribution',
  //   route: 'performance-attribution',
  //   component: PerformanceAttribution,
  //   pages: [],
  // },
  // {
  //   name: 'RAIDR',
  //   route: 'raidr',
  //   component: RAIDR,
  //   pages: [],
  // },
];
