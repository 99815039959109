import React, { FC } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import CustomTable from '../../../../../tables/CustomTable';
import { greys } from '../../../../../../styling/theme';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import { addCommasToNumbersAndRound } from '../../../../../../utilities/numberFormatters';

interface PositionsTableProps {
  dataObject: DataObject;
}

export interface PositionsTableData {
  gm_name: string;
  gm_name_long: string;
  maturity_date: string;
  mr_position: number;
  risk_factor: number;
  hedged_class: string;
}

export function buildTableData(dataObject: DataObject): PositionsTableData[] {
  if (!dataObject.data || !dataObject.data.length) return [];
  else {
    const tableData: PositionsTableData[] = [];
    dataObject.data[0].forEach((value: any) => {
      tableData.push({
        gm_name: value.gm_name,
        gm_name_long: value.gm_name_long,
        maturity_date: value.maturity_date,
        mr_position: value.mr_position,
        risk_factor: value.risk_factor,
        hedged_class: value.hedged_class,
      });
    });
    return tableData;
  }
}

const columns: CustomColumn<PositionsTableData>[] = [
  {
    title: 'Asset Name',
    field: 'gm_name_long',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '50%',
  },
  {
    title: 'Maturity Date',
    field: 'maturity_date',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Position Size',
    field: 'mr_position',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PositionsTableData) =>
      addCommasToNumbersAndRound(rowData.mr_position),
  },
  {
    title: 'Risk Factor',
    field: 'risk_factor',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Hedged Class',
    field: 'hedged_class',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
];

const PositionsTable: FC<PositionsTableProps> = ({ dataObject }) => {
  const tableData = buildTableData(dataObject);

  return (
    <div
      style={{
        border: `10px solid ${greys.grey100}`,
      }}
    >
      <CustomTable<PositionsTableData>
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        columns={columns}
        data={tableData}
        showToolbar={true}
      />
    </div>
  );
};

export default PositionsTable;
