import React from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../../utilities/numberFormatters';
import SecondLevelWrapper from './SecondLevelWrapper.component';
import { SecondLevelTableData } from './SecondLevelTable.component';

interface SectorTableProps {
  data: any;
}

export interface SectorTableData {
  name: string;
  currencyPL: number;
  currencyPctPL: number;
  assetPL: number;
  assetPctPL: number;
  totalPL: number;
  totalPctPL: number;
  underlyingAssets: SecondLevelTableData[];
  volatility: number;
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<SectorTableData>[] = [
  {
    title: 'Sector Name',
    field: 'name',
    width: '25%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency PL',
    field: 'currencyPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      addCommasToNumbersAndRound(rowData.currencyPL),
  },
  {
    title: 'Currency PL %',
    field: 'currencyPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      percentageToNdecialPlaces(rowData.currencyPctPL / 100, 2),
  },
  {
    title: 'Asset PL',
    field: 'assetPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      addCommasToNumbersAndRound(rowData.assetPL),
  },
  {
    title: 'Asset PL %',
    field: 'assetPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      percentageToNdecialPlaces(rowData.assetPctPL / 100, 2),
  },
  {
    title: 'Total PL',
    field: 'totalPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      addCommasToNumbersAndRound(rowData.totalPL),
  },
  {
    title: 'Total PL %',
    field: 'totalPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      percentageToNdecialPlaces(rowData.totalPctPL / 100, 2),
  },
  {
    title: 'Volatility',
    field: 'volatility',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorTableData) =>
      percentageToNdecialPlaces(rowData.volatility / 100, 2),
  },
];

const buildTableData = (inputData: any): SectorTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SectorTableData[] = [];
    inputData.data[0].drop_down_breakdown.sector.map((sector: any) => {
      returnData.push({
        name: sector.identifier,
        currencyPL: sector.aggregate_currency_pl,
        currencyPctPL: sector.aggregate_currency_pl_pct,
        assetPL: sector.aggregate_asset_pl,
        assetPctPL: sector.aggregate_asset_pl_pct,
        totalPL: sector.aggregate_pl,
        totalPctPL: sector.aggregate_pct_pl,
        volatility: sector.volatility,
        underlyingAssets: sector.extra_info.map((asset: any) => {
          return {
            assetName: asset[0],
            assetClass: asset[1],
            assetType: asset[2],
            currency: asset[3],
            size: asset[4],
            totalPl: asset[6],
            totalPlPc: asset[7],
            assetPl: asset[8],
            assetPlPc: asset[9],
            currencyPl: asset[10],
            currencyPlPc: asset[11],
          };
        }),
      });
    });
    return returnData;
  }
};

const SectorTable: React.FC<SectorTableProps> = ({ data }) => {
  const builtTableData = buildTableData(data);

  const title = `Sub-Fund Historical Performance - Sector Daily Returns`;

  return (
    <GridItem xs={12} card>
      <CustomTable<SectorTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={title}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        detailPanel={SecondLevelWrapper()}
      />
    </GridItem>
  );
};

export default SectorTable;
