import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { ShareClassTableData } from '../shareClassTable.component.tsx/ShareClassTable.component';
import PositionsTableData from './PositionsTableData.component';

const PositionsTableWrapper = (fundId: string) => [
  (rowData: ShareClassTableData) => {
    const icon = () =>
      rowData.hedge_positions_ids.length != 0 ? <UnfoldMore /> : <></>;
    const render = () => (
      <PositionsTableData
        fundId={fundId}
        positionDate={rowData.positionDate}
        positionIds={rowData.hedge_positions_ids}
      />
    );
    return {
      disabled: !rowData.hedge_positions_ids,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default PositionsTableWrapper;
