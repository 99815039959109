import { PropsWithChildren, ReactElement } from 'react';
import { Status } from '../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../utilities/colorUtilities';
import { flexify } from '../../utilities/cssMixins';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RaptorTheme } from '../../styling/theme';
import { Typography } from '@mui/material';

interface Props {
  status: Status;
  height?: number | string;
  fontSize?: number | string;
  innerText?: string;
  linkToPage?: string;
  additionalHandler?: () => void;
}

const useStyles = makeStyles<RaptorTheme>(() => ({
  link: {
    textDecoration: 'none',
  },
}));

function GenericStatusCell(props: Props): ReactElement {
  const classes = useStyles();

  const Wrapper = props.linkToPage
    ? (props: PropsWithChildren<Props>) => (
        <Link
          className={classes.link}
          to={props.linkToPage!}
          onClick={props.additionalHandler}
        >
          {props.children}
        </Link>
      )
    : (props: PropsWithChildren<Props>) => (
        <div onClick={props.additionalHandler}>{props.children}</div>
      );
  return (
    <Wrapper {...props}>
      <div
        style={{
          height: props.height || '8rem',
          backgroundColor: mapStatusToColor(props.status),
          ...flexify('center', 'center'),
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ color: 'white', fontSize: props.fontSize || 16 }}
        >
          {props.innerText || props.status}
        </Typography>
      </div>
    </Wrapper>
  );
}

export default GenericStatusCell;
