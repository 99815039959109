import React, { FC } from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import { Tooltip } from '@mui/material';

interface StyleFormatButtonsProps {
  handleTogggleClick: (e: React.MouseEvent, inlineStyle: string) => void;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const StyleFormatButtons: FC<StyleFormatButtonsProps> = (props) => {
  const { handleTogggleClick } = props;

  const classes = useStyles();
  return (
    <div className={classes.buttonsContainer}>
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Format Selected Text in Bold"
      >
        <FormatBoldIcon
          sx={{
            color: 'white',
            fontSize: '2.8rem',
            backgroundColor: mainColors.controlButtonBlue,
            borderRadius: 1,
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.controlButtonBlue_lighter,
            },
          }}
          onMouseDown={(e) => handleTogggleClick(e, 'BOLD')}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Underline Selected Text"
      >
        <FormatUnderlinedIcon
          sx={{
            color: 'white',
            fontSize: '2.8rem',
            backgroundColor: mainColors.controlButtonBlue,
            borderRadius: 1,
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.controlButtonBlue_lighter,
            },
          }}
          onMouseDown={(e) => handleTogggleClick(e, 'UNDERLINE')}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Format Selected Text in Italic"
      >
        <FormatItalicIcon
          sx={{
            color: 'white',
            fontSize: '2.8rem',
            backgroundColor: mainColors.controlButtonBlue,
            borderRadius: 1,
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.controlButtonBlue_lighter,
            },
          }}
          onMouseDown={(e) => handleTogggleClick(e, 'ITALIC')}
        />
      </Tooltip>
    </div>
  );
};

export default StyleFormatButtons;
