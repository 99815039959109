import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../utilities/numberFormatters';

interface ExposureVsCumulativeTableProps {
  data: any;
}

interface ExposureVsCumulativeTableData {
  sector: string;
  aggregate_pct_gross_exposure: number;
  aggregate_pl: number;
  aggregate_pct_pl: number;
  difference_pct: number;
}

const detailColumns: CustomColumn<ExposureVsCumulativeTableData>[] = [
  {
    title: 'Sector',
    field: 'sector',
    width: '25%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Gross Exposure %',
    field: 'aggregate_pct_gross_exposure',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: ExposureVsCumulativeTableData) =>
      percentageToNdecialPlaces(rowData.aggregate_pct_gross_exposure / 100, 2),
  },
  {
    title: 'Calculated Returns',
    field: 'aggregate_pl',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: ExposureVsCumulativeTableData) =>
      addCommasToNumbersAndRound(rowData.aggregate_pl),
  },
  {
    title: 'Calculated Returns %',
    field: 'aggregate_pct_pl',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: ExposureVsCumulativeTableData) =>
      percentageToNdecialPlaces(rowData.aggregate_pct_pl / 100, 2),
  },
  {
    title: 'Portfolio Sector Returns %',
    field: 'diference_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: ExposureVsCumulativeTableData) =>
      percentageToNdecialPlaces(rowData.difference_pct / 100, 2),
  },
];

const buildTableData = (inputData: any): ExposureVsCumulativeTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: ExposureVsCumulativeTableData[] = [];
    inputData.data[0].sector_aggregation.map((sector: any) => {
      returnData.push({
        sector: sector.sector_name,
        aggregate_pct_gross_exposure: sector.aggregate_pct_gross_exposure,
        aggregate_pl: sector.aggregate_pl,
        aggregate_pct_pl: sector.aggregate_pct_pl,
        difference_pct:
          sector.aggregate_gross_exposure !== 0
            ? (sector.aggregate_pl / sector.aggregate_gross_exposure) * 100
            : 0,
      });
    });

    return returnData;
  }
};

const ExposureVsCumulative: React.FC<ExposureVsCumulativeTableProps> = ({
  data,
}) => {
  const builtTableData = buildTableData(data);

  return (
    <GridItem xs={12} card>
      <CustomTable<ExposureVsCumulativeTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={'Initial Exposure vs Cumulative Returns - Sector Analysis'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default ExposureVsCumulative;
