export interface IRstData {
  name: string;
  pass: number;
  alert: number;
  fail: number;
}

// what you can expect from the server
export interface RstFromServerData {
  // index 1 here is redundant, don't know why Peter has included
  data: [string, string, number, number, number][];
  date1: string;
  date2: string;
  fund_name: string;
}

export function buildRstData(inputData: RstFromServerData[]): IRstData[] {
  if (!inputData.length) return [];
  const dataForUse = [...inputData[0].data];
  dataForUse.shift();
  return dataForUse.map((row: [string, string, number, number, number]) => ({
    name: row[0],
    pass: giveVal(row[4]),
    alert: giveVal(row[3]),
    fail: giveVal(row[2]),
  }));
}

function giveVal(num: number) {
  // For some reason on the backend the values are all multiplied by 25
  // We cannot adjust this on the backed as the function is used in multiple locations
  // Any adjustments may have affects on other code so the solution is to do this division here.
  return num / 25;
  // switch (num) {
  //   case 100:
  //     return 4;
  //   case 75:
  //     return 3;
  //   case 50:
  //     return 2;
  //   case 25:
  //     return 1;
  //   default:
  //     return 0;
  // }
}
