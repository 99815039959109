import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  toFourDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import categoryProvider from '../../../../../utilities/categoryProvider';
import React, { FC, ReactElement } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { useTheme } from '@mui/material';

export interface CounterpartyMainTableData {
  asset: string;
  assetClass: string;
  assetInformation: string;
  assetType: string;
  currency: string;
  fxRate: string | number;
  localExposure: number | string;
  baseExposure: number | string;
  grossExposure: number | string;
  grossExposurePc: number | string;
  unrealisedPl: number | string;
  isin: string;
  headerRow: any;
}

export const csvCounterPartyMainTable = [
  'asset',
  'assetClass',
  'assetType',
  'isin',
  'currency',
  'fxRate',
  'localExposure',
  'baseExposure',
  'grossExposure',
  'grossExposurePc',
  'unrealisedPl',
];

export const counterpartyMainTableColumns: CustomColumn<CounterpartyMainTableData>[] =
  [
    { title: 'Asset', field: 'asset' },
    {
      title: 'Asset Class',
      field: 'assetClass',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
    },
    {
      title: 'Asset Type',
      field: 'assetType',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
    },
    {
      title: 'ISIN',
      field: 'isin',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
    },
    {
      title: 'Currency',
      field: 'currency',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
    },
    { title: 'FX Rate', field: 'fxRate' },
    {
      title: 'Local Exposure',
      field: 'localExposure',
      render: (rowData: CounterpartyMainTableData) => {
        return addCommasToNumbersAndRound(rowData.localExposure);
      },
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Base Exposure',
      field: 'baseExposure',
      render: (rowData: CounterpartyMainTableData) => {
        return addCommasToNumbersAndRound(rowData.baseExposure);
      },
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Gross Exposure',
      field: 'grossExposure',
      render: (rowData: CounterpartyMainTableData) => {
        return addCommasToNumbersAndRound(rowData.grossExposure);
      },
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Gross Exposure %',
      field: 'grossExposurePc',
      render: (rowData: CounterpartyMainTableData) => {
        return percentageToTwoDecimalPlaces(rowData.grossExposurePc as number);
      },
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Unrealised P/L',
      field: 'unrealisedPl',
      render: (rowData: CounterpartyMainTableData) => {
        return addCommasToNumbersAndRound(rowData.unrealisedPl as number);
      },
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];

export interface IExposureDetailData {
  name: string;
  price: number;
  delta: number;
  size: number;
  riskFactor: number;
  localExposure: number;
  baseExposure: number;
  grossExposure: number;
}

export const detailColumns: CustomColumn<IExposureDetailData>[] = [
  { title: 'Name', field: 'name' },
  {
    title: 'Price',
    field: 'price',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Delta',
    field: 'delta',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Size',
    field: 'size',
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => addCommasToNumbers(rowData.size),
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Risk Factor',
    field: 'riskFactor',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Local Exposure',
    field: 'localExposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
  },
  {
    title: 'Base Exposure',
    field: 'baseExposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
  },
  {
    title: 'Commitment',
    field: 'commitment',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
  },
];

export const CounterpartyMainTableDetailPanel = [
  (rowData: CounterpartyMainTableData) => ({
    disabled: rowData.headerRow || !rowData.assetInformation.length,
    tooltip: rowData.headerRow ? undefined : 'Additional Information',
    render: (rowData: CounterpartyMainTableData) => {
      return (
        <DetailPanelTable
          title={rowData.asset}
          data={rowData.assetInformation}
        />
      );
    },
  }),
];

export interface DetailPanelProps {
  data: any;
  title: string;
}

const DetailPanelTable: FC<DetailPanelProps> = (props) => {
  const theme = useTheme();
  return (
    <CustomTable<IExposureDetailData>
      columns={detailColumns}
      showToolbar={false}
      data={props.data}
      title={props.title}
      options={{
        headerStyle: {
          color: 'white',
          textAlign: 'left',
          backgroundColor: theme.palette.grey[100],
        },

        paging: false,
        exportButton: false,
      }}
    />
  );
};

export function buildMainCounterpartyData(selectedCounterpartyData: any) {
  const { exposure_data: data, nav } = selectedCounterpartyData;
  const finalData: CounterpartyMainTableData[] = [];

  Object.keys(data).forEach((item) => {
    const items = categoryProvider(item.slice(0, 1));
    const assetType = items[1];
    const obj: CounterpartyMainTableData = {
      asset: data[item].name,
      assetClass: item.includes('collateral') ? 'COLLATERAL' : assetType,
      assetInformation: data[item].underlying_assets,
      assetType:
        data[item].asset_type.split('')[0].toUpperCase() +
        data[item].asset_type.slice(1),
      isin: data[item].isin || '',
      currency: data[item].currency,
      fxRate: toFourDecimalPlaces(data[item].fx),
      localExposure: data[item].lc_exposure,
      baseExposure: data[item].bc_exposure,
      grossExposure: data[item].gross_exposure,
      grossExposurePc: data[item].gross_exposure / nav,
      unrealisedPl: data[item].unrealised_pl,
      headerRow: false,
    };

    const adjustedAssetInformationForDetail: any = [];
    data[item].underlying_assets.forEach((info: any) => {
      const [
        name,
        price,
        delta,
        size,
        risk_factor,
        local_exposure,
        base_exposure,
        gross_exposure,
        commitment,
        country_code,
        country_code_risk_level,
      ] = info;
      let newPrice;
      if (price >= 10) {
        newPrice = toTwoDecimalPlaces(price);
      } else {
        newPrice = toFourDecimalPlaces(price);
      }

      const detailObj = {
        name,
        price: newPrice,
        delta,
        size,
        riskFactor: risk_factor,
        localExposure: addCommasToNumbers(Math.round(local_exposure)),
        baseExposure: addCommasToNumbers(Math.round(base_exposure)),
        grossExposure: addCommasToNumbers(Math.round(gross_exposure)),
        commitment: addCommasToNumbers(Math.round(commitment)),
        countryCode: country_code,
        countryCodeRiskLevel: country_code_risk_level,
      };
      adjustedAssetInformationForDetail.push(detailObj);
    });

    obj['assetInformation'] = adjustedAssetInformationForDetail;

    finalData.push(obj);
  });
  // returning an array of objects
  return finalData;
}
interface CounterpartyMainTableProps {
  selectedCounterpartyData: any;
}
function CounterpartyMainTable({
  selectedCounterpartyData,
}: CounterpartyMainTableProps): ReactElement {
  const tableData = selectedCounterpartyData.data.length
    ? buildMainCounterpartyData(selectedCounterpartyData.data[0])
    : [];
  return (
    <GridItem xs={12} card>
      <CustomTable<CounterpartyMainTableData>
        data={tableData}
        csvFields={csvCounterPartyMainTable}
        columns={counterpartyMainTableColumns}
        showToolbar
        toolbarComponents={{
          toolbarTitle: 'Counterparty Data',
        }}
        loading={selectedCounterpartyData.loading}
        detailPanel={CounterpartyMainTableDetailPanel}
        options={{
          paging: false,
          grouping: true,
          exportButton: true,
          search: true,
          maxBodyHeight: 'calc(100vh - 8rem)',
        }}
      />
    </GridItem>
  );
}

export default CounterpartyMainTable;
