import { FC } from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import PrincipleAdverseImpactOverviewPanel from './PrincipleAdverseImpactOverviewPanel.component';
import PrincipleAdverseImpactReportTable from './PrincipleAdverseImpactReportTable.component';
import PrincipleAdverseImpactTable from './PrincipleAdverseImpactTable.component';
import NonPremiumSubscriptionDisplayMessage, {
  doesClientHavePremiumPackage,
} from '../NonPremiumSubscriptionDisplay/NonPremiumSubscriptionDisplayMessage.component';
import { Grid } from '@mui/material';

const PrincipleAdverseImpact: FC<FundInfoComponentProps> = ({
  fundName,
  fundId,
  positionDate,
}) => {
  const clientName = useSelector(clientNameSelector);

  const esgData = useFetchData({
    url: `get_fund_esg_data/${fundId}/${positionDate}`,
    keyName: `${fundId}_esg_fund_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const rulesData = useFetchData({
    keyName: 'esg_code_descriptions',
    url: 'get_esg_code_descriptions',
    makeFetch: true,
  });

  const doesClientHavePremiumSubscription =
    doesClientHavePremiumPackage(esgData);

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgData, rulesData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found'}
    >
      {doesClientHavePremiumSubscription ? (
        <Grid container spacing={2} style={{ padding: 8 }}>
          <PrincipleAdverseImpactOverviewPanel
            data={esgData}
            fundName={fundName}
          />
          <PrincipleAdverseImpactTable
            esgData={esgData}
            rulesData={rulesData}
          />
          {clientName === 'mersenne' && <PrincipleAdverseImpactReportTable />}
        </Grid>
      ) : (
        <NonPremiumSubscriptionDisplayMessage />
      )}
    </GeneralComponentErrorShield>
  );
};

export default PrincipleAdverseImpact;
