import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector } from '../redux/auth/selectors';
import { updateSection } from '../redux/pages/actions';
import withAuthorization from '../hoc/withAuthorisation';
import { clientSectionsSelector } from '../redux/pages/selectors';

export interface LoggedInRouteProps<P> extends RouteProps {
  Component: React.ComponentType<RouteComponentProps<P>>;
}

interface AuthenticatedPageProps<P> extends LoggedInRouteProps<P> {
  otherProps: any;
}

const sectionExpections = ['dashboard', 'settings', 'documentation'];

function AuthenticatedPage<P>(props: AuthenticatedPageProps<P>) {
  const { Component, otherProps } = props;
  const { section, pageId } = useParams<any>();
  const clientSections = useSelector(clientSectionsSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateSection(props.otherProps.match.params.section));
  }, [props.otherProps.match.params.section]);

  // Handles the exception routes
  const nonSectionParams = props.otherProps.location.pathname.split('/')[1];

  if (sectionExpections.includes(nonSectionParams)) {
    if (
      nonSectionParams === 'documentation' ||
      nonSectionParams === 'dashboard' ||
      nonSectionParams === 'settings'
    ) {
      return <Component {...otherProps} />;
    }
  }

  return withAuthorization(
    Component,
    section,
    pageId,
    clientSections,
  )(otherProps);
}

function LoggedInRoute<P>(props: LoggedInRouteProps<P>) {
  const { Component } = props;
  const isAuthenticated = useSelector(isLoggedInSelector);

  return (
    <Route
      {...props}
      render={(otherProps) =>
        isAuthenticated ? (
          <AuthenticatedPage otherProps={otherProps} Component={Component} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
export default LoggedInRoute;
