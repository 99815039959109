import { FC } from 'react';
import { Link } from 'react-router-dom';
import theme from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Status } from '../../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import secondLevelTableWrapper from './SecondLevelTableWrapper';
import { Typography } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';
import { statusSort } from '../../../../../utilities/customSorting';

export interface FirstLevelTableData {
  internalFundId: string;
  fundName: string;
  status: string;
  shareClassData: Array<any>;
  defaultShareClass: string;
  shareClassIsin: string;
  publishedSrri: number;
  calculatedSrri: number;
  calculationDate: string;
  fundType: string;
  shareClassCurrency: string;
  calculatedVolatility: number;
  weekOfDeviation: string;
  srriDate: string;
  shareClassGmid: string;
}

export interface FirstLevelTableProps {
  data: any;
}

export const SrriOverviewStatusBox = ({
  status,
  fundId,
  shareClass,
}: {
  status: Status;
  fundId?: string;
  shareClass?: string;
}) => (
  <div
    style={{
      padding: '.8rem',
      borderRadius: 8,
      border: `1px solid ${mapStatusToColor(specifyStatus(status))}`,
    }}
  >
    {fundId && shareClass ? (
      <Link
        to={`/ucits/srri-share-class?fundId=${fundId}&shareClass=${shareClass}`}
        style={{ textDecoration: 'none' }}
      >
        <Typography
          variant="h3"
          style={{
            transform: 'translateY(1px)',
            color: mapStatusToColor(status),
            fontSize: '1.8rem',
          }}
        >
          Status: {status}
        </Typography>
      </Link>
    ) : (
      <Typography
        variant="h3"
        style={{
          transform: 'translateY(1px)',
          color: mapStatusToColor(status),
          fontSize: '1.8rem',
        }}
      >
        Status: {status}
      </Typography>
    )}
  </div>
);

export const firstLevelColumns = (): CustomColumn<FirstLevelTableData>[] => [
  {
    title: 'Internal Fund Name',
    field: 'internalFundId',
    width: '8%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
  },
  {
    title: 'Fund Name',
    field: 'fundName',
    width: '20%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
  },
  {
    title: 'Default Share Class',
    field: 'defaultShareClass',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Share Class ISIN',
    field: 'shareClassIsin',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Published SRRI',
    field: 'publishedSrri',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Calculated SRRI',
    field: 'calculatedSrri',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Calculation Date',
    field: 'calculationDate',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Fund Type',
    field: 'fundType',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Share Class Currency',
    field: 'shareClassCurrency',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Calculated Volatility',
    field: 'calculatedVolatility',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: FirstLevelTableData) =>
      percentageToTwoDecimalPlaces(rowData.calculatedVolatility),
  },
  {
    title: 'Week of Deviation',
    field: 'weekOfDeviation',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'SRRI Date',
    field: 'srriDate',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'status',
    pdfRenderType: 'PageLinkButton',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: FirstLevelTableData) => (
      <SrriOverviewStatusBox
        status={specifyStatus(rowData.status)}
        fundId={
          rowData.shareClassData.length === 1
            ? rowData.internalFundId
            : undefined
        }
        shareClass={
          rowData.shareClassData.length === 1
            ? rowData.shareClassGmid
            : undefined
        }
      />
    ),
    width: '10%',
    customSort: (a: FirstLevelTableData, b: FirstLevelTableData) =>
      statusSort(a, b, 'status'),
  },
];

export function buildFirstLevelSrriData(data: any) {
  if (!data.data.length) return [];
  const fundsData = data.data[0].slice(1);
  // Get the data headers
  // const headers = fundsData.shift();
  // Define type for the statuses object
  interface statusObject {
    [key: string]: FirstLevelTableData;
  }
  // create an object for storing the status of each fund (if the srri is in bounds)
  // In this case true indicates pass and false indicates fail
  const statuses: statusObject = {};
  // Map over each of the elements in the data
  fundsData.forEach((value: any) => {
    // Check if the fund already has a status and if not add it in to the object
    if (!(value[0] in statuses)) {
      statuses[value[0]] = {
        internalFundId: value[0],
        fundName: value[1],
        status: value[10] === value[11] ? 'Pass' : 'Fail',
        shareClassData: [value],
        defaultShareClass: value[9] ? value[6] : '',
        shareClassIsin: value[9] ? value[8] : '',
        publishedSrri: value[9] ? value[10] : 0,
        calculatedSrri: value[9] ? value[11] : 0,
        calculationDate: value[9] ? value[14] : '',
        fundType: value[9] ? value[4] : '',
        shareClassCurrency: value[9] ? value[7] : '',
        calculatedVolatility: value[9] ? value[12] : '',
        weekOfDeviation: value[9] ? value[15] : '',
        srriDate: value[9] ? value[13] : '',
        shareClassGmid: value[9] ? value[5] : '',
      };
    }
    // Case where defualt share class is not first in list
    else if (value[9] === true) {
      const status =
        statuses[value[0]].status === 'Fail' || value[10] !== value[11]
          ? 'Fail'
          : 'Pass';
      const shareClassData = statuses[value[0]].shareClassData;
      shareClassData.push(value);
      statuses[value[0]] = {
        internalFundId: value[0],
        fundName: value[1],
        status: status,
        shareClassData: shareClassData,
        defaultShareClass: value[9] ? value[6] : '',
        shareClassIsin: value[9] ? value[8] : '',
        publishedSrri: value[9] ? value[10] : 0,
        calculatedSrri: value[9] ? value[11] : 0,
        calculationDate: value[9] ? value[14] : '',
        fundType: value[9] ? value[4] : '',
        shareClassCurrency: value[9] ? value[7] : '',
        calculatedVolatility: value[9] ? value[12] : '',
        weekOfDeviation: value[9] ? value[15] : '',
        srriDate: value[9] ? value[13] : '',
        shareClassGmid: value[9] ? value[5] : '',
      };
    } else {
      statuses[value[0]].status =
        statuses[value[0]].status === 'Fail' || value[10] !== value[11]
          ? 'Fail'
          : 'Pass';
      statuses[value[0]].shareClassData.push(value);
    }
  });
  return Object.values(statuses);
}

const FirstLevelTable: FC<FirstLevelTableProps> = (props) => {
  const tableData = buildFirstLevelSrriData(props.data);
  const columns = firstLevelColumns();

  return (
    <GridItem xs={12} card>
      <CustomTable<FirstLevelTableData>
        id="share_class_overview_first_level"
        showToolbar={true}
        columns={columns}
        options={{
          exportButton: true,
          paging: false,
          search: true,
          rowStyle: (rowData: FirstLevelTableData) => ({
            padding: 0,
            color: theme.palette.grey[900],
          }),
        }}
        data={tableData}
        detailPanel={secondLevelTableWrapper()}
      />
    </GridItem>
  );
};

export default FirstLevelTable;
