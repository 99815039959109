import {
  addCommasToNumbersAndRound,
  formatMillions,
} from '../../../../../utilities/numberFormatters';
import { LabelProps } from 'recharts';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { FC } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { flexify } from '../../../../../utilities/cssMixins';
import GenericBarChart from '../../../../charts/GenericBarChart';
import { mainColors } from '../../../../../styling/theme';
import { AssetClassBcExposure } from '../../../sharedComponents/exposureComponents/ExposureBarChart';
import { useTheme } from '@mui/material';

export interface ICounterpartyBarChartProps {
  inputData: DataObject;
}
export interface ICounterpartyChartData {
  name: string;
  baseExposure: number;
}

export const buildCounterpartyChart = (data: any[]) => {
  if (!data.length) return [];
  const assetClasses: AssetClassBcExposure = data[0].asset_class_bc_exposure;
  return [
    {
      name: 'Equity',
      baseExposure: assetClasses.equity,
    },
    {
      name: 'Commodity',
      baseExposure: assetClasses.commodity,
    },
    {
      name: 'Foreign Exchange',
      baseExposure: assetClasses.foreign_exchange,
    },
    {
      name: 'Fixed Income',
      baseExposure: assetClasses.fixed_income,
    },
    {
      name: 'Cash',
      baseExposure: assetClasses.cash,
    },
    {
      name: 'Alternative / CIS',
      baseExposure: assetClasses.alternative,
    },
  ];
};
const CounterpartyBarChart: FC<ICounterpartyBarChartProps> = (props) => {
  const chartData = buildCounterpartyChart(props.inputData.data);
  const { width, height } = useWindowSize();
  const theme = useTheme();
  const genericYAxisLabelProps: LabelProps = {
    angle: -90,
    position: 'insideLeft',
    fontSize: '1.4rem',
    fill: theme.palette.grey[900],
    style: {
      textAnchor: 'middle',
    },
  };
  return (
    <GridItem
      xs={8}
      card
      cardStyle={{
        height: '50rem',
        ...flexify('center', 'center', 'column'),
      }}
    >
      <GenericBarChart
        yAxisFormatter={(value) =>
          Math.abs(parseFloat(value)) < 1000000
            ? addCommasToNumbersAndRound(value)
            : formatMillions(value)
        }
        tooltipFormatter={(value: any, name: any) => [
          addCommasToNumbersAndRound(value as number),
          'Base Exposure',
        ]}
        margin={{ top: 0, bottom: 30, left: 50, right: 50 }}
        loading={props.inputData.isFetching}
        title="Base Currency Exposure"
        data={chartData}
        xAxes={[
          {
            tick: {
              fontSize: '1.4rem',
            },
            label: {
              value: 'Base Exposure',
              offset: 0,
              position: 'bottom',
              style: {
                fill: theme.palette.grey[700],
              },
            },
            dataKey: 'name',
          },
        ]}
        height={'90%'}
        yAxisLabel={{
          value: 'Exposure',
          offset: -16,
          ...genericYAxisLabelProps,
        }}
        width={'100%'}
        bars={[
          {
            dataKey: 'baseExposure',
            fill: mainColors.mainBlue,
            key: 'counterpartyBarExposure',
          },
        ]}
      />
    </GridItem>
  );
};

export default CounterpartyBarChart;
