import { FC } from 'react';
import { mainColors } from '../../../../styling/theme';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import GenericBarChart from '../../../charts/GenericBarChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import { useTheme } from '@mui/material';

interface ScoreStressTestChartProps {
  data: any;
  chartTitle: string;
}

function getChartColour(chartTitle: string): string {
  switch (chartTitle) {
    case 'Environmental Score Stress Test P/L':
    case 'Disorderly Environmental Score Stress Test P/L':
      return mainColors.Pass_darker;
    case 'Social Score Stress Test P/L':
    case 'Disorderly Social Score Stress Test P/L':
      return mainColors.pumpkin;
    case 'Governance Score Stress Test P/L':
    case 'Disorderly Governance Score Stress Test P/L':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

const ScoreStressTestChart: FC<ScoreStressTestChartProps> = (props) => {
  const { data, chartTitle } = props;

  // const chartData = data.data[0];
  const theme = useTheme();
  return (
    <GridItem xs={6} card>
      <GenericBarChart
        title={chartTitle}
        id={`esg_stress_chart_${chartTitle}`}
        loading={false}
        bars={[
          {
            dataKey: 'score',
            fill: getChartColour(chartTitle),
            key: 'score',
            // yAxisId: 'left'
          },
        ]}
        loadingHeight={400}
        height={400}
        margin={{ top: 0, bottom: 24, left: 24, right: 24 }}
        width={'100%'}
        legend={false}
        yAxisLabel={{
          position: 'left',
          angle: -90,
          offset: 0,
          fontSize: '1.4rem',
          fill: theme.palette.grey[700],
          value: 'P/L',
          style: {
            textAnchor: 'middle',
          },
        }}
        yAxisFormatter={(val) => percentageToTwoDecimalPlacesNoHundred(val)}
        tooltipFormatter={(val: any) => [
          percentageToTwoDecimalPlacesNoHundred(val as number),
          'P/L',
        ]}
        xAxes={[
          {
            label: {
              value: 'Score Filter Level',
              offset: 10,
              style: {
                fill: theme.palette.grey[700],
                fontSize: '1.4rem',
              },
              position: 'bottom',
            },
            dataKey: 'name',
          },
        ]}
        data={data}
        exportButton={false}
      />
    </GridItem>
  );
};

export default ScoreStressTestChart;
