import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { categoryProviderByNumber } from '../../../../../../utilities/categoryProvider';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';

export interface ProspectusRestrictionsSecondLevelTableData {
  number: number;
  genericMarket: string;
  isin: string;
  info: string;
  riskFactor: string;
  position: string;
  mkt: number;
  risk: number;
  exposure: number;
  countryCode: string;
  currency: string;
}

export const ProspectusRestrictionsSecondLevelColumns: CustomColumn<ProspectusRestrictionsSecondLevelTableData>[] =
  [
    {
      title: '#',
      field: 'number',
      width: 1,
      cellStyle: {
        backgroundColor: 'rgba(68, 101, 151, 0.35)',
        borderBottom: `1px solid #446699`,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        borderBottom: `1px solid #446699`,
        backgroundColor: 'rgb(232, 232, 232)',
      },
    },
    {
      title: 'Generic Market',
      field: 'generic_market',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'ISIN',
      field: 'isin',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Info',
      field: 'info',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Risk Factor',
      field: 'risk_factor',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Position',
      field: 'position',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Mkt',
      field: 'mkt',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },

      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Risk',
      field: 'risk',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Exposure %',
      field: 'exposure_pc',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        transition: 'all .5s',
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Country Code',
      field: 'country_code',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
    {
      title: 'Currency',
      field: 'currency',
      headerStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: '1px solid #446699',
      },
      cellStyle: {
        backgroundColor: 'rgb(232, 232, 232)',
        borderBottom: `1px solid #446699`,
      },
    },
  ];

export function buildSecondaryData(data: any, positions: any, fundId: string) {
  const relevantParam = data.name; // this is the limit status that we're looking for.

  let newData;
  if (positions) {
    const relevantPositions = positions.filter((position: any) => {
      return position.impacted_prospectus_rules.includes(relevantParam);
    });

    newData = relevantPositions.map((item: any, index: number) => {
      return {
        number: index + 1,
        generic_market: item.gm_name,
        isin: item.isin,
        info: categoryProviderByNumber(item.gm_id)[1],
        risk_factor: item.risk_factor,
        position: addCommasToNumbersAndRound(item.raw_position),
        mkt: toTwoDecimalPlaces(item.market_price),
        risk: addCommasToNumbersAndRound(item.risk_metric_value),
        exposure_pc: percentageToTwoDecimalPlaces(item.exposure),
        country_code: item.country_code,
        currency: item.base_currency,
        clientIndustry: item.client_industry,
      };
    });
  } else {
    newData = [];
  }

  return newData;
}
