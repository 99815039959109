import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import { LiquidityUrls } from '../fundAssetLiquidationTime/component';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import RstChart from './chart/rstChart.component';
import { DatePicker } from '../../../../types/redux/pages/PagesStore';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createSpecificTitleBarDatePickerSelector,
} from '../../../../redux/pages/selectors';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { dateOneMonthAgo } from '../../../../utilities/dateFormatters';

const RstEvolution: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
  section,
}) => {
  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  const startDatePicker: DatePicker = {
    titleBarKey: 'startDate',
    titleBarTitle: 'Select Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const { pathname, search } = useLocation();

  const endDatePicker: DatePicker = {
    titleBarKey: 'endDate',
    titleBarTitle: 'Select End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const activeSection = useSelector(activeSectionSelector);

  useAdditionalTitleBarDatePicker(startDatePicker);
  useAdditionalTitleBarDatePicker(endDatePicker);

  const startSel = createSpecificTitleBarDatePickerSelector('startDate');
  const startDate = useSelector(startSel);
  const endSel = createSpecificTitleBarDatePickerSelector('endDate');
  const endDate = useSelector(endSel);

  const startDateProper = startDate.length
    ? startDate[0]?.currentValue
    : dayjs(dateOneMonthAgo()).format('YYYY-MM-DD');
  const endDateProper = endDate.length
    ? endDate[0]?.currentValue
    : dayjs().format('YYYY-MM-DD');

  const ucits = useFetchData({
    keyName: `${fundId}_rst_ucits_${startDateProper}_${endDateProper}`,
    url: `${LiquidityUrls.FETCH_RST}/${fundId}/${
      section.includes('ucits') ? 'ucits' : 'sif'
    }/${startDateProper}/${endDateProper}`,
    makeFetch:
      startDateProper !== null &&
      endDateProper !== null &&
      section !== 'partii' &&
      section !== 'qiaif',
  });
  const risk = useFetchData({
    keyName: `${fundId}_rst_risk_${startDateProper}_${endDateProper}`,
    url: `${LiquidityUrls.FETCH_RST}/${fundId}/risk/${startDateProper}/${endDateProper}`,
    makeFetch: startDateProper !== null && endDateProper !== null,
  });

  const { height, width } = useWindowSize();
  const renderHeight = (height - 324) / 2;
  const renderWidth = '100%';
  return (
    <GeneralComponentErrorShield dataObjects={[risk]}>
      {section !== 'partii' && section !== 'qiaif' && (
        <RstChart
          inputData={ucits}
          title={`Liquidity Reverse Stress Test - ${
            section.includes('ucits')
              ? 'UCITS Compliance'
              : 'CSSF Risk Spreading Compliance'
          }`}
          dimensions={{ xs: 12 }}
          heightAndWidth={{ height: renderHeight, width: renderWidth }}
          fundId={fundId}
        />
      )}
      <RstChart
        inputData={risk}
        title="Liquidity Reverse Stress Test - Risk Compliance"
        dimensions={{ xs: 12 }}
        heightAndWidth={{ height: renderHeight, width: renderWidth }}
        fundId={fundId}
      />
    </GeneralComponentErrorShield>
  );
};

export default RstEvolution;
