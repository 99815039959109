import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { DataObject, Status } from '../../../../../types/redux/data/dataTypes';
import RaptorStatusBox from '../../../../feedback/RaptorStatusBox.component';

interface PerformanceDiagnosticsTableProps {
  data: DataObject;
}

export interface PerformanceDiagnosticsTableData {
  metric: string;
  fundValue: number | string;
  shareClassValue: number | string;
  difference: number | string;
  diagnosticValue: number | string;
  diagnosticLimits: number | string;
  status: Status;
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<PerformanceDiagnosticsTableData>[] = [
  {
    title: 'Metric',
    field: 'metric',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Fund',
    field: 'fundValue',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: PerformanceDiagnosticsTableData) => {
      if (typeof rowData.fundValue === 'number') {
        return roundNumberToTwoDecimalPlaces(rowData.fundValue);
      } else {
        return rowData.fundValue;
      }
    },
  },
  {
    title: 'Share Class',
    field: 'shareClassValue',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: PerformanceDiagnosticsTableData) => {
      if (typeof rowData.shareClassValue === 'number') {
        return roundNumberToTwoDecimalPlaces(rowData.shareClassValue);
      } else {
        return rowData.shareClassValue;
      }
    },
  },
  {
    title: 'Difference',
    field: 'difference',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: PerformanceDiagnosticsTableData) => {
      if (typeof rowData.difference === 'number') {
        return roundNumberToTwoDecimalPlaces(rowData.difference);
      } else {
        return rowData.difference;
      }
    },
  },
  {
    title: 'Diagnostic Value',
    field: 'diagnosticValue',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: PerformanceDiagnosticsTableData) => {
      if (typeof rowData.diagnosticValue === 'number') {
        return roundNumberToTwoDecimalPlaces(rowData.diagnosticValue);
      } else {
        return rowData.diagnosticValue;
      }
    },
  },
  {
    title: 'Diagnostic Limits',
    field: 'diagnosticLimits',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: PerformanceDiagnosticsTableData) => {
      if (typeof rowData.diagnosticLimits === 'number') {
        return roundNumberToTwoDecimalPlaces(rowData.diagnosticLimits);
      } else {
        return rowData.diagnosticLimits;
      }
    },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '15%',
    render: (rowData: PerformanceDiagnosticsTableData) => {
      let tooltipValue = '';
      if (rowData.metric === 'Mean') {
        tooltipValue =
          "The Status will show an 'ALERT' if the mean value of the calculated daily returns is not equal to or between +/- mean value of the realised daily returns multiplied by its volatility for the selected duration";
      } else if (rowData.metric === 'Standard Deviation') {
        tooltipValue =
          "The Status will show an 'ALERT' if the Difference in the calculated standard deviation of the daily returns and realised standard deviation of the daily returns for the selected duration is greater than 20% of the realised standard deviation value, else it will be a 'PASS'";
      } else if (rowData.metric === 'R^2') {
        tooltipValue =
          "The Status will show an 'ALERT' if the correlation value between the Calculated daily returns and the realised daiy returns is less than or equal to 0.95";
      }
      return (
        <RaptorStatusBox status={rowData.status} tooltipValue={tooltipValue} />
      );
    },
  },
];

const buildTableData = (inputData: any): PerformanceDiagnosticsTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const outputData: PerformanceDiagnosticsTableData[] = [];
    outputData.push({
      metric: inputData.data[0].check_fit['Mean'].Criteria,
      fundValue: inputData.data[0].check_fit['Mean'].Fund,
      shareClassValue: inputData.data[0].check_fit['Mean'].Realised_SC,
      difference: inputData.data[0].check_fit['Mean'].Differences,
      diagnosticValue:
        inputData.data[0].check_fit['Mean']['Diagnostic Value'] || '-',
      diagnosticLimits:
        inputData.data[0].check_fit['Mean']['Diagnostic Limits'] || '-',
      status: inputData.data[0].check_fit['Mean'].Status,
    });
    outputData.push({
      metric: inputData.data[0].check_fit['Standard Deviation'].Criteria,
      fundValue: inputData.data[0].check_fit['Standard Deviation'].Fund,
      shareClassValue:
        inputData.data[0].check_fit['Standard Deviation'].Realised_SC,
      difference: inputData.data[0].check_fit['Standard Deviation'].Differences,
      diagnosticValue:
        inputData.data[0].check_fit['Standard Deviation']['Diagnostic Value'] ||
        '-',
      diagnosticLimits:
        inputData.data[0].check_fit['Standard Deviation'][
          'Diagnostic Limits'
        ] || '-',
      status: inputData.data[0].check_fit['Standard Deviation'].Status,
    });
    outputData.push({
      metric: inputData.data[0].check_fit['Cumulative Return'].Criteria,
      fundValue: inputData.data[0].check_fit['Cumulative Return'].Fund,
      shareClassValue:
        inputData.data[0].check_fit['Cumulative Return'].Realised_SC,
      difference: inputData.data[0].check_fit['Cumulative Return'].Differences,
      diagnosticValue:
        inputData.data[0].check_fit['Cumulative Return']['Diagnostic Value'] ||
        '-',
      diagnosticLimits:
        inputData.data[0].check_fit['Cumulative Return']['Diagnostic Limits'] ||
        '-',
      status: inputData.data[0].check_fit['Cumulative Return'].Status,
    });
    outputData.push({
      metric: inputData.data[0].check_fit['Annualized Volatility'].Criteria,
      fundValue: inputData.data[0].check_fit['Annualized Volatility'].Fund,
      shareClassValue:
        inputData.data[0].check_fit['Annualized Volatility'].Realised_SC,
      difference:
        inputData.data[0].check_fit['Annualized Volatility'].Differences,
      diagnosticValue:
        inputData.data[0].check_fit['Annualized Volatility'][
          'Diagnostic Value'
        ] || '-',
      diagnosticLimits:
        inputData.data[0].check_fit['Annualized Volatility'][
          'Diagnostic Limits'
        ] || '-',
      status: inputData.data[0].check_fit['Annualized Volatility'].Status,
    });
    outputData.push({
      metric: inputData.data[0].check_fit['rsquare'].Criteria,
      fundValue: '',
      shareClassValue: '',
      difference: '',
      diagnosticValue:
        inputData.data[0].check_fit['rsquare']['Diagnostic Value'] || '-',
      diagnosticLimits:
        inputData.data[0].check_fit['rsquare']['Diagnostic Limits'] || '-',
      status: inputData.data[0].check_fit['rsquare'].Status,
    });
    return outputData;
  }
};

const PerformanceDiagnosticsTable: React.FC<
  PerformanceDiagnosticsTableProps
> = ({ data }) => {
  const builtTableData = buildTableData(data);

  const title = `Performance Diagnostics - Overview`;

  return (
    <GridItem xs={12} card>
      <CustomTable<PerformanceDiagnosticsTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={title}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default PerformanceDiagnosticsTable;
