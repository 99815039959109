import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import PeValueAtRiskTimeSeries from './lineChart/PeValueAtRiskTimeSeries.component';
import { Grid } from '@mui/material';

const prepareTimeSeriesData = (data: any) => {
  // Get the time series data values lists
  const discountRateTimeSeries = data.data[0]['Discount_rate'];
  const historicalLiquidityValueAtRisk =
    data.data[0]['Historical_Liquidity_Value_at_Risk'];
  const historicalValueAtRisk = data.data[0]['Historical_Value_at_Risk'];

  const dateKeys = Object.keys(discountRateTimeSeries);
  dateKeys.sort();
  const preparedData: any[] = [];
  dateKeys.forEach((key: string) => {
    preparedData.push([
      key,
      historicalValueAtRisk[key],
      historicalLiquidityValueAtRisk[key],
      discountRateTimeSeries[key],
    ]);
  });
  return preparedData;
};

const PeMarketRiskOverview: FC<FundInfoComponentProps> = (props) => {
  const { fundId } = props;
  // Pull data
  const varData = useFetchData({
    url: 'get_private_equity_market_risk_summary',
    keyName: fundId + 'pe_stress_scenario_test_data',
    makeFetch: true,
  });
  const { height, width } = useWindowSize();
  const adjustedHeight = height - 204;
  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      {varData &&
        (varData.isFetching ? (
          <GridItem
            xs={12}
            lg={12}
            style={{
              display: 'flex',
              // alignItems: personalData.isFetching ? 'center' : 'flex-start',
              alignItems: 'center',
              // justifyContent: personalData.isFetching ? 'flex-start' : 'center',
              justifyContent: 'center',
              verticalAlign: 'center',
              height: adjustedHeight,
            }}
          >
            <RaptorLoading
              messages={[
                'Loading Value at Risk Data...',
                'This may take a few moments...',
              ]}
            />
          </GridItem>
        ) : (
          <>
            {/* <PeValueAtRiskTable varData={varData} /> */}

            <PeValueAtRiskTimeSeries
              timeSeriesData={prepareTimeSeriesData(varData)}
              tableData={varData}
            />
          </>
        ))}
    </Grid>
  );
};

export default PeMarketRiskOverview;
