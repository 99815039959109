import { useEffect } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../redux/auth/selectors';
import { openingRouteSelector } from '../redux/pages/selectors';

const LoggedOutRoute = (props: RouteProps) => {
  const history = useHistory();
  const openingRoute = useSelector(openingRouteSelector)!;
  const isAuthenticated = useSelector(isLoggedInSelector);
  const location = useLocation();

  useEffect(() => {
    const { from } = (location.state as { from: any }) || {
      from: { pathname: '/' },
    };

    if (isAuthenticated) {
      // If the user was brought to the login page from another page, redirect them back to that page.
      // else, redirect them to the opening route. (e.g. /dashboard)
      // Maybe this should be changed, should it be the first page of the first section and not hardcoded?
      if (from.pathname !== '/' && from.pathname !== null) {
        history.replace(from);
      } else if (openingRoute) {
        history.push(openingRoute);
      } else {
        history.push('/');
      }
    }
  }, [history, isAuthenticated, openingRoute]);

  return <Route {...props} />;
};
export default LoggedOutRoute;
