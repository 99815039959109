import makeStyles from '@mui/styles/makeStyles';
import React, { Dispatch, FC, ReactElement, useState } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { roundNumberToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import HelpIcon from '@mui/icons-material/Help';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { Button, Tooltip, Typography } from '@mui/material';

interface CarbonFootprintOverviewPanelProps {
  data: any;
  fundId: string | null;
  fundName: string | null;
  positionDate?: string;
}

interface OverviewTimeSeriesData {
  name: string;
  carbonFootprint: number;
  carbonIntensity: number;
  totalCarbonEmissions: number;
  averageCarbonIntensity: number;
}

const useOverviewPanelStyles = makeStyles<RaptorTheme>((theme) => ({
  footprintMetricsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
  metricValueContainer: {
    display: 'flex',
    marginTop: '1rem',
  },
  numberContainer: {
    marginLeft: '0.5rem',
    borderStyle: 'solid',
    borderRadius: 10,
    borderColor: greys.grey600,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
  },
  title: {
    display: 'flex',
    color: mainColors.mainBlue,
  },
}));

interface CarbonButtonProps {
  displayValue: string;
  keyValue: string;
  currentlySelectedKey: string;
  onClick: Dispatch<string>;
}

interface StyleProps {
  buttonActive: boolean;
}

const useCarbonButtonStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  CarbonButton: {
    backgroundColor: (props) =>
      props.buttonActive ? mainColors.mainBlue_lighter : greys.grey400,
    color: 'white',
    width: '100%',
    maxWidth: '25rem',
    maxHeight: '4rem',
    '&:hover': {
      backgroundColor: (props) =>
        props.buttonActive ? mainColors.mainBlue_lighter : mainColors.mainBlue,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '20rem',
  },
}));

export function CarbonButton({
  displayValue,
  keyValue,
  currentlySelectedKey,
  onClick,
}: React.PropsWithChildren<CarbonButtonProps>): ReactElement {
  const handleClick = () => {
    onClick(keyValue);
  };
  const classes = useCarbonButtonStyles({
    buttonActive: keyValue === currentlySelectedKey,
  });
  return (
    <Button onClick={handleClick} className={classes.CarbonButton}>
      {displayValue}
    </Button>
  );
}

interface MetricsData {
  carbonFootprint: number;
  carbonIntensity: number;
  totalCarbonEmissions: number;
  averageCarbonIntensity: number;
}

function buildMetricsData(data: any): MetricsData {
  if (!('tcfd' in data.data[0])) {
    return {
      carbonFootprint: 0,
      carbonIntensity: 0,
      totalCarbonEmissions: 0,
      averageCarbonIntensity: 0,
    };
  }
  const currentScores = data.data[0]['tcfd'];
  return {
    carbonFootprint: currentScores.carbon_footprint,
    carbonIntensity: currentScores.carbon_intensity,
    totalCarbonEmissions: currentScores.total_carbon_emissions,
    averageCarbonIntensity: currentScores.weighted_average_carbon_intensity,
  };
}

const CarbonFootprintOverviewPanel: FC<CarbonFootprintOverviewPanelProps> = ({
  data,
  fundId,
  fundName,
  positionDate,
}) => {
  const classes = useOverviewPanelStyles();

  const [currentlySelected, setCurrentlySelected] =
    useState<string>('carbonFootprint');

  const metrics = buildMetricsData(data);

  const timeSeriesData = useFetchData({
    url: `/get_fund_esg_timeseries/${fundId}/${positionDate}`,
    keyName: `esg_timeseries_data_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <>
      <GridItem
        card
        xs={12}
        md={5}
        lg={3}
        cardStyle={{
          padding: 8,
          height: '100%',
        }}
      >
        <div>
          <div className={classes.title}>
            <Typography variant="h2">Carbon Footprint Metrics</Typography>
            <Tooltip
              title={
                <Typography variant="h3" style={{ color: 'white' }}>
                  {'Click on a Metric below to view on Chart.'}
                </Typography>
              }
              placement="top"
            >
              <HelpIcon style={{ cursor: 'pointer' }} />
            </Tooltip>
          </div>
          <div className={classes.metricValueContainer}>
            <CarbonButton
              displayValue="Carbon Footprint"
              keyValue="carbonFootprint"
              onClick={setCurrentlySelected}
              currentlySelectedKey={currentlySelected}
            />
            <div className={classes.numberContainer}>
              <Typography variant="h2">
                {roundNumberToTwoDecimalPlaces(metrics.carbonFootprint)}
              </Typography>
            </div>
          </div>
          <div className={classes.metricValueContainer}>
            <CarbonButton
              displayValue="Carbon Intensity"
              keyValue="carbonIntensity"
              onClick={setCurrentlySelected}
              currentlySelectedKey={currentlySelected}
            />
            <div className={classes.numberContainer}>
              <Typography variant="h2">
                {roundNumberToTwoDecimalPlaces(metrics.carbonIntensity)}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.metricValueContainer}>
          <CarbonButton
            displayValue="Total Carbon Emissions"
            keyValue="totalCarbonEmissions"
            onClick={setCurrentlySelected}
            currentlySelectedKey={currentlySelected}
          />
          <div className={classes.numberContainer}>
            <Typography variant="h2">
              {roundNumberToTwoDecimalPlaces(metrics.totalCarbonEmissions)}
            </Typography>
          </div>
        </div>
        <div className={classes.metricValueContainer}>
          <CarbonButton
            displayValue="Average Carbon Intensity"
            keyValue="averageCarbonIntensity"
            onClick={setCurrentlySelected}
            currentlySelectedKey={currentlySelected}
          />
          <div className={classes.numberContainer}>
            <Typography variant="h2">
              {roundNumberToTwoDecimalPlaces(metrics.averageCarbonIntensity)}
            </Typography>
          </div>
        </div>
      </GridItem>
      <GridItem
        card
        xs={12}
        md={7}
        lg={9}
        cardStyle={{ padding: 8, height: '100%' }}
      >
        <GeneralComponentErrorShield
          dataObjects={[timeSeriesData]}
          setLoadingHeight={'45rem'}
          customLoadingMessages={['Loading Historical Data...']}
          customErrorMessage={'No Historical Data Found'}
        >
          <CarbonFootprintTimeseriesPanel
            timeseriesData={timeSeriesData}
            timeseriesKey={currentlySelected}
          />
        </GeneralComponentErrorShield>
      </GridItem>
    </>
  );
};

interface CarbonFootprintTimeseriesProps {
  timeseriesData: DataObject;
  timeseriesKey: string;
}

function getChartTitle(key: string) {
  switch (key) {
    case 'carbonFootprint':
      return 'Carbon Footprint';
    case 'carbonIntensity':
      return 'Carbon Intensity';
    case 'totalCarbonEmissions':
      return 'Total Carbon Emissions';
    case 'averageCarbonIntensity':
      return 'Average Carbon Intensity';
    default:
      return '';
  }
}

function buildOverviewTimeseriesData(data: any, key: string): any[] {
  if (!data.data.length) return [];
  switch (key) {
    case 'carbonFootprint':
      return data.data[0].carbon_footprint.slice().reverse();
    case 'carbonIntensity':
      return data.data[0].carbon_intensity.slice().reverse();
    case 'totalCarbonEmissions':
      return data.data[0].total_carbon_emissions.slice().reverse();
    case 'averageCarbonIntensity':
      return data.data[0].weighted_average_carbon_intensity.slice().reverse();
    default:
      return [];
  }
}

const CarbonFootprintTimeseriesPanel: FC<CarbonFootprintTimeseriesProps> = ({
  timeseriesData,
  timeseriesKey,
}) => {
  const timeseries = buildOverviewTimeseriesData(timeseriesData, timeseriesKey);

  return (
    <GenericLineChart
      title={`${getChartTitle(timeseriesKey)} - Historical Time Series`}
      data={timeseries}
      showTitle
      export={false}
      id={'esg_historical_chart'}
      xAxisDataKey="date"
      yAxisTickFormatter={(value: number) =>
        roundNumberToTwoDecimalPlaces(value)
      }
      tooltipFormatter={(value: number) => roundNumberToTwoDecimalPlaces(value)}
      lines={[
        {
          dataKey: 'value',
          color: mainColors.mainBlue_lighter,
        },
      ]}
      yAxisDomain={[
        (dataMin: number) => {
          return dataMin - Math.abs(dataMin * 0.1);
        },
        (dataMax: number) => {
          return dataMax + Math.abs(dataMax * 0.1);
        },
      ]}
      width={'100%'}
      height={400}
      showDot={false}
      margin={{
        top: 10,
        bottom: 10,
        left: 10,
        right: 40,
      }}
      error={'No Historical ESG Data available'}
    />
  );
};

export default CarbonFootprintOverviewPanel;
