import EsgGeographicalExposure from './esgExposureComponents/EsgGeographicalExposureChart.component';
import EsgSectorExposure from './esgExposureComponents/EsgSectorExposureChart.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import EsgPortfolioScoresTable from './esgExposureComponents/EsgExposureScoresTable.component';
import EsgBusinessInvolvementPanel from './EsgBusinessInvolvement.component';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { Grid, Typography } from '@mui/material';

const EsgComplianceGrid = ({ esgData }: any) => {
  return esgData.isFetching ? (
    <></>
  ) : (
    <Grid container spacing={3} style={{ margin: 0 }}>
      <GridItem
        card
        lg={12}
        // height="100%"
        cardStyle={{
          padding: '1rem',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
        }}
      >
        <EsgPortfolioScoresTable
          data={esgData.data[0]}
          title="ESG Portfolio Scores"
        />
      </GridItem>
      <GridItem
        card
        lg={12}
        cardStyle={{
          padding: '1rem',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
        }}
      >
        <EsgBusinessInvolvementPanel data={esgData.data[0]} />
      </GridItem>
      <GridItem
        card
        lg={12}
        cardStyle={{
          padding: '1rem',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
        }}
      >
        <EsgSectorExposure esgData={esgData.data[0].sector_scores} />
      </GridItem>
      <GridItem
        card
        lg={12}
        cardStyle={{
          padding: '1rem',
          height: '60%',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          minHeight: '30rem',
        }}
      >
        <EsgGeographicalExposure
          esgData={esgData.data[0].geographical_scores}
        />
      </GridItem>
    </Grid>
  );
};

const ESGCompliance = ({ fundId, positionDate }: FundInfoComponentProps) => {
  const esgData = useFetchData({
    keyName: `${fundId}_esg_fund_data_${positionDate}`,
    url: `get_fund_esg_data/${fundId}/${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const noDataAvailable = esgData
    ? esgData.data.length > 0
      ? esgData.data[0].error || 'no data' in esgData.data[0] || false
      : true
    : false;

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgData]}
      customLoadingMessages={['Loading ESG Data...']}
      customErrorMessage={'No ESG Data Found'}
    >
      {noDataAvailable ? (
        <DisplayAreaCenteredWrapper noMinHeight>
          <Typography variant="h1">{'No Data Available'}</Typography>{' '}
        </DisplayAreaCenteredWrapper>
      ) : (
        <EsgComplianceGrid esgData={esgData} />
      )}
    </GeneralComponentErrorShield>
  );
};

export default ESGCompliance;
