import React, { FC } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import CustomTable from '../../../../../tables/CustomTable';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../../utilities/numberFormatters';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../../../styling/theme';

export interface SecondLevelTableData {
  name: string;
  asset_class: string;
  asset_type: string;
  base_currency: string;
  currency_pl: number;
  currency_pl_pct: number;
}

export interface SecondLevelTableProps {
  title: string;
  data: any;
}

const secondLevelColumns: CustomColumn<SecondLevelTableData>[] = [
  {
    title: 'Asset Name',
    field: 'name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Class',
    field: 'asset_class',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Type',
    field: 'asset_type',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency',
    field: 'base_currency',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency PL',
    field: 'currency_pl',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      addCommasToNumbersAndRound(rowData.currency_pl),
  },
  {
    title: 'Currency PL %',
    field: 'currency_pl_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      percentageToNdecialPlaces(rowData.currency_pl_pct / 100, 2),
  },
];

const useStyles = makeStyles(() => ({
  secondLevelContainer: {
    margin: '1.5rem',
    border: `1px solid ${mainColors.lightGrey}`,
    borderRadius: '0.5rem',
  },
}));

const SecondLevelTable: FC<SecondLevelTableProps> = (props) => {
  const { title, data } = props;

  const classes = useStyles();

  return (
    <div className={classes.secondLevelContainer}>
      <CustomTable<SecondLevelTableData>
        columns={secondLevelColumns}
        showToolbar
        data={data}
        title={title}
        options={{
          paging: false,
          showTitle: false,
          exportButton: true,
          search: false,
          toolbar: true,
        }}
      />
    </div>
  );
};

export default SecondLevelTable;
