import React from 'react';
import {
  IFixedIncomePositionData,
  generateAggregateData,
  generatePositionTableData,
  positionColumns,
} from './table.data';
import { FixedIncomeComponent } from '../FixedIncome';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import GridItem from '../../../../layout/GridComponents/GridItem';
import SlimTable from '../../../../tables/SlimTable';

type PositionTableProps = FixedIncomeComponent;

const PositionTable = (props: PositionTableProps) => {
  const { width } = useWindowSize();
  const { data } = props.inputData;
  const { height } = useWindowSize();
  const positionData = generatePositionTableData(data);
  const aggData = generateAggregateData(data, width);
  const csvFields = [
    'number',
    'name',
    'isin',
    'coupon',
    'maturity',
    'currency',
    'countryCode',
    'yearsToMaturity',
    'yield',
    'price',
    'position',
    'outstandingSecurities',
    'exposure',
    'duration',
    'dv',
    'positionDv',
    'creditRating',
    'convertible',
  ];
  return (
    <GridItem card xs={12} xl={10}>
      <SlimTable<IFixedIncomePositionData>
        data={positionData}
        title="Position Data"
        loading={false}
        search
        columns={positionColumns}
        csvFields={csvFields}
        id="fixed_income_position_table"
      />
    </GridItem>
  );
};

export default PositionTable;
