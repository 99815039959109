import React from 'react';
import TopBar from '../topBar/TopBar';
import RaptorSnackbar from '../../feedback/raptorSnackbar/Snackbar.component';
import { mainColors } from '../../../styling/theme';
import SettingsMenu from '../settingsMenu/SettingsMenu.component';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  body: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainColors.background,
  },
  main: {
    height: 'calc(100% - 6rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: '1rem',
  },
});

type DocumentationLayoutProps = {
  children?: React.ReactNode;
};

const SettingsLayout = ({ children }: DocumentationLayoutProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <body className={classes.body}>
        <TopBar />
        <main className={classes.main}>
          <SettingsMenu />
          <div className={classes.content}>{children}</div>
        </main>
        <RaptorSnackbar />
      </body>
    </React.Fragment>
  );
};
export default SettingsLayout;
