import {
  FixedIncomeExposureOptionType,
  IGeneralExposureData,
} from '../../exposureGraphics.data';
import { useWindowSize } from '../../../../../../../../hooks/useWindowSize';
import GenericBarChart from '../../../../../../../charts/GenericBarChart';
import { createAngledTick } from '../../../../equity/components/barChart/barChart.component';
import { toTwoDecimalPlaces } from '../../../../../../../../utilities/numberFormatters';
import { colorsArray } from '../../../../../../../../styling/theme';
import { useTheme } from '@mui/material';

export interface IExposureGraphicsView {
  data: IGeneralExposureData[];
  loading: boolean;
  exposureType: FixedIncomeExposureOptionType;
}

export type ExposureOptionKey = {
  [key in FixedIncomeExposureOptionType]: string;
};
const EXPOSURE_TITLE_MAP: ExposureOptionKey = {
  [FixedIncomeExposureOptionType.CREDIT]: 'Exposure by Credit Rating',
  [FixedIncomeExposureOptionType.SECTOR]: 'Exposure by Sector',
  [FixedIncomeExposureOptionType.MATURITY]: 'Exposure by Maturity',
  [FixedIncomeExposureOptionType.GROSS_COUNTRY_EXPOSURE]:
    'Gross Country Exposure',
  [FixedIncomeExposureOptionType.NET_COUNTRY_EXPOSURE]: 'Net Country Exposure',
};
const ExposureGraphicsBarChart = (props: IExposureGraphicsView) => {
  const theme = useTheme();
  const { height, width } = useWindowSize();

  return (
    <GenericBarChart
      margin={{
        top: 0,
        bottom:
          props.exposureType === FixedIncomeExposureOptionType.SECTOR
            ? 128
            : 40,
        left: 40,
        right: 40,
      }}
      title={EXPOSURE_TITLE_MAP[props.exposureType]}
      loading={props.loading}
      height={height - 300}
      dontVaryBarColor
      width={'100%'}
      data={props.data}
      xAxes={[
        {
          interval: 0,
          dataKey: 'name',
          label: {
            value: EXPOSURE_TITLE_MAP[props.exposureType],
            position: 'bottom',
            offset:
              props.exposureType === FixedIncomeExposureOptionType.SECTOR
                ? 80
                : 0,
            style: {
              fill: theme.palette.grey[700],
            },
          },
          tick:
            props.exposureType === FixedIncomeExposureOptionType.SECTOR
              ? createAngledTick({})
              : undefined,
        },
      ]}
      yAxes={[
        {
          yAxisId: 'left',
          label: {
            value: 'Exposure',
            angle: -90,
            style: { textAnchor: 'middle', fill: theme.palette.grey[700] },
            position: 'insideLeft',
          },
          dataKey: 'exposure',
          unit: '%',
          type: 'number',
          scale: 'linear',
          domain: [0, (dataMax: number) => dataMax * 1.1],
        },
        {
          yAxisId: 'right',
          dataKey: 'dv01',
          label: {
            value: 'DV01',
            angle: -90,
            offset: -10,
            style: { textAnchor: 'middle', fill: theme.palette.grey[700] },
            position: 'right',
          },
          type: 'number',
          scale: 'linear',
          orientation: 'right',
          domain: ['dataMin', (dataMax: any) => dataMax * 1.1],
        },
      ]}
      tooltipFormatter={(value: any, name: any, props: any) => {
        const nameMap: { [key: string]: string } = {
          exposure: 'Exposure',
          dv01: 'DV01',
        };
        return [toTwoDecimalPlaces(value as string | number), nameMap[name]];
      }}
      bars={[
        {
          dataKey: 'exposure',
          fill: theme.palette.primary.main,
          key: 'exposureKey',
          yAxisId: 'left',
        },
        {
          dataKey: 'dv01',
          fill: colorsArray[1],
          key: 'exposureKeyRight',
          yAxisId: 'right',
        },
      ]}
    />
  );
};

export default ExposureGraphicsBarChart;
