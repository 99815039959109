import React, { ReactElement } from 'react';
import withChartWrapper from '../withChartWrapper';
import { buildEvolutionChart } from '../portfolioLiquidation/portfolioLiquidation.data';
import { toTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';
import { mainColors } from '../../../../../../styling/theme';
import { Dimensions } from '../portfolioLiquidation/portfolioLiquidation.component';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GenericLineChart from '../../../../../charts/GenericLineChart';

interface Props extends Dimensions {
  inputData: DataObject;
  height: number;
  width: number;
}

function HistoricalNav(props: Props): ReactElement {
  const { data, isFetching, error } = props.inputData;
  // width was experimental
  const { height, width } = props;
  const chartData = buildEvolutionChart(data, 'q4');
  const formatter = (value: number, name: string) => [
    name === 'nav'
      ? `${toTwoDecimalPlaces(value)}mm`
      : `${toTwoDecimalPlaces(value)}`,
    name === 'nav' ? 'NAV' : 'Unit Px',
  ];
  // const axisFormatter = (value: number) => Math.round(value);
  const axisFormatter = (value: number) => toTwoDecimalPlaces(value);

  return (
    <>
      <GenericLineChart
        error={error}
        export
        download
        loading={isFetching}
        width="90%"
        margin={{ top: 10, left: 0, right: 10, bottom: 10 }}
        title="Historical NAV and Unit Price"
        showTitle
        yAxisTickFormatter={axisFormatter}
        yAxisLabel={{
          value: 'NAV (mm)',
          angle: -90,
          position: 'insideLeft',
          style: { textAnchor: 'middle' },
        }}
        height={height}
        showLegend
        legendFormatter={(value: any, entry: any) => {
          const { color } = entry;
          let name;
          if (value === 'nav') {
            name = 'NAV';
          } else {
            name = 'Unit Px';
          }

          return <span style={{ color }}>{name}</span>;
        }}
        additionalYAxis={[
          {
            dataKey: 'unitPx',
            leftDataKey: 'nav',
            type: 'number',
            domain: ['dataMin', 'dataMax'],
            yAxisId: 'right',
            orientation: 'right',
            tickFormatter: (value: number) => toTwoDecimalPlaces(value),
          },
        ]}
        data={chartData}
        tooltipFormatter={formatter}
        lines={[
          {
            dataKey: 'nav',
            color: mainColors.mainBlue,
          },
          {
            dataKey: 'unitPx',
            color: mainColors.Alert,
            yAxisId: 'right',
          },
        ]}
      />
    </>
  );
}

export default withChartWrapper(HistoricalNav);
