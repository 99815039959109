import React from 'react';

const RaptorLoadingIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="hide-bottom">
          <polygon points="134.1,135.4 112,135.4 64.8,91.2 87.1,91.2 102.1,105.3 102,105.3" />
        </clipPath>
        <clipPath id="hide-top">
          <path d="M143,83.7c0,2.6-0.5,5.2-1.4,7.5c-3.2,8.2-11.7,14.1-21.6,14.1h-9.1l-15-14.1h24.3c4.3,0,7.8-3.3,7.8-7.3 c0-4.1-3.5-7.3-7.8-7.3H80L65,62.4v-0.3h55c10,0,18.6,6,21.7,14.4C142.5,78.7,143,81.2,143,83.7z" />
        </clipPath>
        <linearGradient id="grad">
          <stop offset="0%" stopColor="white" />
          <stop offset="10%" stopColor="white" />
          <stop offset="100%" stopColor="#396fad" />
        </linearGradient>
      </defs>

      <path
        className="topLine"
        clipPath="url(#hide-top)"
        d="M63.8,69.7h56.9c0,0,13.6-0.6,13.6,14.2c0,13.6-14.9,13.6-14.9,13.6H95.1"
      />
      <line
        className="bottomLine"
        clipPath="url(#hide-bottom)"
        x1="67.7"
        y1="83.8"
        x2="130.7"
        y2="144.3"
      />
      <circle cx="100" cy="100" r="80.5" />
      <path
        className="circle-segment"
        d="M134.1,27.3c20.6,8.9,36.5,26.6,42.9,48.5"
      />
    </svg>
  );
};

export default React.memo(RaptorLoadingIcon);
