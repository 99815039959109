import { useDispatch } from 'react-redux';
import {
  toggleSnackbarLoading,
  updateSnackbarMessage,
} from '../redux/ui/snackbar/actions';

function useSnackbar() {
  const dispatch = useDispatch();
  const showMessage = (message: string | null) => {
    dispatch(updateSnackbarMessage(message));
  };
  const isLoading = (loading: boolean) => {
    dispatch(toggleSnackbarLoading(loading));
  };

  /**
   *
   * Add a new function called updateSnackbarLoading,
   * this should take 1 parameter, a boolean, saying whether the snackbar is loading or not
   * If it is, a loading spinner should display inside the snackbar component, and the
   * auto close should be disabled
   *
   */

  async function showAsyncSnackbar<T>({
    startMessage,
    successMessage,
    failureMessage,
    promiseToResolve,
  }: {
    startMessage: string;
    successMessage: string;
    failureMessage: string;
    promiseToResolve: Promise<T>;
  }) {
    showMessage(startMessage);
    isLoading(true);
    try {
      await promiseToResolve;
      isLoading(false);
      showMessage(successMessage);
      setTimeout(() => {
        showMessage(null);
      }, 1000);
    } catch {
      isLoading(false);
      showMessage(failureMessage);
      setTimeout(() => {
        showMessage(null);
      }, 4000);
    }
  }

  return {
    showSnackbarMessage: showMessage,
    updateSnackbarLoading: isLoading,
    showAsyncSnackbar,
  };
}

export default useSnackbar;
