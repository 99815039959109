import React, { ReactElement } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import OverallStatusPage from '../../sharedComponents/overallStatusPage/component';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';

const ValueAtRiskOverview: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props): ReactElement | null => {
  return (
    <OverallStatusPage
      additionalValues={[
        {
          keyName: 'var_value',
          keyTitle: 'VAR Value',
          transformerFunc: (val: number) => percentageToTwoDecimalPlaces(val),
        },
      ]}
      overviewDataPageKey="Value_at_Risk"
      positionDate={props.positionDate}
    />
  );
};

export default ValueAtRiskOverview;
