import React from 'react';
import {
  IFixedIncomeAggregateDataMaximised,
  IFixedIncomeAggregateDataMinimised,
  generateAggregateColumns,
  generateAggregateData,
} from '../table/table.data';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import SlimTable from '../../../../tables/SlimTable';
import { FixedIncomePages } from '../FixedIncome';

interface AggregateTable {
  inputData: DataObject;
  currentPage: FixedIncomePages;
}
const AggregateFixedIncomeTable = (props: AggregateTable) => {
  const { width } = useWindowSize();

  const { data } = props.inputData;
  const widthForUse = props.currentPage === 'exposureGraphics' ? 1919 : width;
  const aggData = generateAggregateData(data, widthForUse);
  const aggregateColumns = generateAggregateColumns(widthForUse);

  const csvFields =
    widthForUse <= 1919
      ? ['nav', 'exposure', 'exposurePc', 'dv01', 'wam', 'wac', 'duration']
      : ['parameter', 'value'];

  return (
    <GridItem
      card
      xs={12}
      xl={props.currentPage === 'exposureGraphics' ? 12 : 2}
      style={{
        display: 'flex',
        alignSelf: 'stretch',
      }}
    >
      <SlimTable<
        IFixedIncomeAggregateDataMinimised | IFixedIncomeAggregateDataMaximised
      >
        data={aggData}
        loading={false}
        columns={aggregateColumns}
        csvFields={csvFields}
        title="Aggregate Data"
        export={true}
      />
    </GridItem>
  );
};

export default AggregateFixedIncomeTable;
