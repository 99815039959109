import React from 'react';
import { useSelector } from 'react-redux';
import { activeSectionSelector } from '../../../../../redux/pages/selectors';
import { greys } from '../../../../../styling/theme';
import CustomTable from '../../../../tables/CustomTable';
import {
  MoabData,
  buildMoabData,
  generateMoabColumns,
} from './sharedTable.data';

interface SharedComponentProps {
  title: string;
  data: MoabData[];
  fundId: string;
  positionDate: string;
  identifier: string;
}

const MOAB_CSV_FIELDS = ['asset', 'exposure'];
const MoabTableComponent = (props: SharedComponentProps) => {
  const columns = generateMoabColumns();
  const activeSection = useSelector(activeSectionSelector);
  const tableData = buildMoabData(props.data);
  return (
    <CustomTable<MoabData>
      columns={columns}
      data={tableData}
      showToolbar={true}
      toolbarComponents={{
        toolbarTitle: props.title,
        titleStyle: {
          fontSize: '1.6rem',
          fontWeight: 400,
          color: greys.grey800,
        },
      }}
      noteExport={{
        fundId: props.fundId,
        subject: `${activeSection}_moab`,
        topic: props.identifier,
        positionDate: props.positionDate,
        shouldCall: false,
      }}
      options={{
        paging: false,
        maxBodyHeight: 'calc(100vh - 25.5rem)',
        exportButton: true,
      }}
      csvFields={MOAB_CSV_FIELDS}
    />
  );
};

export default MoabTableComponent;
