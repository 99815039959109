import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { HitsData } from './HitsTable.component';
import SecondLevelTable from './SecondLevelTable.component';

const SecondLevelTableWrapper = () => [
  (rowData: HitsData) => {
    const icon = () =>
      rowData.sources.length ? <KeyboardArrowRight /> : <></>;
    const render = () => <SecondLevelTable data={rowData.sources} />;
    return {
      disabled: !rowData.sources.length,
      icon,
      openIcon: KeyboardArrowDown,
      render,
    };
  },
];

export default SecondLevelTableWrapper;
