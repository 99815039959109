import React, { ReactElement } from 'react';
import { LineDescriptor } from '../../types/components/charts/lineCharts';
import GenericLineChart from './GenericLineChart';

interface Props {
  data: any;
  lines: LineDescriptor[];
  yAxisDomain?: any;
  formatter?: any;
  yAxisTickFormatter?: any;
  title: string;
  export?: boolean;
  error: number | string | null;
  id: string;
}

function InTableTimeSeries({
  error,
  data,
  lines,
  title,
  ...props
}: Props): ReactElement {
  return (
    <GenericLineChart
      error={error}
      width={'100%'}
      height={120}
      margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
      strokeWidth={1.5}
      data={data}
      id={props.id}
      title={title}
      lines={lines}
      showDot={false}
      xAxisDataKey="date"
      minTickGap={50}
      smallTitle
      export={props.export}
      download
      xInterval="preserveStart"
      yAxisTickFormatter={props.yAxisTickFormatter}
      tooltipFormatter={props.formatter || null}
      yAxisDomain={
        props.yAxisDomain || [
          (dataMin: number) => {
            return dataMin - Math.abs(dataMin * 0.5);
          },
          (dataMax: number) => dataMax + dataMax * 0.1,
        ]
      }
    />
  );
}

export default React.memo(InTableTimeSeries);
