import { UnfoldLess } from '@mui/icons-material';
import SecondLevelTable from './SecondLevelTable.component';
import { StockSelectionPerformanceTableData } from './StockSelectionPerformanceTable.component';

const SecondLevelTableWrapper = () => [
  (rowData: StockSelectionPerformanceTableData) => {
    const render = () => (
      <SecondLevelTable
        title={'Underling Assets'}
        data={rowData.underlying_assets}
      />
    );
    return {
      openIcon: UnfoldLess,
      render,
      disabled: rowData.underlying_assets.length ? false : true,
    };
  },
];

export default SecondLevelTableWrapper;
