import React, { FC } from 'react';
import CustomTable from '../../../../tables/CustomTable';
import {
  AifmdOverviewTableData,
  generateAifmdOverviewColumns,
  generateAifmdOverviewData,
} from './table.data';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';

export interface AifmdOverviewTableProps {
  reports: DataObject;
  inputData: DataObject;
}

const AifmdOverviewTable: FC<AifmdOverviewTableProps> = (props) => {
  const tableData =
    props.inputData.data[0].length && props.reports.data.length
      ? generateAifmdOverviewData(props.inputData.data[0], props.reports.data)
      : [];
  const tableColumns = generateAifmdOverviewColumns();

  return (
    <GridItem card xs={12}>
      <CustomTable<AifmdOverviewTableData>
        options={{
          exportButton: true,
          paging: false,
        }}
        loading={
          props.reports && props.inputData
            ? props.reports.isFetching || props.inputData.isFetching
            : true
        }
        data={tableData}
        columns={tableColumns}
        showToolbar
        id="aifmd_overview_table"
        title="AIFMD Overview"
        toolbarComponents={{
          toolbarTitle: 'AIFMD Overview',
        }}
      />
    </GridItem>
  );
};

export default AifmdOverviewTable;
