import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';

type Scenario = 'Base Currency Cash Assets' | 'All Currency Cash Assets';
export interface IMaximumOneDayTableData {
  statistic: string;
  baseCurrency: number;
  allCurrency: number;
}

export const maximumOneDayColumns: CustomColumn<IMaximumOneDayTableData>[] = [
  {
    title: 'Statistic',
    field: 'statistic',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    pdfWidth: 33,
  },
  {
    title: 'Base Currency',
    field: 'baseCurrency',
    pdfWidth: 33,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.baseCurrency),
  },
  {
    title: 'All Currency',
    field: 'allCurrency',
    pdfWidth: 33,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.allCurrency),
  },
];

export interface IMaxmimumOneDayServerData {
  liquidation_risk_report: any;
  moab_data: any;
  redemption_liquidity_stress_test_3: any;
  redemption_liquidity_stress_test_10: any;
  redemption_liquidity_stress_test_3_ss: any;
  redemption_liquidity_stress_test_10_ss: any;
}

function formatStatisticName(name: string): string {
  switch (name) {
    case 'M0':
      return 'M0 (Cash)';
    case 'M1':
      return 'M1 (Cash, Govt T-Bills)';
    case 'M2':
      return 'M2 (Cash, All Govt Bonds)';
    case 'M3':
      return 'M3 (All 1-Day Liquidation Assets)';
    default:
      return name;
  }
}

export function buildMaximumOneDayData(inputData: IMaxmimumOneDayServerData[]) {
  if (!inputData || !inputData.length) return [];
  try {
    const data = inputData[0].moab_data;
    const dataForUse = [...data];
    //remove header
    dataForUse.shift();

    return dataForUse.map((moab, index) => {
      return {
        statistic: formatStatisticName(moab[0]),
        baseCurrency: moab[1],
        allCurrency: moab[2],
      };
    });
  } catch (err) {
    console.error('Cant do moab: ', err);

    return [];
  }
}
