import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';

interface IDiscountRateData {
  scenario: string;
  value: number;
}

interface Props {
  data: any;
  height: number | string;
}

export const DiscountRateColumns: CustomColumn<IDiscountRateData>[] = [
  {
    title: 'Scenario',
    field: 'scenario',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Liquidity Discount',
    field: 'value',
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.value),
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

export function buildDiscountRateTableData(inputData: any) {
  if (!inputData.data.length) return [];
  const DiscountRateData = inputData.data[0];
  // const data = inputData[0];
  // scenario: string;
  // status: string;
  // value: number;
  // tooltip: string;

  return [
    {
      scenario: 'Current Discount Rate',
      value: DiscountRateData.current_Discount,
    },
    {
      scenario: 'Three Month Average Discount Rate',
      value: DiscountRateData.threeMonthAverageDiscount,
    },
    {
      scenario: 'Historical Average Discount Rate',
      value: DiscountRateData.historicalAverageDiscountRate,
    },
    {
      scenario: 'Historical 99 Percentile Discount Rate',
      value: DiscountRateData.historical99percentileDiscountRate,
    },
    {
      scenario: 'Historical Worst Discount Rate',
      value: DiscountRateData.historicalWorstDiscountRate,
    },
  ];
}

function DiscountRateTable({ data, height }: Props): ReactElement | null {
  const tableData = buildDiscountRateTableData(data);
  // const columns = createFundAssetColumns(stressLevel as StressLevel);
  // const fundsSelector = createSectionFundsSelector('liquidity');
  // const funds = useSelector(fundsSelector);
  // const fundTitle =
  //     funds && funds.length
  //         ? funds.find((fund: any) => fund.id === activeFund)?.name + ' - Breakdown'
  //         : '';
  return (
    <GridItem xs={5} card cardStyle={{ minHeight: height }}>
      <CustomTable<IDiscountRateData>
        showToolbar={false}
        // loading={fundAssetData.isFetching}
        // csvFields={csvFundAssetLiquidationTime}
        options={{
          paging: false,
          sorting: false,
        }}
        data={tableData}
        columns={DiscountRateColumns}
        // toolbarComponents={{
        //     toolbarTitle: fundTitle,
        // }}
      />
    </GridItem>
  );
}

export default DiscountRateTable;
