import React from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import EsAlignmentTable from './subComponents/EsAlignmentTable.component';

export interface EsAlignmentUnderlyingTableData {
  exposure_percentage: number;
  gmid: number;
  gross_exposure_percentage: number;
  isin: string;
  position_id: string;
  position_name: string;
}

export interface EsAlignmentTableData {
  description: string;
  indicator: string;
  indicator_name: string;
  limit: number;
  limit_type: string;
  pass_fail: string;
  value: number;
  unit: string;
  underlying: EsAlignmentUnderlyingTableData[];
}

const EsAlignmentMonitor: React.FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const esAlignmentData = useFetchData({
    url: `butler_es_alignment/${fundId}/${positionDate}`,
    keyName: `butler_es_alignment_${fundId}_${positionDate}`,
    makeFetch: fundId && positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[esAlignmentData]}
      customLoadingMessages={['Loading E/S Alignment Data']}
      customErrorMessage="No Data Available."
    >
      <EsAlignmentTable data={esAlignmentData} />
    </GeneralComponentErrorShield>
  );
};

export default EsAlignmentMonitor;
