import { StressTestsData } from './StressTestsTable';
import PositionsTable from './PositionsTable.component';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

const SecondLevelTableWrapper = () => [
  (rowData: StressTestsData) => {
    const icon = () =>
      rowData.positions && rowData.positions.length ? (
        <KeyboardArrowRight />
      ) : (
        <></>
      );
    const render = () => <PositionsTable positions={rowData.positions || []} />;
    return {
      disabled: rowData.positions && rowData.positions.length ? false : true,
      icon,
      openIcon: KeyboardArrowDown,
      render,
    };
  },
];

export default SecondLevelTableWrapper;
