import React, { FC } from 'react';

import {
  activeDateSelector,
  availableDatesSelector,
  createFundNameFromFundIdSelector,
  createSectionFundIdsSelector,
} from '../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import UcitsLaw from '../pages/ucits/law/UcitsLaw';
import ProspectusRestrictions from '../pages/risk/prospectusRestrictions/ProspectusRestrictions.component';
import Counterparty from '../pages/ucits/counterparty/Counterparty';
import StressScenario from '../pages/ucits/stressTests/StressTestsTable';
import HistoricalData from '../pages/ucits/historical/component';
import RiskComparison from '../pages/ucits/riskComparison/component';
import VarBacktest from '../pages/ucits/varBacktest/component';
import AifmdReports from '../pages/aifmd/reports/component';
import Equity from '../pages/asset_class/equity/Equity';
import Currency from '../pages/asset_class/currency/Currency';
import FixedIncome from '../pages/asset_class/fixedIncome/FixedIncome';
import Cis from '../pages/asset_class/cis/Cis';
import FundAssetLiquidationTime from '../pages/liquidity/fundAssetLiquidationTime/component';
import Moab from '../pages/liquidity/moab/component';
import RstFixed from '../pages/liquidity/rstFixed/component';
import RstVariable from '../pages/liquidity/rstVariable/component';
import LiquidationRiskOverview from '../pages/liquidity/liquidationRiskOverview/component';
import CounterpartyMarketLiquidityRisk from '../pages/liquidity/counterparty/component';
import LiquidityRiskEvolution from '../pages/liquidity/riskEvolution/component';
import RstEvolution from '../pages/liquidity/RstEvolution/component';
import ExposureFund from '../pages/sharedComponents/ExposureFund';
import GeneralComponentErrorShield from './GeneralComponentErrorShield';
import DisplayAreaCenteredWrapper from '../layout/utilities/displayAreaWrapper';
import AifRstFixed from '../pages/aif_liquidity/rst-fixed/rst-fixed.component';
import SyntheticRisk from '../pages/ucits/synthetic-risk/SyntheticRisk';
import CssfRiskSpreading from '../pages/sif-raif/cssfRiskSpreading/CssfRiskSpreading.component';
import ValueAtRiskNew from '../pages/sharedComponents/valueAtRiskNew/ValueAtRiskNew.component';
import NotesPage from '../pages/utilities/NotesPage/NotesPage.component';
import SubFundSummary from '../pages/utilities/SubFundSummary/SubFundSummary.component';
import FailsPage from '../pages/utilities/Fails/FailsPage.component';
import ShareClassHedgeData from '../pages/reportsPages/shareClassHedge/ShareClassHedgeData.component';
import SyntheticRiskShareClass from '../pages/ucits/syntheticRiskShareClass/SyntheticRiskShareClass';
import LiquidityAdjustedSettlementDateAchievableBalance from '../pages/liquidity/lasdab/component';
import SubscriptionsRedemptions from '../pages/ucits/subscriptionsRedemptions/component';
import ESGCompliance from '../pages/esg/esgExposure/Esg.component';
import DiscountCashflow from '../pages/privateEquity/discountCashFlow/DiscountCashflow.component';
import PeStressScenario from '../pages/privateEquity/peStressScenarioTests/PeStressScenarioTests.component.tsx';
import PeValueAtRisk from '../pages/privateEquity/peValueAtRisk/PeValueAtRisk.component';
import PeHeatMap from '../pages/privateEquity/peHeatMap/PeHeatMap.component';
import PeExposure from '../pages/privateEquity/peExposure/PeExposure.component';
import MarketLiquidityRisk from '../pages/liquidity/marketLiquidityRisk/MarketLiquidityRisk.component';
import VariableMarketVolumeLiquidationCosts from '../pages/liquidity/variableMarketVolumeLiquidationCosts/VariableMarketVolumeLiquidationCosts.component';
import IndividualESGPage from '../pages/esg/individualESGPage/individualESGPage.component';
import PeLiquiidtyValueAtRisk from '../pages/privateEquity/peLiquidityValueAtRisk/PeLiquidityValueAtRisk.component';
import PeMarketRiskOverview from '../pages/privateEquity/marketRiskOverview/PeMarketRiskOverview.component';
import MonteCarloVar from '../pages/privateEquity/monteCarloVar/MonteCarloVar.componet';
import ESGPortfolioSummary from '../pages/esg/portfolioSummary/PortfolioSummary.component';
import ArticleScanner from '../pages/esg/articleScanner/ArticleScanner.component';
import PrincipleAdverseImpact from '../pages/esg/principleAdverseImpact/PrincipleAdverseImpact.component';
import PortfolioCarbonEmissions from '../pages/esg/portfolioCarbonEmissions/PortfolioCarbonEmissions.component';
import ESGExposure from '../pages/esg/exposure/ESGExposure.component';
import LiquidityDiscountAnalysis from '../pages/privateEquity/liquidityDiscountAnalysis/LiquidityDiscountAnalysis.component';
import DiscountCashflowConfiguration from '../pages/privateEquity/discountCashFlowConfiguration/DiscountCashFlowConfiguration.component';
import PerformanceReport from '../pages/sharedComponents/performanceReport/PerformanceReport.component';
import CollateralMonitor from '../pages/ucits/collateralMonitor/CollateralMonitor.component';
import ESGStressTests from '../pages/esg/esgStressTests/ESGStressTests.component';
import NursLaw from '../pages/nurs/law/NursLaw';
import CounterpartyStressTests from '../pages/sharedComponents/counterpartyStressTests/CounterpartyStressTests.component';
import RiaifLaw from '../pages/riaif/law/RiaifLaw';
import SubFundPerformance from '../pages/performanceAttribution/subFundPerformance/SubFundPerformance.component.';
import AssetAllocationPerformance from '../pages/performanceAttribution/assetAllocationPerformance/AssetAllocationPerformance.component';
import AssetAndCurrency from '../pages/performanceAttribution/assetAndCurrency/AssetAndCurrency.component';
import SubFundCumulativeBreakdown from '../pages/performanceAttribution/subFundCumulativeBreakdown/SubFundCumulativeBreakdown.component';
import EsgVAR from '../pages/esg/esgVar/EsgVAR.component';
import AlternativeNonCis from '../pages/asset_class/alternativeNonCis/AlternativeNonCis.component';
import PeProxyModel from '../pages/privateEquity/peProxyModel/PeProxyModel.component';
import RelativeRisk from '../pages/ucits/relativeRisk/RelativeRisk.component';
import ExposureVsCumulativeReturns from '../pages/performanceAttribution/exposureVsCumulativeReturns/ExposureVsCumulativeReturns.component';
import Act40Law from '../pages/act40/law/Act40Law';
import SubFundHistorical from '../pages/performanceAttribution/subFundHistorical/SubFundHistorical.component';
import DiscountCashflowOverview from '../pages/privateEquity/discountCashflowOverview/DiscountCashflowOverview.component';
import CashflowStressTests from '../pages/privateEquity/cashFlowStressTests/CashFlowStressTests.component';
import SubFundVsBenchmark from '../pages/performanceAttribution/subFundVsBenchmark/SubFundVsBenchmark.component';
import PerformanceDiagnostics from '../pages/performanceAttribution/performanceDiagnostics/PerformanceDiagnostics.component';
import StockSelectionPerformance from '../pages/performanceAttribution/stockSelectionPerformance/StockSelectionPerformance.component';
import FxRates from '../pages/utilities/fxRates/FxRates.component';
import IndicatorCompliance from '../pages/esg/indicatorCompliance/IndicatorCompliance.component';
import IndicatorCoverageRatioMonitor from '../pages/esg/IndicatorCoverageRatioMonitor/IndictaorCoverageRatioMonitor.component';
import PageNotFound from '../settings/pages/PageNotFound.component';
import EsAlignmentMonitor from '../pages/esg/esAlignmentMonitor/EsAlignmentMonitor.component';
import HistoricalDataExport from '../pages/manco/historicalDataExport/HistoricalDataExport.component';
import HistoricalFailsPage from '../pages/utilities/HistoricalFails/HistoricalFailsPage.component';
import { Grid, useTheme } from '@mui/material';
import FivgLaw from '../pages/fivg/law/FivgLaw';
import PartiiLaw from '../pages/partii/law/PartiiLaw';
import PositionUpdatePortal from '../pages/manco/positionUpdatePortal/PositionUpdatePortal.page';

export interface GeneralFundInfoPageProps {
  section: string;
  pageId: string;
  fundId: string;
}

export interface FundInfoComponentProps {
  section: string;
  pageId: string;
  fundId: string;
  fundName: string;
  positionDate?: string;
  availableDates?: string[];
}

const buildFundInfoPage = (
  section: string,
  pageId: string,
): React.FC<FundInfoComponentProps> => {
  switch (pageId) {
    case 'exposure':
      return ExposureFund;
    case 'value-at-risk':
      return ValueAtRiskNew;
    case 'ucits-law':
      return UcitsLaw;
    case 'nurs-law':
      return NursLaw;
    case 'riaif-law':
      return RiaifLaw;
    case 'act40-law':
      return Act40Law;
    case 'fivg-law':
      return FivgLaw;
    case 'partii-law':
      return PartiiLaw;
    case 'notes':
      return NotesPage;
    case 'prospectus-restrictions':
      return ProspectusRestrictions;
    case 'cssf-risk-spreading':
      return CssfRiskSpreading;
    case 'counterparty':
      return Counterparty;
    case 'stress-scenario-tests':
      return StressScenario;
    case 'pe-stress-scenario-tests':
      return PeStressScenario;
    case 'pe-value-at-risk':
      return PeValueAtRisk;
    case 'historical-data':
      return HistoricalData;
    case 'risk-comparison':
      return RiskComparison;
    case 'var-backtest':
      return VarBacktest;
    case 'sub-fund-setup':
      return SubFundSummary;
    case 'fails':
      return FailsPage;
    case 'historical-fails':
      return HistoricalFailsPage;
    case 'aifmd-reports':
      return AifmdReports;
    case 'equity':
      return Equity;
    case 'srri':
      return SyntheticRisk;
    case 'srri-share-class':
      return SyntheticRiskShareClass;
    case 'share-class-hedge':
      return ShareClassHedgeData;
    case 'currency':
      return Currency;
    case 'fixed-income':
      return FixedIncome;
    case 'cis':
      return Cis;
    case 'fund-asset-liquidation-time':
      return FundAssetLiquidationTime;
    case 'lasdab':
      return LiquidityAdjustedSettlementDateAchievableBalance;
    case 'moab':
      return Moab;
    case 'rst-fixed':
      return section.includes('aifmd') ? AifRstFixed : RstFixed;
    case 'rst-variable':
      return RstVariable;
    case 'liquidation-risk-overview':
      return LiquidationRiskOverview;
    case 'counterparty-market-liquidity-risk':
      return CounterpartyMarketLiquidityRisk;
    case 'liquidity-risk-evolution':
      return LiquidityRiskEvolution;
    case 'reverse-stress-test-evolution':
      return RstEvolution;
    case 'esg':
      return ESGCompliance;
    case 'subscriptions-redemptions':
      return SubscriptionsRedemptions;
    case 'discount-cashflow':
      return DiscountCashflow;
    case 'pe-heat-map':
      return PeHeatMap;
    case 'pe-exposure':
      return PeExposure;
    case 'market-liquidity-risk':
      return MarketLiquidityRisk;
    case 'variable-market-volume-liquidation-costs':
      return VariableMarketVolumeLiquidationCosts;
    case 'liquidity-pe-value-at-risk':
      return PeLiquiidtyValueAtRisk;
    case 'pe-market-risk-overview':
      return PeMarketRiskOverview;
    case 'pe-monte-carlo-var':
      return MonteCarloVar;
    case 'pe-liquidity-discount-analysis':
      return LiquidityDiscountAnalysis;
    case 'discount-cashflow-configuration':
      return DiscountCashflowConfiguration;
    case 'discount-cashflow-overview':
      return DiscountCashflowOverview;
    case 'performance-report':
      return PerformanceReport;
    case 'collateral-monitor':
      return CollateralMonitor;

    // -----------------------
    // Performance Attribution
    case 'performance-diagnostics':
      return PerformanceDiagnostics;
    case 'sub-fund-performance':
      return SubFundPerformance;
    case 'asset-allocation-performance':
      return AssetAllocationPerformance;
    case 'asset-and-currency':
      return AssetAndCurrency;
    case 'sub-fund-cumulative-breakdown':
      return SubFundCumulativeBreakdown;
    case 'initial-exposure-vs-cumulative-returns':
      return ExposureVsCumulativeReturns;
    case 'sub-fund-historical-performance':
      return SubFundHistorical;
    case 'sub-fund-vs-benchmark':
      return SubFundVsBenchmark;
    case 'stock-selection-performance':
      return StockSelectionPerformance;
    // -----------------------

    // -----------------------
    // ESG
    case 'portfolio-esg':
      return ESGPortfolioSummary;
    case 'article-scanner':
      return ArticleScanner;
    case 'principle-adverse-impact':
      return PrincipleAdverseImpact;
    case 'carbon-emissions':
      return PortfolioCarbonEmissions;
    case 'environmental-esg':
    case 'social-esg':
    case 'corporate-governance-esg':
      return IndividualESGPage;
    case 'esg-exposure':
      return ESGExposure;
    case 'esg-stress-tests':
      return ESGStressTests;
    case 'esg-var':
      return EsgVAR;
    case 'indicator-compliance':
      return IndicatorCompliance;
    case 'indicator-coverage-ratio-monitor':
      return IndicatorCoverageRatioMonitor;
    case 'es-alignment-monitor':
      return EsAlignmentMonitor;
    case 'historical-data-export':
      return HistoricalDataExport;
    // case 'sustainability-data':
    //   return SustainabilityDataPage;
    // case 'business-involvement':
    //   return BusinessInvolvementPage; // Rebuild these pages if necessary
    // -----------------------

    case 'counterparty-stress-tests':
      return CounterpartyStressTests;
    case 'alternative-non-cis':
      return AlternativeNonCis;
    case 'pe-proxy-model':
      return PeProxyModel;
    case 'relative-risk':
      return RelativeRisk;
    case 'cashflow-stress-tests':
      return CashflowStressTests;
    case 'fx-rates':
      return FxRates;

    // -----------------------
    case 'position-update-portal':
      return PositionUpdatePortal;

    default:
      return PageNotFound;
  }
};

const GeneralFundInfoPage: FC<GeneralFundInfoPageProps> = (props) => {
  const { section, pageId, fundId } = props;

  const activeDate = useSelector(activeDateSelector);
  const availableDates = useSelector(availableDatesSelector);

  const fundIdsSelector = createSectionFundIdsSelector(section);
  const fundIds = useSelector(fundIdsSelector);
  const fundName = useSelector(createFundNameFromFundIdSelector(fundId));
  const theme = useTheme();

  const Component = buildFundInfoPage(section, pageId);

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      <GeneralComponentErrorShield dataObjects={[]}>
        {fundIds && fundIds.includes(fundId) ? (
          <Component
            {...props}
            section={section}
            pageId={pageId}
            fundId={fundId}
            fundName={fundName || 'Unknown Fund'}
            positionDate={activeDate || undefined}
            availableDates={availableDates || undefined}
          />
        ) : (
          <DisplayAreaCenteredWrapper>
            <h1 style={{ color: theme.palette.primary.main }}>
              Unknown fund selected
            </h1>
          </DisplayAreaCenteredWrapper>
        )}
      </GeneralComponentErrorShield>
    </Grid>
  );
};

export default GeneralFundInfoPage;
