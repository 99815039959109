import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import CurrencyTable from './table/currencyTable.component';
import CurrencyChart from './chart/chart.component';

const Currency: FC<FundInfoComponentProps> = (props) => {
  const { positionDate, fundId } = props;

  const url = positionDate
    ? `stored_report_data_agg/${fundId}/currency_exposure/${positionDate}`
    : `most_recently_stored_report_data_agg/${fundId}/cis_specific_data`;

  const currencyData = useFetchData({
    keyName: `assetClassCurrencyData_${fundId}_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: url,
    // url: `${assetClassUrls.FETCH_EQUITY_DATA_FIRST_HALF
    //   }/${fundId}/currency_exposure${positionDate ? `/${positionDate}` : ''}`,
    makeFetch: true,
  });
  return (
    <GeneralComponentErrorShield
      dataObjects={[currencyData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      <CurrencyTable inputData={currencyData} />
      <CurrencyChart inputData={currencyData} />
    </GeneralComponentErrorShield>
  );
};

export default Currency;
