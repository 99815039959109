import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface ILiquidityFactorMetricsData {
  scenario: string;
  value: number;
}

interface Props {
  data: any;
  height: number | string;
}

export const DiscountRateColumns: CustomColumn<ILiquidityFactorMetricsData>[] =
  [
    {
      title: 'Scenario',
      field: 'scenario',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Liquidity Discount',
      field: 'value',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.value),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

export function builMetricsTableData(inputData: any) {
  if (!inputData.data.length) return [];
  if (!('discount_metrics' in inputData.data[0])) return [];
  if (!inputData.data[0].discount_metrics.length) return [];
  const DiscountRateData = inputData.data[0].discount_metrics;
  // const data = inputData[0];
  // scenario: string;
  // status: string;
  // value: number;
  // tooltip: string;

  return [
    {
      scenario: 'Current Liquidity Discount',
      value: DiscountRateData[0],
    },
    {
      scenario: 'Liquidity Discount Quantile Risk Measure',
      value: DiscountRateData[1],
    },
    {
      scenario: 'Liquidity Discount - Historical Average',
      value: DiscountRateData[2],
    },
    {
      scenario: ' Liquidity Discount - One Year Average',
      value: DiscountRateData[3],
    },
    {
      scenario: 'Liquidity Discount - Five Year Average',
      value: DiscountRateData[4],
    },
    {
      scenario: 'Liquidity Discount - Ten Year Average',
      value: DiscountRateData[5],
    },
    {
      scenario: 'Liquidity Discount - Worst Ever',
      value: DiscountRateData[6],
    },
  ];
}

function LiquidityFactorMetricsTable({
  data,
  height,
}: Props): ReactElement | null {
  const tableData = builMetricsTableData(data);
  // const columns = createFundAssetColumns(stressLevel as StressLevel);
  // const fundsSelector = createSectionFundsSelector('liquidity');
  // const funds = useSelector(fundsSelector);
  // const fundTitle =
  //     funds && funds.length
  //         ? funds.find((fund: any) => fund.id === activeFund)?.name + ' - Breakdown'
  //         : '';
  return (
    <GridItem xs={5} card cardStyle={{ minHeight: height }}>
      <CustomTable<ILiquidityFactorMetricsData>
        showToolbar={true}
        // loading={fundAssetData.isFetching}
        // csvFields={csvFundAssetLiquidationTime}
        options={{
          paging: false,
          sorting: false,
          showTitle: true,
        }}
        data={tableData}
        columns={DiscountRateColumns}
        title={'Liquidity Factor Metrics'}
        // toolbarComponents={{
        //     toolbarTitle: fundTitle,
        // }}
      />
    </GridItem>
  );
}

export default LiquidityFactorMetricsTable;
