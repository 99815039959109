import {
  CurrencyData,
  generateCurrencyColumns,
  generateCurrencyData,
} from './currencyTable.data';
import CustomTable from '../../../../tables/CustomTable';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { useTheme } from '@mui/material';

interface CurrencyTableProps {
  inputData: DataObject;
}

const CurrencyTable = (props: CurrencyTableProps) => {
  const { data, isFetching } = props.inputData;
  const tableData = generateCurrencyData(data);
  const tableColumns = generateCurrencyColumns();
  const theme = useTheme();

  return (
    <GridItem xs={12} xl={6} card>
      <CustomTable<CurrencyData>
        options={{
          paging: false,
          exportButton: true,
        }}
        toolbarComponents={{
          otherComponents: [
            <div style={{ display: 'flex' }} key="toolbar_titles">
              <h3
                style={{
                  margin: '0.5rem',
                  marginBottom: 0,
                  fontSize: '1.8rem',
                  fontWeight: 'normal',
                  color: theme.palette.primary.main,
                }}
              >
                <span style={{ color: theme.palette.grey[600] }}>NAV: </span>
                {data[0].base_currency}{' '}
                {isFetching ? '' : addCommasToNumbersAndRound(data[0].nav)}
              </h3>
              <h3
                style={{
                  margin: '0.5rem',
                  marginBottom: 0,
                  fontSize: '1.8rem',
                  fontWeight: 'normal',
                  color: theme.palette.secondary.main,
                }}
              >
                <span style={{ color: theme.palette.grey[600] }}>
                  Position Date:{' '}
                </span>
                {isFetching ? '' : data[0].selected_position_date}
              </h3>
            </div>,
          ],
        }}
        id="asset_class_currency_table"
        title="Currency"
        csvFields={tableColumns.map((col: any) => col.field)}
        showToolbar
        data={tableData}
        columns={tableColumns}
        loading={isFetching}
      />
    </GridItem>
  );
};

export default CurrencyTable;
