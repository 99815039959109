import { LegendProps } from 'recharts';
import RaptorLoading from '../../../../feedback/RaptorLoading';
import generateCurrencyChartData from './chart.data';
import { flexify } from '../../../../../utilities/cssMixins';
import { colorsArray } from '../../../../../styling/theme';
import { formatMillions } from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import GenericBarChart from '../../../../charts/GenericBarChart';

interface CurrencyChartProps {
  inputData: DataObject;
}

const CurrencyChart = (props: CurrencyChartProps) => {
  const legendFormatter = (innerProps: LegendProps) => {
    switch (innerProps) {
      case 'cash':
        return 'Cash';
      case 'forex':
        return 'Foreign Exchange';
      case 'asset':
        return 'Asset';
      case 'unrealisedPl':
        return 'Unrealised P/L';
      case 'total':
        return 'Total';
      default:
        return 'Unknown Value';
    }
  };

  const chartData = generateCurrencyChartData(props.inputData.data);

  const xAxis = [
    {
      dataKey: 'name',
      label: { value: 'Currency', dy: 10 },
    },
  ];

  const bars = [
    {
      dataKey: 'cash',
      key: 'cash',
      fill: colorsArray[0],
    },
    {
      dataKey: 'forex',
      key: 'forex',
      fill: colorsArray[1],
    },
    {
      dataKey: 'asset',
      key: 'asset',
      fill: colorsArray[2],
    },
    {
      dataKey: 'unrealisedPl',
      key: 'unrealisedPl',
      fill: colorsArray[3],
    },
    {
      dataKey: 'total',
      key: 'total',
      fill: colorsArray[4],
    },
  ];

  return !props.inputData.isFetching ? (
    <GridItem xs={12} xl={6} card cardStyle={{ height: '100%' }}>
      <GenericBarChart
        xAxes={xAxis}
        height={800}
        width={'100%'}
        bars={bars}
        data={chartData}
        title={''}
        loading={false}
        legend={true}
        yAxisLabel={{
          angle: -90,
          position: 'left',
          value: 'Exposure',
          style: {
            textAnchor: 'middle',
            // fill: theme.palette.grey[500],
          },
        }}
        yAxisFormatter={(value) => formatMillions(value)}
        legendWrapper={{ top: 10 }}
        legendLayout="horizontal"
        legendPositionHorizontal="center"
        legendPositionVertical="top"
        legendFormatter={legendFormatter}
        // tooltipFormatter={tooltipFormatter}
      />
      {/* </ResponsiveContainer> */}
    </GridItem>
  ) : (
    <GridItem xs={12} xl={6} card cardStyle={{ height: '100%', ...flexify() }}>
      <RaptorLoading />
    </GridItem>
  );
};

export default CurrencyChart;
