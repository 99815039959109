import { FC } from 'react';
import { buildTitleString } from '../table/table.component';
import { generateHistoricalTimeSeriesData } from '../data-view.data';
import { AdaptedYAxis } from '../../../../../../../types/components/charts/lineCharts';
import GenericLineChart from '../../../../../../charts/GenericLineChart';
import { DataObject } from '../../../../../../../types/redux/data/dataTypes';
import { ValuesType } from '../../controlBar/controlBar.component';
import { Typography } from '@mui/material';

export interface HistoricalLineChartProps {
  dimensions: { height: number; width: number };
  height: number;
  inputData: DataObject;
  datesAndKeys: ValuesType;
  fourShowing: boolean;
}
const chartColors = ['red', 'green'];

const HistoricalLineChart: FC<HistoricalLineChartProps> = (props) => {
  const chartData = generateHistoricalTimeSeriesData(props.inputData.data);

  // Default to having 2 axes
  let multipleYAxes = true;
  // Get the keys of each of the objects in the chart data list
  const keys = chartData.length > 0 ? Object.keys(chartData[0]) : [];
  // If there are two lines on the chart then decide if 2 y axes are required
  if (keys.length > 2) {
    // get the values for the first key
    const key1Values = chartData.map((element: any) => element[keys[1]]);
    // get the values for the second key
    const key2Values = chartData.map((element: any) => element[keys[2]]);
    // get the min and max values of both keys
    const key1Max = key1Values.reduce((a: number, b: number) => Math.max(a, b));
    const key1Min = key1Values.reduce((a: number, b: number) => Math.min(a, b));
    const key2Max = key2Values.reduce((a: number, b: number) => Math.max(a, b));
    const key2Min = key2Values.reduce((a: number, b: number) => Math.min(a, b));
    // Decide if the additional axis is required based on if the ranges, min and max values differ by more than 10
    multipleYAxes =
      Math.abs(key1Max - key1Min - (key1Max - key1Min)) > 10 ||
      Math.abs(key1Max - key2Max) > 10 ||
      Math.abs(key1Min - key2Min) > 10;
  }
  const lines = props.inputData.data.length
    ? props.inputData.data[0][0]
        .map((column: any, index: number) => {
          if (index === 0) {
            return null;
          }
          return {
            dataKey: column,
            // we're skipping index 1
            color: chartColors[index - 1],
            // fix to the left axis if only using one acis
            yAxisId: multipleYAxes ? (index === 1 ? 'left' : 'right') : 'left',
          };
        })
        .filter((el: any) => Boolean(el))
    : [];
  const additionalYAxis: AdaptedYAxis[] | undefined =
    lines.length > 1 && multipleYAxes
      ? lines
          .map((line: any, index: number) => {
            if (index > 0) {
              return {
                leftDataKey: line.dataKey,
                orientation: 'right',
                dataKey: line.dataKey,
                yAxisId: 'right',
              };
            } else {
              return null;
            }
          })
          .filter((el: any) => Boolean(el))
      : undefined;

  return lines.length ? (
    <>
      <div
        style={{
          padding: '1rem',
          minHeight: 56,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          // alignSelf: 'stretch',
        }}
      >
        <Typography
          variant="h2"
          style={{ fontWeight: 300, textAlign: 'center' }}
        >
          {buildTitleString(props.datesAndKeys)}
        </Typography>
      </div>
      <GenericLineChart
        title={buildTitleString(props.datesAndKeys)}
        height={props.height}
        width={'100%'}
        data={chartData}
        lines={lines}
        margin={{ top: 0, bottom: 30, left: 50, right: 50 }}
        error={props.inputData.error}
        additionalYAxis={additionalYAxis}
      />
    </>
  ) : null;
};

export default HistoricalLineChart;
