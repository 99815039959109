import React, { FC } from 'react';
import CustomTable from '../../../../tables/CustomTable';
import {
  AifReportsData,
  generateAifmdData,
  generateReportsColumns,
} from './data';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { AifmdReportType } from '../../overview/table/components/ReportsLinkButton/reportsLinkButton.component';

export interface ReportsTableProps {
  inputData: DataObject;
  reportType: AifmdReportType;
}

const ReportsTable: FC<ReportsTableProps> = ({ inputData, reportType }) => {
  const tableData = generateAifmdData(inputData.data, reportType);
  const reportsColumns = generateReportsColumns(reportType);

  return (
    <CustomTable<AifReportsData>
      loading={inputData.isFetching}
      data={tableData}
      columns={reportsColumns}
      options={{
        paging: false,
        exportButton: true,
        maxBodyHeight: 'calc(100vh - 20rem)',
        rowStyle: {
          padding: '1rem',
        },
      }}
      showToolbar
      toolbarComponents={{
        toolbarTitle: reportType.replace(/^\w/, (c) => c.toUpperCase()),
      }}
      id={`aifmd_reports_${reportType}`}
      title={reportType.replace(/^\w/, (c) => c.toUpperCase())}
    />
  );
};

export default ReportsTable;
