import { ReactElement } from 'react';
import { StressTestsPositions } from './StressTestsTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import CustomTable from '../../../tables/CustomTable';
import {
  addCommasToNumbers,
  percentageToNdecialPlaces,
} from '../../../../utilities/numberFormatters';
import makeStyles from '@mui/styles/makeStyles';
import { greys } from '../../../../styling/theme';

const useStyles = makeStyles(() => ({
  root: {
    margin: '1rem',
    border: `1px solid ${greys.grey200}`,
  },
}));

interface Props {
  positions: StressTestsPositions[];
}

export const columns: CustomColumn<StressTestsPositions>[] = [
  {
    title: 'Position Name',
    field: 'position_name',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Size',
    field: 'size',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      return addCommasToNumbers(rowData.size);
    },
  },
  {
    title: 'Base Currency Exposure',
    field: 'exposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      return addCommasToNumbers(rowData.exposure);
    },
  },
  {
    title: 'Base Currency Exposure (% of NAV)',
    field: 'exposure_percentage',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      return percentageToNdecialPlaces(rowData.exposure_percentage, 2);
    },
  },
  {
    title: 'Change in P/L',
    field: 'change_in_pnl',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      return addCommasToNumbers(rowData.change_in_pnl);
    },
  },
  {
    title: 'Change in P/L (% of NAV)',
    field: 'change_in_pnl_percentage',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      return percentageToNdecialPlaces(rowData.change_in_pnl_percentage, 2);
    },
  },
  {
    title: 'Is Derivative',
    field: 'is_derivative',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      return rowData.is_derivative ? 'Yes' : 'No';
    },
  },
];

function PositionsTable({ positions }: Props): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomTable<StressTestsPositions>
        title={`Underlying Positions`}
        columns={columns}
        data={positions}
        showToolbar={true}
        options={{
          paging: false,
          exportButton: true,
          draggable: false,
        }}
      />
    </div>
  );
}
export default PositionsTable;
