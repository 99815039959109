import { DataObject } from '../../../../../types/redux/data/dataTypes';
import React, { ReactElement } from 'react';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../../hooks/useFetchData';
import RaptorLoading from '../../../../feedback/RaptorLoading';
import CounterpartySummaryTable from './SummaryTable';
import CounterpartyBarChart from './BarChart';
import CounterpartyMainTable from './mainTable';

interface ICounterpartyTablesProps {
  activeCounterpartyAgg: DataObject;
  counterpartyGeneralMonitorExposure: DataObject;
  selectedFund: string;
  positionDate: string | null;
  counterpartyOptions: any;
}

function CounterpartyTables({
  activeCounterpartyAgg,
  counterpartyGeneralMonitorExposure,
  selectedFund,
  counterpartyOptions,
  positionDate,
}: ICounterpartyTablesProps): ReactElement {
  const loading =
    activeCounterpartyAgg.isFetching ||
    counterpartyGeneralMonitorExposure.isFetching;

  const counterparty = useTitleBarSelect('counterpartySelection');

  const relevantCounterpartyData = useFetchData({
    url: !positionDate
      ? `counterparty_general_exposure_monitor_agg/${selectedFund}/${counterparty}`
      : `counterparty_general_exposure_monitor_agg/${selectedFund}/${counterparty}/${positionDate}`,
    keyName: `${selectedFund}_${positionDate}_${counterparty}`,
    makeFetch: true,
  });

  return relevantCounterpartyData &&
    !relevantCounterpartyData.isFetching &&
    counterparty ? (
    <>
      <CounterpartySummaryTable
        currentlySelectedCounterparty={
          counterpartyOptions.filter((el: any) => {
            return el.value === counterparty;
          })[0].label
        }
        selectedCounterpartyData={relevantCounterpartyData}
      />
      <CounterpartyBarChart inputData={relevantCounterpartyData} />
      <CounterpartyMainTable
        selectedCounterpartyData={relevantCounterpartyData}
      />
    </>
  ) : (
    <RaptorLoading centerWrap />
  );
}

export default CounterpartyTables;
/*

 */
