import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import AggregateExposureSecondLevelTable from './AggregateExposureSecondLevelTable';
import { AgTableData } from './AggregateExposureTable';

const AggregateExposureSecondLevelTableWrapper = () => [
  (rowData: AgTableData) => {
    const icon = () => (rowData.detailPanelData ? <UnfoldMore /> : <></>);
    const render = () => (
      <AggregateExposureSecondLevelTable data={rowData.detailPanelData || []} />
    );
    return {
      disabled: rowData.detailPanelData ? false : true,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default AggregateExposureSecondLevelTableWrapper;
