import React from 'react';
import useFetchData from '../../../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../../../general/GeneralComponentErrorShield';
import makeStyles from '@mui/styles/makeStyles';
import PositionsTable from './PositionsTable.component';

interface PositionsTableDataProps {
  fundId: string;
  positionIds: string[];
  positionDate: string;
}

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    padding: '2rem 0',
    paddingBottom: 0,
  },
}));

const PositionsTableData: React.FC<PositionsTableDataProps> = ({
  fundId,
  positionIds,
  positionDate,
}) => {
  const formData = new FormData();
  formData.append('positions', JSON.stringify(positionIds));

  const positionsData = useFetchData({
    url: `positions_from_ids/${fundId}/${positionDate}`,
    keyName: `positions_from_ids_${fundId}_${positionDate}_${positionIds}`,
    formData: formData,
    makeFetch: fundId && positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[positionsData]}
      setLoadingHeight={'45rem'}
    >
      <PositionsTable dataObject={positionsData} />
    </GeneralComponentErrorShield>
  );
};

export default PositionsTableData;
