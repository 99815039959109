import React from 'react';
import { greys, mainColors } from '../../../styling/theme';
import { ESGChartProps } from './EsgChartsData';

const PrincipalAdverseImpactChart = (props: ESGChartProps) => {
  const { value } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const boxHeight = value ? ((100 - value) / 100) * 24 : 0;
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="cut-off-principal-adverse-impact">
          <rect x="0" y="0" width="200" height={boxHeight} />
        </clipPath>
      </defs>
      <path
        id="principal_adverse_impact"
        d="M15 13V5c0-1.66-1.34-3-3-3S9 3.34 9 5v8c-1.21.91-2 2.37-2 4 0 2.76 2.24 5 5 5s5-2.24 5-5c0-1.63-.79-3.09-2-4zm-4-8c0-.55.45-1 1-1s1 .45 1 1h-1v1h1v2h-1v1h1v2h-2V5z"
        fill={greys.grey400}
      />
      <path
        id="principal_adverse_impact"
        d="M15 13V5c0-1.66-1.34-3-3-3S9 3.34 9 5v8c-1.21.91-2 2.37-2 4 0 2.76 2.24 5 5 5s5-2.24 5-5c0-1.63-.79-3.09-2-4zm-4-8c0-.55.45-1 1-1s1 .45 1 1h-1v1h1v2h-1v1h1v2h-2V5z"
        fill={mainColors.mainBlue_lighter}
      />

      <use
        clipPath="url(#cut-off-principal-adverse-impact)"
        xlinkHref="#principal_adverse_impact"
      />
    </svg>
  );
};

export default React.memo(PrincipalAdverseImpactChart);
