import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { FirstLevelTableData } from './FirstLevelTable.component';
import SecondLevelTable from './SecondLeveleTable.component';

const secondLevelTableWrapper = () => [
  (rowData: FirstLevelTableData) => {
    const icon = () => (rowData.shareClassData.length ? <UnfoldMore /> : <></>);
    const render = () => <SecondLevelTable data={rowData.shareClassData} />;
    return {
      disabled:
        !rowData.shareClassData.length || rowData.shareClassData.length === 1,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default secondLevelTableWrapper;
