import React, { FC } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { SourcesData } from './HitsTable.component';

interface SourcesTableProps {
  data: SourcesData[];
}

const columns: CustomColumn<SourcesData>[] = [
  {
    title: 'Names',
    field: 'names',
    width: '60%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Source',
    field: 'source',
    width: '20%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const SecondLevelTable: FC<SourcesTableProps> = ({ data }) => {
  return (
    <GridItem card xs={12} cardStyle={{ height: '100%' }}>
      <CustomTable<SourcesData>
        id="sanctions_scanner_sources"
        title={'Sources'}
        showToolbar={true}
        columns={columns}
        data={data}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default SecondLevelTable;
