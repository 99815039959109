export interface IFundSharePrice {
  name: string | number;
  price: number;
}

export function buildFundSharePrice(data: any) {
  const output = data[0].cis_time_series.map((data: any) => {
    return {
      name: data[0],
      price: data[1],
    };
  });
  output.shift();
  return output;
}
