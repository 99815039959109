import React from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../../utilities/numberFormatters';

interface LVarPositionLevelProps {
  data: DataObject;
}

interface LVarPositionLevelData {
  asset: string;
  countryIndex: string;
  sectorIndex: string;
  strategyIndex: string;
  exposure: number;
  currentDiscountRate: number;
  discountRateVar: number;
  monthlyVar: number;
  monthlyLVar: number;
  gmid: number;
}

const buildTableData = (inputData: DataObject): LVarPositionLevelData[] => {
  if (!inputData.data.length) return [];
  if (!('positions_data' in inputData.data[0])) return [];
  const varData = inputData.data[0].positions_data;
  const tableData: LVarPositionLevelData[] = [];

  varData.forEach((value: any) => {
    tableData.push({
      asset: value.gm_name,
      countryIndex: value.location,
      sectorIndex: value.sector,
      strategyIndex: value.strategy,
      exposure: value.bc_exposure,
      currentDiscountRate: value.current_discount_factor,
      discountRateVar: value.L_VaR,
      monthlyVar: value.monthly_VaR,
      monthlyLVar: value.liquidity_discount_VaR,
      gmid: value.gm_id_old,
    });
  });

  return tableData;
};

const LVarPositionLevelTableColumns: CustomColumn<LVarPositionLevelData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Country Index',
    field: 'countryIndex',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Sector Index',
    field: 'sectorIndex',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Strategy Index',
    field: 'strategyIndex',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Exposure',
    field: 'exposure',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarPositionLevelData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.exposure),
  },
  {
    title: 'Current Liquidity Factor',
    field: 'currentDiscountRate',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarPositionLevelData) =>
      percentageToTwoDecimalPlaces(rowData.currentDiscountRate),
  },
  {
    title: 'Monthly Quantile Risk',
    field: 'monthlyVar',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarPositionLevelData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.monthlyVar),
  },
  {
    title: 'Liquidity Adjusted Monthly Quantile Risk',
    field: 'monthlyLVar',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarPositionLevelData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.discountRateVar),
  },
];

const LVarPositionLevel: React.FC<LVarPositionLevelProps> = ({ data }) => {
  const tableData = buildTableData(data);

  return (
    <GridItem card xs={12} style={{ padding: 8 }}>
      <CustomTable<LVarPositionLevelData>
        columns={LVarPositionLevelTableColumns}
        showToolbar
        data={tableData}
        title={'Position Level Data'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default LVarPositionLevel;
