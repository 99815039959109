import { ThemeOptions, Theme, Shadows, createTheme } from '@mui/material';
import { ThemeIcons } from './themeInterfaces/icons';

interface Status {
  danger: string;
}

interface Layout {
  drawerWidth: number;
}

interface Borders {
  light: string;
  main: string;
}

interface RaptorThemeOptions extends ThemeOptions {
  status: Status;
  layout: Layout;
  icons: ThemeIcons;
  borders?: Borders;
}
export interface RaptorTheme extends Theme {
  status: Status;
  layout: Layout;
  icons: ThemeIcons;
  borders?: Borders;
}

export enum mainColors {
  mainBlue = '#274C77',
  mainBlue_lighter = '#396fad',
  mainBlue_lightAlt = '#9dadbf',
  Fail = '#ED150E',
  Fail_darker = '#d9130d',
  Alert = '#FC7315',
  Alert_darker = '#e36f09',
  Pass = '#38BF26',
  Pass_darker = '#31a822',
  NA = '#A7A7A7',
  NA_darker = '#999999',
  secondaryRed = '#d7263d',
  tertiaryGreen = '#4d8b31',
  fourthPurple = '#462255',
  fifthTangerine = '#FF8E72',
}

export const colorsArray = [
  mainColors.mainBlue,
  mainColors.secondaryRed,
  mainColors.tertiaryGreen,
  mainColors.mainBlue_lighter,
  mainColors.fifthTangerine,
];

export enum StatusColors {
  Fail = mainColors.Fail,
  Alert = mainColors.Alert,
  Pass = mainColors.Pass,
}

export enum greys {
  headerGrey = '#838383',
}

function createShadows(): Shadows {
  const shadows: Shadows = Array<string>(25).fill('none') as Theme['shadows'];
  shadows[1] = '0px 3px 3px rgba(0,0,0,0.3)';
  shadows[2] = '0px 3px 6px rgba(0,0,0,0.3)';
  shadows[3] = '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
  shadows[4] = '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)';
  return shadows;
}

const generateCustomTheme = () => {
  const customTheme: RaptorThemeOptions = {
    borders: {
      light: '1px solid #E7ECEF',
      main: `1px solid ${mainColors.mainBlue}`,
    },
    palette: {
      primary: {
        main: mainColors.secondaryRed,
      },
      background: {
        default: '#E7ECEF',
      },
      grey: {
        100: '#f1f1f1',
        200: '#ededed',
        300: '#dbdbdb',
        400: '#cccccc',
        500: '#c2c2c2',
        600: '#b8b8b8',
        700: '#a8a8a8',
        800: '#9e9e9e',
      },
    },
    transitions: {
      easing: {
        easeOut: 'ease-out',
      },
      duration: {
        enteringScreen: 200,
        leavingScreen: 200,
      },
    },
    layout: {
      drawerWidth: 240,
    },
    status: {
      danger: 'orange',
    },
    icons: {
      size: 20,
    },
    typography: {
      fontFamily: ['Jost'].join(','),
      htmlFontSize: 10,
      h1: {
        fontWeight: 400,
        fontSize: '3.2rem',
        color: mainColors.mainBlue,
      },
      h2: {
        fontSize: '2.4rem',
      },
      h3: {
        color: mainColors.mainBlue,
        fontSize: '1.6rem',
      },
      h4: {
        fontSize: '2.4rem',
        color: greys.headerGrey,
      },
      subtitle1: {
        fontSize: '1.6rem',
      },
      subtitle2: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: greys.headerGrey,
        textTransform: 'uppercase',
      },
    },
    shadows: createShadows(),
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '1.4rem',
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: mainColors.mainBlue,
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 8,
          },
        },
      },

      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: mainColors.mainBlue,
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '0 0px 6px rgba(0,0,0,0.1)',
          },
          list: {
            padding: 0,
          },
        },
      },

      MuiListItem: {
        styleOverrides: {
          root: {
            '&:not(:last-child)': {
              borderBottom: `1px solid #edeef0`,
            },
          },
        },
      },

      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&.MuiTableSortLabel-active': {
              color: mainColors.mainBlue_lighter,
            },
          },
        },
      },

      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: '0 0px 6px rgba(0,0,0,0.1)',
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: mainColors.mainBlue,
            color: 'white',
          },
          clickable: {
            '&:hover': {
              backgroundColor: mainColors.mainBlue_lighter,
            },
          },
          deleteIcon: {
            color: 'white',
            '&:hover': {
              color: '#d8d8d8',
            },
          },
        },
      },
    },
  };

  return customTheme;
};

const theme = createTheme(generateCustomTheme());
export default theme;
