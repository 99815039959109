/*
 * The buildCsv function needs an array of strings detailing which parameters from the data to use
 */
interface DataColumn {
  field: string;
  [key: string]: any;
}
export function buildCsv<T extends DataColumn>(csvFields: string[], data: T[]) {
  // step 1: map over each row
  return data.map((datum: T) => {
    const newObj: { [key: string]: any } = {};
    csvFields.forEach((field) => {
      if (datum[field] !== null) {
        newObj[field] = datum[field];
      } else {
        newObj[field] = '';
      }
    });
    return newObj;
  });
}
