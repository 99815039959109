import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import MancoExposureSecondLevelTable from './MancoExposureSecondLevelTable.component';
import { IMancoExposureTableData } from './MancoExposureTable.component';

const MancoExposureSecondLevelTableWrapper = () => [
  (rowData: IMancoExposureTableData) => {
    const icon = () =>
      rowData.underlyingAssets.length ? <UnfoldMore /> : <></>;
    const render = () => (
      <MancoExposureSecondLevelTable
        underlyingAssets={rowData.underlyingAssets}
      />
    );
    return {
      disabled: !rowData.underlyingAssets.length,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default MancoExposureSecondLevelTableWrapper;
