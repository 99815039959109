import React, { ReactElement } from 'react';
import {
  IExceedanceTableData,
  buildExceedanceData,
  csvExceedanceTable,
  exceedanceColumns,
} from './exceedanceTable.data';
import CustomTable from '../../../../../tables/CustomTable';
import ExceedanceSecondaryTable from './secondaryTable/secondaryTable.component';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';

interface Props {
  varData: DataObject;
}

function ExceedanceTable({ varData }: Props): ReactElement {
  const tableData = buildExceedanceData(varData.data);
  return (
    <GridItem xs={12} xl={6} card>
      <CustomTable<IExceedanceTableData>
        options={{
          paging: false,
          exportButton: true,
          rowStyle: {
            padding: '0.3rem, 0.5rem',
          },
        }}
        csvFields={csvExceedanceTable}
        data={tableData}
        loading={varData.isFetching}
        columns={exceedanceColumns}
        showToolbar
        detailPanel={[
          {
            icon: 'trending_up',
            tooltip: 'Show breakdown',
            render: (rowData) => {
              return (
                <ExceedanceSecondaryTable
                  tValue={rowData.t}
                  tPlusOneValue={rowData.tplusone}
                />
              );
            },
          },
        ]}
        toolbarComponents={{
          toolbarTitle: 'Exceedance Breakdown',
        }}
      />
    </GridItem>
  );
}

export default ExceedanceTable;
