import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../layout/GridComponents/GridItem';
import { mainColors } from '../../../../styling/theme';
import ExportItem from './subComponents/ExportItem.component';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { Grid } from '@mui/material';

interface RaptorExportTilesProps {
  positionDate: string;
}

const useStyles = makeStyles(() => ({
  pageTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    marginBottom: '2rem',
  },
  sectionTitle: {
    color: mainColors.mainBlue,
    fontSize: '3rem',
    fontWeight: 600,
    marginBottom: '1rem',
  },
  sectionContent: {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
  },
}));

const RaptorExportTiles: React.FC<RaptorExportTilesProps> = ({
  positionDate,
}) => {
  const classes = useStyles();

  const FundGroupsData = useFetchData({
    url: `/available_pptx_client`,
    keyName: `available_pptx_client`,
    makeFetch: true,
  });

  // capitalise first letter of client name
  const clientName = useSelector(clientNameSelector) || 'mersenne';

  // capitalise first letter of client name
  const clientNameCapitalised =
    clientName.charAt(0).toUpperCase() + clientName.slice(1);

  return (
    <GeneralComponentErrorShield
      dataObjects={[FundGroupsData]}
      customLoadingMessages={[
        'Loading Export Options Data',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem xs={12} card cardStyle={{ padding: '2rem' }}>
          <div className={classes.sectionTitle}>Compliance</div>
          <div className={classes.sectionContent}>
            <ExportItem
              title={'UCITS Rule (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/ucits_rule_overview/${positionDate}`}
              fileName={`${clientNameCapitalised}-UCITS-Rules-${positionDate}.xlsx`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'UCITS Prospectus Rules (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/ucits_prospectus_rule_overview/${positionDate}`}
              fileName={`${clientNameCapitalised}-UCITS-Prospectus-Rules-${positionDate}.xlsx`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'SIF/RAIF Prospectus Rules (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/aif_prospectus_rule_overview/${positionDate}`}
              fileName={`${clientNameCapitalised}-SIF_RAIF-Prospectus-Rules-${positionDate}.xlsx`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'SIF/RAIF CSSF Rules (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/aif_cssf_rule_overview/${positionDate}`}
              fileName={`${clientNameCapitalised}-SIF_RAIF-CSSF-Rules-${positionDate}.xlsx`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'BA3 Report (.zip)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/adepa_ba3/${positionDate}`}
              fileName={`BA3_Overview_Daily_Report${positionDate}.zip`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'All Reports (.zip)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/all_reports/${positionDate}`}
              fileName={`RiskSystem-${positionDate}.zip`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'CBA Funds Summary (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/cba_funds_specific_report/${positionDate}`}
              fileName={`${clientNameCapitalised}-CBA Funds Summary-${positionDate}.xlsx`}
              openDownloadInNewTab
            />
            <ExportItem
              title={'Board Report (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`/manco_level_data_export/${positionDate}`}
              fileName={`${clientNameCapitalised}-Risk Report_v7-${positionDate}.xlsx`}
              openDownloadInNewTab
            />
          </div>
        </GridItem>
        <GridItem xs={12} card cardStyle={{ padding: '2rem' }}>
          <div className={classes.sectionTitle}>Anti Money Laundering</div>
          <div className={classes.sectionContent}>
            <ExportItem
              title={'Anti Money Laundering (.xlsx)'}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`overview_report/aml_report/${positionDate}`}
              fileName={`${clientNameCapitalised}_Funds_AML_Asset_Level_data_${positionDate}.xlsx`}
              openDownloadInNewTab
            />
          </div>
        </GridItem>
        <GridItem xs={12} card cardStyle={{ padding: '2rem' }}>
          <div className={classes.sectionTitle}>Monthly Reporting</div>
          <div className={classes.sectionContent}>
            <ExportItem
              title={'Monthly Reporting (.pptx)'}
              description={
                'Choose the fund group to generate the monthly reporting powerpoint.'
              }
              options={[
                {
                  type: 'single-select',
                  id: 'fund-group',
                  title: 'Fund Group:',
                  options:
                    FundGroupsData &&
                    FundGroupsData.data &&
                    FundGroupsData.data.length
                      ? FundGroupsData.data[0]
                      : [],
                },
              ]}
              // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
              downloadUrl={`pptx_monthly_reporting/{fund-group}/${positionDate}`}
              fileName={'{fund-group} - Monthly Report - InvComp.pptx'}
              openDownloadInNewTab
            />
          </div>
        </GridItem>
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default RaptorExportTiles;
