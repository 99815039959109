import { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import SubscriptionsRedemptionsTable from './subscriptionsRedemptionsTable.component';
import SubscriptionsRedemptionsChart from './subcsriptionsRedemptionsChart.component';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import { Grid } from '@mui/material';

const SubscriptionsRedemptions: FC<FundInfoComponentProps> = (props) => {
  const activeDate = props.positionDate;

  const urlForFetch = !activeDate
    ? `most_recently_stored_report_data_agg/${props.fundId}/sub_red_risk_data`
    : `stored_report_data_agg/${props.fundId}/sub_red_risk_data/${activeDate}`;

  const subsRedsData = useFetchData({
    keyName: makeUniqueDataKey(props.fundId, 'sub_red', activeDate),
    url: urlForFetch,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[subsRedsData]}
      customLoadingMessages={['Loading Subscriptions/Redemptions Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container>
        <SubscriptionsRedemptionsTable
          data={subsRedsData}
          title={'Redemption Risk'}
        />
        <SubscriptionsRedemptionsChart
          data={subsRedsData}
          title={'Redemption Data'}
        />
        <SubscriptionsRedemptionsTable
          data={subsRedsData}
          title={'Gross Activity Risk'}
        />
        <SubscriptionsRedemptionsChart
          data={subsRedsData}
          title={'Gross Activity Data'}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default SubscriptionsRedemptions;
