import React, { FC, useEffect, useState } from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import Datepicker from './subComponents/Datepicker.component';
import HitsTable from './subComponents/HitsTable.component';
import NoDataMessage from '../../../feedback/NoDataMessage.component';

const SanctionsScanner: FC = () => {
  const fundId = 'pure9001';
  const [activeDate, setActiveDate] = useState<string | null>(null);
  const [availableDates, setAvailableDates] = useState<string[]>([]);

  const dates = useFetchData({
    url: `/sanctions_scanner/position_dates/${fundId}`,
    keyName: `sanctions_scanner_position_dates_${fundId}`,
    makeFetch: true,
  });

  useEffect(() => {
    if (dates && dates.data && dates.data[0] && dates.data[0][0]) {
      setActiveDate(dates.data[0][0]);
    }
  }, [dates]);

  const hitsData = useFetchData({
    url: `/sanctions_scanner/get_hits/${fundId}/${activeDate}`,
    keyName: `sanctions_scanner_get_hits_${fundId}_${activeDate}`,
    makeFetch: activeDate !== null,
  });

  const excludedIsins = useFetchData({
    url: `/sanctions_scanner/get_excluded_isins/${fundId}/${activeDate}`,
    keyName: `sanctions_scanner_get_excluded_isins_${fundId}_${activeDate}`,
    makeFetch: activeDate !== null,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[dates, hitsData, excludedIsins]}
      customLoadingMessages={[
        'Loading Sanctions Scanner...',
        'This May Take A Few Moments',
      ]}
      customErrorMessage="No Data Found"
    >
      {activeDate === null ? (
        <NoDataMessage message={'No Data Found'} />
      ) : (
        <>
          <Datepicker
            initialDate={activeDate}
            availableDates={availableDates}
          />
          <HitsTable
            fundId={fundId}
            positionDate={activeDate || ''}
            dataForRender={hitsData}
            excludedIsinsData={excludedIsins}
          />
        </>
      )}
    </GeneralComponentErrorShield>
  );
};

export default SanctionsScanner;
