import React, { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import ShareClassHedgeSummaryTable from './ShareClassHedgeSummaryTable.component';
import { useSelector } from 'react-redux';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';

const ShareClassHedgeSummary: FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
  section,
}) => {
  const sectionDetails = useSelector(createSectionByIdSelector(section));
  const fundTypes = sectionDetails?.fundTypes;

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';

  const ShareClassHedgeSummaryData = useFetchData({
    url: `get_hedge_ratios/${positionDate}` + schemas,
    keyName: `${fundTypes}_hedge_ratios_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[ShareClassHedgeSummaryData]}>
      <ShareClassHedgeSummaryTable data={ShareClassHedgeSummaryData} />
    </GeneralComponentErrorShield>
  );
};

export default ShareClassHedgeSummary;
