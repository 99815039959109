import { SvgIcon } from '@mui/material';

const MoabIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17,12.83c-.1-1.91-.23-4.21-.2-5.92h-.07c-.47,1.61-1.05,3.32-1.75,5.21l-2.45,6.66H11.16L8.91,12.24c-.66-1.93-1.21-3.7-1.6-5.33h0c0,1.71-.15,4-.27,6.07l-.37,5.88H4.92l1-13.72H8.17l2.37,6.64c.57,1.68,1.05,3.19,1.4,4.61H12c.35-1.38.84-2.88,1.46-4.61l2.47-6.64h2.29l.86,13.72H17.33Z"
        transform="translate(0.13 0.12)"
      />
    </SvgIcon>
  );
};

export default MoabIcon;
