import { useEffect, useState } from 'react';

interface Props {
  messages: string[];
  timeDelay: number;
}

const useFeedbackMessages = (props: Props) => {
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);

  function handleMessage(counter: number, messages: string[]) {
    setCurrentMessage(messages[counter % messages.length]);
  }

  useEffect(() => {
    let counter = 0;
    handleMessage(counter++, props.messages);
    const interval = window.setInterval(() => {
      if (props.messages) {
        handleMessage(counter++, props.messages);
      }
    }, props.timeDelay);

    return () => {
      clearInterval(interval);
    };
  }, [props.messages]);

  return [currentMessage];
};

export default useFeedbackMessages;
