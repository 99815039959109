import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useState } from 'react';
import { useWindowSize } from 'react-use';
import theme, {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../styling/theme';
import {
  percentageToNoDecimalPlacesNoHundred,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../utilities/numberFormatters';
import GenericBarChart from '../../../charts/GenericBarChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../selects/GeneralSelect';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
}));

interface Props {
  data: any;
  stressLevel: string;
}

interface chartData {
  name: number;
  dailyPercentage: number;
  cumulativePercentage: number;
}

function buildChartData(inputData: any, level: string): chartData[] {
  if (!inputData.length) return [];
  if (!('lasdab' in inputData[0])) return [];
  const data = inputData[0].lasdab;
  let stressData = [];
  if (level === 'standard') {
    stressData = data['bau'];
  } else if (level === 'stressed') {
    stressData = data['stressed'];
  } else if (level === 'superStressed') {
    stressData = data['super_stressed'];
  }
  const returnData: chartData[] = [];
  stressData.slice(1).forEach((value: any) => {
    returnData.push({
      name: value[0],
      dailyPercentage: value[1],
      cumulativePercentage: value[2],
    });
  });
  return returnData;
}

function buildBarsData(stressLevel: string, dataType: string) {
  switch (stressLevel) {
    case 'standard':
      return [
        {
          dataKey: dataType,
          fill: mainColors.mainBlue,
          key: dataType,
        },
      ];
    case 'stressed':
      return [
        {
          dataKey: dataType,
          fill: mainColors.Alert,
          key: dataType,
        },
      ];
    case 'superStressed':
      return [
        {
          dataKey: dataType,
          fill: mainColors.Fail,
          key: dataType,
        },
      ];
    default:
      return [
        {
          dataKey: dataType,
          fill: mainColors.mainBlue,
          key: dataType,
        },
      ];
  }
}

function LasdabChart({ data, stressLevel }: Props): ReactElement | null {
  const chartData = data ? buildChartData(data.data, stressLevel) : [];
  const { width, height } = useWindowSize();
  const [balanceType, setBalanceType] = useState('cumulativePercentage');
  const bars = buildBarsData(stressLevel, balanceType);
  // Set up the select for chosing net or gross exposure
  const setSelected = (value: any) => {
    setBalanceType(value);
  };
  const classes = useStyles();

  return (
    <GridItem xs={9} card>
      <div
        style={{
          float: 'right',
          // height: 500,
          marginRight: '1rem',
          marginTop: '1rem',
          width: '15%',
        }}
      >
        <GeneralSelect
          labels={['Cumulative Balance', 'Daily Balance']}
          values={['cumulativePercentage', 'dailyPercentage']}
          selected={balanceType}
          setSelected={setSelected}
          placeholderValue=""
          placeholderColor={greys.grey900}
          selectTitle={'Balance Type'}
          customStyles={{
            pickerRoot: classes.pickerRoot,
            selectRoot: classes.selectRoot,
          }}
        />
      </div>
      <GenericBarChart
        data={chartData}
        tooltipFormatter={(value: any, name: any) => [
          percentageToTwoDecimalPlacesNoHundred(value as number),
          `${
            balanceType === 'cumulativePercentage' ? 'Cumulative' : 'Daily'
          } Balance`,
        ]}
        height={height * 0.7}
        width={'100%'}
        xAxes={[
          {
            tick: {
              fontSize: '1.4rem',
            },
            label: {
              value: '# Days',
              offset: 0,
              position: 'bottom',
              style: {
                fill: theme.palette.grey[700],
              },
            },
            dataKey: 'name',
          },
        ]}
        bars={bars}
        loading={false}
        title={`${
          balanceType === 'cumulativePercentage' ? 'Cumulative' : 'Daily'
        } Achievable Balance`}
        yAxisFormatter={(value) => percentageToNoDecimalPlacesNoHundred(value)}
      />
    </GridItem>
  );
}

export default LasdabChart;
