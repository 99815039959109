import React, { useState } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../styling/theme';
import RaptorFileDownloadButton, {
  RaptorFileDownloadButtonStatus,
} from '../../../feedback/RaptorFileDownloadButton.component';
import { BASE_URL } from '../../../../utilities/requestClient';

const useStyles = makeStyles(() => ({
  pageTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    marginBottom: '2rem',
  },
  sectionTitle: {
    color: mainColors.mainBlue,
    fontSize: '3rem',
    fontWeight: 600,
    marginBottom: '1rem',
  },
  sectionContent: {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
  },
  exportItemButton: {
    margin: '0.5rem 0',
    width: '20rem',
  },
}));

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

const FixedIncomeReportGenerator: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const classes = useStyles();
  // initialise the download status state
  const [status, setStatus] = useState<RaptorFileDownloadButtonStatus>('idle');
  // when the user clicks the download button, send the request to the server, updating the download status
  // if the request is successful, download the file using the programmatically calculated file name
  function generateReport() {
    setStatus('loading');
    const url = `${BASE_URL}generate_fixed_income_report/${props.positionDate}`;
    const openReport = openInNewTab(url);
    openReport();
    setStatus('idle');
  }
  return (
    <GridItem xs={12} card cardStyle={{ padding: '2rem' }}>
      <div className={classes.sectionTitle}>Fixed Income Report</div>

      <div className={classes.exportItemButton} onClick={generateReport}>
        <RaptorFileDownloadButton status={status} />
      </div>
    </GridItem>
  );
};

export default FixedIncomeReportGenerator;
