import React, { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import AggregateExposureTable from './AggregateExposureTable.component';
import PeExposureBarChart from './PeExposureBarChart.component';
import PeExposureTable from './PeExposureTable.component';
import TopTenExposuresBarChart from './TopTenExposuresBarChart.component';

const PeExposure: FC<FundInfoComponentProps> = ({ fundId, positionDate }) => {
  const exposureData = useFetchData({
    url: `fund_gem_data/${fundId}/${positionDate}`,
    keyName: `exposure_data_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });
  const peExposureBreakdowns = useFetchData({
    url: `getPeExposureBreakdowns/${fundId}/${positionDate}`,
    keyName: `pe_exposure_breakdowns_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });
  const peExposureTables = useFetchData({
    url: `getPeExposureTable/${fundId}/${positionDate}`,
    keyName: `pe_exposure_tables_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });
  return (
    <GeneralComponentErrorShield
      dataObjects={[exposureData, peExposureBreakdowns]}
      customLoadingMessages={['Loading Exposure Data...']}
    >
      <GridItem xs={12} style={{ display: 'flex', gap: 16 }}>
        <AggregateExposureTable dataForRender={exposureData} fundId={fundId} />
        <GridItem
          xs={9}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            height: '100%',
          }}
        >
          <TopTenExposuresBarChart data={peExposureBreakdowns} />
          <GridItem xs={12} style={{ display: 'flex', gap: 16 }}>
            <PeExposureBarChart data={peExposureTables} chartOption={0} />
            <PeExposureBarChart data={peExposureTables} chartOption={1} />
            <PeExposureBarChart data={peExposureTables} chartOption={2} />
          </GridItem>
        </GridItem>
      </GridItem>
      <GridItem xs={12} style={{ padding: 8 }}>
        <PeExposureTable data={exposureData} fundId={fundId} />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default PeExposure;
