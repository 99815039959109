import React, { ReactElement } from 'react';
import {
  BauOrStressed,
  buildBauChart,
  buildStressedChart,
  colNamesBAU,
  colNamesStressed,
} from '../data/shared.data';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { percentageToTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';
import { colorsArray } from '../../../../../../styling/theme';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import GenericLineChart from '../../../../../charts/GenericLineChart';
import { clientNameSelector } from '../../../../../../redux/auth/selectors';
import { useSelector } from 'react-redux';
import {
  BauDescriptionNamesWcapital,
  StressedDescriptionNamesWcapital,
} from '../subComponents/WcapitalCustomGraphs.component';
import { Typography } from '@mui/material';

interface Props {
  bauOrStressed: BauOrStressed;
  liquidationData: DataObject;
  pdfDontmoveToNewPage?: boolean;
  pdfAligment?: 'center' | 'left' | 'right';
  pdfDontIncrementYPosition?: boolean;
}

function LiquidationTimeCostChart({
  bauOrStressed,
  liquidationData,
  pdfDontmoveToNewPage,
  pdfAligment,
  pdfDontIncrementYPosition,
}: Props): ReactElement {
  const { data, isFetching, error } = liquidationData;

  const clientName = useSelector(clientNameSelector);

  const chartData =
    bauOrStressed === 'bau' ? buildBauChart(data) : buildStressedChart(data);

  let colNames = bauOrStressed === 'bau' ? colNamesBAU : colNamesStressed;

  if (clientName === 'wcapital' && bauOrStressed === 'bau') {
    colNames = BauDescriptionNamesWcapital;
  } else if (clientName === 'wcapital' && bauOrStressed === 'stressed') {
    colNames = StressedDescriptionNamesWcapital;
  }

  const { height } = useWindowSize();

  const formatTooltip = (value: number) =>
    percentageToTwoDecimalPlaces(value / 100);
  return (
    <GridItem
      xs={12}
      xl={6}
      card
      loading={isFetching}
      cardStyle={{ textAlign: 'center' }}
    >
      <Typography
        variant="h3"
        style={{ fontSize: '2.4rem', paddingTop: '2.5rem', fontWeight: 300 }}
      >
        {bauOrStressed === 'bau'
          ? 'Scenario Redemption Costs: BAU'
          : 'Scenario Redemption Costs: Stressed'}
      </Typography>
      {chartData.length > 0 && (
        <GenericLineChart
          loading={isFetching}
          error={error}
          width={'90%'}
          height={height * 0.45}
          tooltipFormatter={formatTooltip}
          yAxisTicks={[0, 20, 40, 60, 80, 100]}
          data={chartData}
          lines={colNames.map((colName: string, index: number) => ({
            dataKey: colName,
            color: colorsArray[index],
          }))}
          title=""
          pdfTitle={
            bauOrStressed === 'bau'
              ? 'Scenario Redemption Costs: BAU'
              : 'Scenario Redemption Costs: Stressed'
          }
          margin={{ top: 30, left: 0, right: 50, bottom: 30 }}
          legendWrapper={{ left: 30 }}
          showLegend={true}
          yAxisLabel={'%'}
          legendVAlign="top"
          id={`${bauOrStressed}_chart`}
          pdfDontmoveToNewPage={pdfDontmoveToNewPage}
          pdfAligment={pdfAligment}
          pdfDontIncrementYPosition={pdfDontIncrementYPosition}
        />
      )}
    </GridItem>
  );
}

export default LiquidationTimeCostChart;
