import { UnfoldLess } from '@mui/icons-material';
import { IFundTableData } from './CoverageRatioFundsTable.component';
import CoverageRatioSecondLevelTable from './SecondLevelTable.component';

const CoverageRatioSecondLevelTableWrapper = () =>
  // current: boolean,
  // activeFund: { fundId: string; fundName: string }
  [
    (rowData: IFundTableData) => {
      const render = () => (
        <CoverageRatioSecondLevelTable
          fundId={rowData.fundId}
          title={'Breakdown'}
          underlyingAssets={rowData.underlyingAssets}
        />
      );
      return {
        openIcon: UnfoldLess,
        render,
        disabled: !rowData.underlyingAssets.length,
      };
    },
  ];

export default CoverageRatioSecondLevelTableWrapper;
