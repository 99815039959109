import { ReactElement } from 'react';
import { Page, Section } from '../../../../types/server-data/ClientConfig';
import SidebarItem from './SidebarItem';
import { activePageAreaSelector } from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { Collapse, List } from '@mui/material';
// import SidebarItem from './SidebarItem';
// import { PageIdentifier } from '../../../../../redux/pages/pagesDirectory/types.pagesDirectory';
// import { PagesByKey } from '../../../../../redux/pages/pagesDirectory/interfaces';
// import { DashboardPageIdentifier } from '../../../../../redux/pages/pagesDirectory/dashboard/dashboard.pageDirectoryEntries';

interface Props {
  iconsVisible: boolean;
  currentPages: Page[];
  currentSection: Section;
}

const PageList = ({
  iconsVisible,
  currentPages,
  currentSection,
}: Props): ReactElement => {
  const activePageArea = useSelector(activePageAreaSelector);
  return (
    <Collapse in={iconsVisible} timeout="auto" unmountOnExit>
      <List component="div">
        {currentPages
          .filter((page) => page.pageArea === activePageArea)
          .map((page: Page) => (
            <SidebarItem
              key={page.pageKey}
              page={page}
              section={currentSection}
            />
          ))}
      </List>
    </Collapse>
  );
};

export default PageList;
