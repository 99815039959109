import React from 'react';
import { mainColors } from '../styling/theme';

function RsSwipe({ className }: { className: string }) {
  return (
    <svg
      className={className}
      width="901"
      height="965"
      viewBox="0 0 901 965"
      fill="none"
      preserveAspectRatio="xMaxYMax meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="fill-swipe">
          <stop offset="0" stopColor={mainColors.mainBlue}>
            <animate
              dur="1.5"
              attributeName="offset"
              fill="freeze"
              from="0"
              to="1"
            />
          </stop>
          <stop offset="0" stopColor={'white'}>
            <animate
              dur="1.5"
              attributeName="offset"
              fill="freeze"
              from="0"
              to="1"
            />
          </stop>
        </linearGradient>
      </defs>
      <path
        d="M348.027 603.636C394.017 534.184 434.132 464.463 472.661 397.25L475.756 391.851L475.756 391.85C512.719 327.362 548.91 264.218 586.294 209.437C661.892 98.659 756.329 0.900909 900.887 0.900879V137.099C828.505 137.099 769.635 182.4 698.793 286.209C664.465 336.512 630.582 395.622 592.378 462.27L590.822 464.984C552.47 531.889 510.326 605.226 461.584 678.833C396.972 776.406 319.929 875.795 221.841 965H0.0170898C151.814 866.158 259.835 736.817 348.027 603.636Z"
        fill="url(#fill-swipe)"
      />
    </svg>
  );
}

export default RsSwipe;
