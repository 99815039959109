import {
  addCommasToNumbersAndRound,
  toTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';

export interface IExceedanceTableData {
  t: string;
  tplusone: string;
  pxt: string;
  ptplusone: string;
  return: string;
  nav: string | number;
  pdivl: string;
  vart: string;
  ratio: string;
}
export const csvExceedanceTable = [
  't',
  'tplusone',
  'pxt',
  'ptplusone',
  'return',
  'nav',
  'pdivl',
  'vart',
  'ratio',
];

export const exceedanceColumns: CustomColumn<IExceedanceTableData>[] = [
  {
    title: 'T',
    field: 't',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'T+1',
    field: 'tplusone',

    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Px(T)',
    field: 'pxt',
    render: (rowData: IExceedanceTableData) => toTwoDecimalPlaces(rowData.pxt),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  { title: 'P(T+1)', field: 'ptplusone' },
  { title: 'Return(T+1)', field: 'return' },
  { title: 'NAV(T)', field: 'nav' },
  { title: 'P/L(T+1)', field: 'pdivl' },
  { title: 'VaR(T)', field: 'vart' },
  { title: 'P/L-VaR Ratio', field: 'ratio' },
];

export function buildExceedanceData(inputData: any) {
  if (!inputData || !inputData.length) return [];

  const { exceedance_data } = inputData[0];
  exceedance_data.shift();
  const returnedData = exceedance_data
    .map((instance: any, index: number) => {
      return {
        t: instance[0],
        tplusone: instance[1],
        pxt: instance[2],
        ptplusone: instance[3],
        return: instance[4],
        nav: addCommasToNumbersAndRound(instance[5]),
        pdivl: addCommasToNumbersAndRound(instance[6]),
        vart: addCommasToNumbersAndRound(instance[7]),
        ratio: instance[8],
      };
    })
    .filter((el: any) => {
      return el !== undefined;
    });

  return returnedData;
}
