import React from 'react';
function RaptorWithRs({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="263"
      height="92"
      viewBox="0 0 263 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        style={{ maskType: 'alpha' }}
        // mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="13"
      >
        <rect
          x="0.00976562"
          y="0.793213"
          width="27.0835"
          height="11.9822"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="0.479248"
          y="0.532806"
          width="30.5592"
          height="13.4759"
          fill="#ED8B00"
        />
        <path
          d="M13.4234 2.83261C17.9763 2.83261 17.0478 13.5384 30.1174 13.5384M13.4234 2.83261C8.87053 2.83261 9.65931 13.5384 -3.27051 13.5384M13.4234 2.83261V13.0286"
          stroke="white"
          strokeWidth="1.43682"
        />
        <path
          d="M9.4772 6.50208V13.3277H2.92236L9.4772 6.50208Z"
          fill="white"
        />
      </g>
      <path
        d="M36.3377 4.97744C35.9326 4.69062 35.4259 4.57217 35.0204 4.57177C34.1251 4.57086 33.4827 5.00826 32.8571 5.56361L32.5876 4.75464L30.4252 4.75246L30.4172 12.7215L32.9681 12.7241L32.9736 7.24851C33.5315 6.82788 33.8864 6.7103 34.3594 6.71078C34.5622 6.71098 34.9844 6.76195 35.4403 7.04883L36.3377 4.97744Z"
        fill="#012169"
      />
      <path
        d="M40.4978 2.35337C40.4986 1.52782 39.8404 0.903789 38.9788 0.90292C38.1172 0.902052 37.4577 1.55845 37.4569 2.3503C37.4561 3.17585 38.1143 3.81673 38.9759 3.8176C39.8374 3.81847 40.497 3.14522 40.4978 2.35337ZM40.2339 12.7314L40.2419 4.76236L37.691 4.75978L37.683 12.7288L40.2339 12.7314Z"
        fill="#012169"
      />
      <path
        d="M48.7318 10.3476C48.7346 7.51712 44.9501 7.90081 44.951 6.99103C44.9513 6.68776 45.2218 6.51956 45.8468 6.52019C46.7253 6.52107 47.5528 6.77463 48.3127 7.07865L48.3148 4.98952C47.6224 4.7361 46.8624 4.58371 45.7474 4.58258C43.7709 4.58059 42.486 5.57332 42.4844 7.10647C42.4817 9.78528 46.1987 9.33414 46.1977 10.4124C46.1973 10.8167 45.7747 11.0353 45.0483 11.0346C44.305 11.0339 43.4943 10.7635 42.7515 10.2404L41.9726 12.0592C42.783 12.5823 43.8976 12.9036 45.3336 12.905C47.0567 12.9068 48.7299 12.2177 48.7318 10.3476Z"
        fill="#012169"
      />
      <path
        d="M58.3561 12.7497L55.0495 8.31534L58.1784 4.78044L55.3233 4.77756L53.0063 7.42034L52.9725 7.42031L52.9791 0.81595L50.4282 0.813378L50.4162 12.7417L52.9671 12.7442L52.9705 9.32412L53.0043 9.32415L55.4505 12.7467L58.3561 12.7497Z"
        fill="#012169"
      />
      <path
        d="M64.7779 10.5322C64.7806 7.87026 61.0636 8.30456 61.0652 6.70401C61.0658 6.06379 61.6574 5.79482 62.4345 5.79561C63.2792 5.79646 63.904 6.03296 64.495 6.28627L64.4963 5.00584C63.939 4.7694 63.2804 4.58341 62.385 4.58251C60.5267 4.58064 59.5966 5.55687 59.5954 6.76992C59.5926 9.54982 63.2422 8.91328 63.2404 10.6823C63.2398 11.3562 62.5974 11.7599 61.6683 11.759C60.8067 11.7581 60.0975 11.4204 59.6586 11.1336L59.1168 12.2618C59.6908 12.6162 60.4676 12.9203 61.684 12.9215C63.3396 12.9232 64.7762 12.2339 64.7779 10.5322Z"
        fill="#012169"
      />
      <path
        d="M73.7593 4.79615L72.2727 4.79465L70.1716 11.0262L70.1209 11.0262L67.3904 4.78973L65.8024 4.78813L69.4096 12.7945C68.8163 14.8325 67.954 15.5561 67.0248 15.5552C66.5349 15.5547 66.1634 15.4532 65.8257 15.2844L65.4528 16.4297C65.8919 16.6154 66.3648 16.7507 67.0574 16.7514C68.4089 16.7528 69.6595 16.2654 70.6929 13.3686L73.7593 4.79615Z"
        fill="#012169"
      />
      <path
        d="M80.3048 10.5479C80.3075 7.88592 76.5904 8.32021 76.592 6.71967C76.5927 6.07945 77.1842 5.81048 77.9613 5.81126C78.806 5.81211 79.4308 6.04861 80.0219 6.30193L80.0231 5.02149C79.4659 4.78506 78.8072 4.59907 77.9119 4.59817C76.0536 4.59629 75.1235 5.57253 75.1222 6.78558C75.1194 9.56547 78.7691 8.92893 78.7673 10.698C78.7666 11.3719 78.1243 11.7756 77.1951 11.7746C76.3335 11.7738 75.6243 11.4361 75.1854 11.1492L74.6437 12.2775C75.2177 12.6319 75.9945 12.9359 77.2108 12.9372C78.8664 12.9388 80.3031 12.2495 80.3048 10.5479Z"
        fill="#012169"
      />
      <path
        d="M86.9475 12.1383L86.5093 11.127C86.188 11.3625 85.664 11.6484 85.1235 11.6478C84.5998 11.6473 84.3467 11.3438 84.3473 10.6867L84.352 6.00303L86.3286 6.00503L86.8197 4.80932L84.3532 4.80683L84.3555 2.54922L82.8515 3.01945L82.8497 4.80532L81.5151 4.80397L81.5139 6.00017L82.8485 6.00152L82.8433 11.1907C82.8422 12.2352 83.399 12.9434 84.6491 12.9447C85.3924 12.9454 86.2712 12.6262 86.9475 12.1383Z"
        fill="#012169"
      />
      <path
        d="M94.8914 9.11367C94.8916 8.96204 94.8917 8.82725 94.8918 8.72617C94.8943 6.24953 93.6796 4.61406 91.5848 4.61195C89.5407 4.60989 88.2043 6.39442 88.202 8.68573C88.1997 10.9096 89.3971 12.9494 91.9987 12.9521C92.9279 12.953 93.9587 12.6508 94.6518 12.1292L94.2305 11.1347C93.6896 11.4543 92.9798 11.6895 92.2872 11.6888C90.6823 11.6872 89.7712 10.5237 89.7726 9.10851L94.8914 9.11367ZM93.3046 7.94957L89.7569 7.94599C89.7581 6.80033 90.4517 5.82386 91.5667 5.82498C92.7323 5.82616 93.3056 6.92185 93.3046 7.94957Z"
        fill="#012169"
      />
      <path
        d="M108.542 12.8003L108.548 7.20678C108.55 5.25243 107.351 4.62785 106.084 4.62657C105.172 4.62565 104.09 5.11315 103.194 5.92094C102.891 5.14564 102.165 4.62262 101.185 4.62163C100.306 4.62075 99.3599 4.9736 98.3624 5.74759L98.1775 4.82077L96.9274 4.81951L96.9194 12.7886L98.4229 12.7901L98.4287 7.04495C99.0205 6.52326 99.7813 5.93435 100.744 5.93532C101.775 5.93636 101.994 6.71158 101.993 7.70561L101.987 12.7937L103.491 12.7952L103.496 7.43756C103.497 7.30277 103.497 7.15114 103.48 7.03319C104.072 6.52835 104.849 5.93946 105.812 5.94043C106.843 5.94147 107.045 6.68298 107.044 7.677L107.039 12.7988L108.542 12.8003Z"
        fill="#012169"
      />
      <path
        d="M63.5781 24.5052H80.4628C85.8319 24.5052 90.0237 25.7612 93.0379 28.2733C96.0522 30.7853 97.5594 34.577 97.5594 39.6485C97.5594 46.1418 94.7099 50.763 89.0111 53.512L100.103 74.2718H91.1305L81.3105 55.2894H80.9573H71.4906V74.2718H63.5781V24.5052ZM79.4031 48.5354C82.7941 48.5354 85.3139 47.777 86.9623 46.2603C88.6578 44.6962 89.5056 42.5634 89.5056 39.8618C89.5056 37.0653 88.6814 34.9325 87.0329 33.4632C85.3845 31.9465 82.8883 31.1882 79.5444 31.1882H71.4906V48.5354H79.4031Z"
        fill="#012169"
      />
      <path
        d="M113.059 74.9827C109.998 74.9827 107.478 74.0348 105.5 72.1389C103.569 70.2431 102.603 67.7547 102.603 64.6739C102.603 61.5932 103.663 59.1285 105.783 57.2801C107.949 55.4316 110.563 54.1282 113.624 53.3698C116.686 52.5641 119.794 52.1375 122.95 52.0901C122.903 49.3885 122.314 47.54 121.184 46.5447C120.1 45.502 118.334 44.9806 115.885 44.9806C114.378 44.9806 112.871 45.2176 111.364 45.6916C109.904 46.1181 108.279 46.8765 106.489 47.9666L105.641 41.9235C107.384 40.8334 109.291 40.0039 111.364 39.4352C113.483 38.819 115.65 38.511 117.863 38.511C122.196 38.511 125.352 39.6959 127.33 42.0657C129.308 44.4355 130.297 48.4643 130.297 54.1519V62.3278C130.297 64.1763 130.368 65.5508 130.509 66.4513C130.697 67.3519 131.027 67.9917 131.498 68.3709C132.016 68.7501 132.817 68.9396 133.9 68.9396H134.748L133.9 74.9827H132.275C129.826 74.9827 127.966 74.6036 126.694 73.8452C125.47 73.0869 124.48 71.9019 123.727 70.2905C122.455 71.7598 120.901 72.921 119.064 73.7741C117.227 74.5799 115.226 74.9827 113.059 74.9827ZM115.32 69.1529C116.686 69.1529 118.052 68.8212 119.417 68.1576C120.83 67.494 122.008 66.5698 122.95 65.3849V57.1379C114.472 57.4222 110.233 59.8158 110.233 64.3185C110.233 67.5414 111.929 69.1529 115.32 69.1529Z"
        fill="#012169"
      />
      <path
        d="M157.657 38.511C160.813 38.511 163.545 39.3167 165.852 40.9282C168.207 42.5397 169.997 44.7436 171.222 47.54C172.446 50.289 173.058 53.3461 173.058 56.7113C173.058 60.1239 172.47 63.2283 171.292 66.0247C170.115 68.7738 168.372 70.954 166.064 72.5655C163.757 74.177 160.954 74.9827 157.657 74.9827C153.089 74.9827 149.557 73.6556 147.06 71.0014V91.4768H139.713V39.1508H145.506L146.354 43.2743C149.274 40.0987 153.042 38.511 157.657 38.511ZM156.386 68.6553C159.259 68.6553 161.496 67.5888 163.097 65.456C164.746 63.3231 165.57 60.4082 165.57 56.7113C165.57 53.0617 164.746 50.1705 163.097 48.0377C161.449 45.9048 159.212 44.8384 156.386 44.8384C153.419 44.8384 151.064 45.8811 149.321 47.9666C147.578 50.0521 146.707 52.967 146.707 56.7113C146.707 60.5978 147.555 63.5601 149.25 65.5982C150.993 67.6362 153.371 68.6553 156.386 68.6553Z"
        fill="#012169"
      />
      <path
        d="M190.765 74.9827C187.704 74.9827 185.396 74.1533 183.842 72.4944C182.335 70.7881 181.581 68.7027 181.581 66.238V45.0517H176L176.636 39.1508H181.581V30.8327L188.929 30.1217V39.1508H197.477V45.0517H188.929V62.47C188.929 64.6502 189.046 66.1669 189.282 67.0201C189.564 67.8258 190.106 68.3472 190.907 68.5842C191.707 68.8211 193.215 68.9396 195.428 68.9396H197.336L196.77 74.9827H190.765Z"
        fill="#012169"
      />
      <path
        d="M218.381 74.9827C214.754 74.9827 211.599 74.177 208.914 72.5655C206.277 70.9066 204.251 68.7027 202.838 65.9537C201.473 63.1572 200.79 60.0765 200.79 56.7113C200.79 53.3461 201.473 50.289 202.838 47.54C204.251 44.7436 206.277 42.5397 208.914 40.9282C211.599 39.2693 214.754 38.4399 218.381 38.4399C221.96 38.4399 225.069 39.2693 227.706 40.9282C230.391 42.5397 232.439 44.7436 233.852 47.54C235.265 50.289 235.972 53.3461 235.972 56.7113C235.972 60.0765 235.265 63.1572 233.852 65.9537C232.439 68.7027 230.391 70.9066 227.706 72.5655C225.069 74.177 221.96 74.9827 218.381 74.9827ZM218.381 68.6553C221.583 68.6553 224.056 67.5888 225.799 65.456C227.541 63.3231 228.413 60.4082 228.413 56.7113C228.413 53.0143 227.541 50.0994 225.799 47.9666C224.056 45.8337 221.583 44.7673 218.381 44.7673C215.178 44.7673 212.705 45.8337 210.963 47.9666C209.22 50.0994 208.349 53.0143 208.349 56.7113C208.349 60.4082 209.22 63.3231 210.963 65.456C212.705 67.5888 215.178 68.6553 218.381 68.6553Z"
        fill="#012169"
      />
      <path
        d="M242.303 39.1508H248.803L249.297 44.0564C252.406 40.312 255.655 38.4399 259.046 38.4399C259.564 38.4399 260.106 38.4873 260.671 38.582L260.035 46.3314C259.282 46.1418 258.434 46.047 257.492 46.047C255.891 46.047 254.384 46.4262 252.971 47.1846C251.558 47.9429 250.545 48.8197 249.933 49.8151V74.2718H242.303V39.1508Z"
        fill="#012169"
      />
      <mask
        id="mask1"
        // mask-type="alpha"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="24"
        width="52"
        height="51"
      >
        <path
          d="M0.00976562 24.5877H51.0766V74.498H0.00976562V24.5877Z"
          fill="url(#paint0_linear)"
        />
      </mask>
      <g mask="url(#mask1)">
        <rect
          x="-7.02515"
          y="21.0235"
          width="58.3621"
          height="57.0403"
          fill="#012169"
        />
        <path
          d="M32.2305 84.5974V48.4781C9.58285 73.0225 -14.5808 84.5974 -14.5808 84.5974H32.2305Z"
          fill="#ED8B00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3898 70.6517C6.87085 75.8152 -4.13737 79.1598 -18.8364 79.1597L-18.8364 73.0302C-5.16603 73.0302 4.67016 69.9365 12.0844 65.4425C19.5257 60.9322 24.738 54.8844 28.9623 48.6496C31.08 45.524 32.9272 42.3862 34.7014 39.3613L34.8441 39.118C36.546 36.2158 38.2125 33.3742 39.9339 30.9088C43.415 25.9233 47.7637 21.5237 54.4202 21.5237C61.088 21.5237 65.3979 25.9367 68.8319 30.9257C70.5126 33.3674 72.1379 36.1791 73.7975 39.0501L73.9868 39.3777C75.735 42.4012 77.5578 45.5371 79.6551 48.66C83.838 54.8884 89.0282 60.9317 96.493 65.4399C103.933 69.9333 113.846 73.0302 127.677 73.0302L127.677 79.1597C112.831 79.1598 101.747 75.8184 93.1962 70.6543C84.6703 65.5051 78.8769 58.6718 74.4121 52.0235C72.1855 48.7081 70.2684 45.4057 68.5267 42.3933L68.3998 42.1738C66.6875 39.212 65.1694 36.5861 63.6282 34.3471C61.4811 31.2276 59.5753 29.2883 57.556 28.3448V73.6423H51.2844V28.3394C49.2398 29.2838 47.3008 31.2323 45.1142 34.3639C43.5335 36.6278 41.9733 39.288 40.2141 42.2875L40.1424 42.4097C38.3764 45.4207 36.4358 48.7212 34.1913 52.0339C29.6912 58.6758 23.8816 65.5046 15.3898 70.6517Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.00976562"
          y1="24.5877"
          x2="49.907"
          y2="75.6411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8049" />
          <stop offset="1" stopColor="#FF8205" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default RaptorWithRs;
