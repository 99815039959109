import React from 'react';

interface DatepickerProps {
  initialDate: string | null;
  availableDates: string[];
}

const Datepicker: React.FC<DatepickerProps> = ({
  initialDate,
  availableDates,
}) => {
  // Implement the Datepicker component logic here

  return (
    <div>
      {/* <RaptorDatePicker
        title={'Select Date'}
        selectedDate={dayjs(initialDate)}
        datesToInclude={availableDates}
        handler={() => console.log('Datepicker handler')}
        tiedToActiveDate={false}
      /> */}
    </div>
  );
};

export default Datepicker;
