import { Dispatch, ReactElement } from 'react';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { greys, mainColors } from '../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Theme } from '@mui/material';

interface Props {
  percentValueOption: string;
  // This will be the function used to update the percentValue option depending on what is clicked.
  updatePercentValueOption: Dispatch<string>;
}

interface StyleProps {
  percentValueOption: string;
}

const useButtonStyles = makeStyles<Theme, StyleProps>((theme) => ({
  containedPrimary: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &:focus': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  bottomLabel: {
    transition: 'all .2s',
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  percentageAvatar: {
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: (props) =>
      props.percentValueOption === 'percent'
        ? mainColors.mainBlue
        : greys.grey400,
    border: `1px solid ${mainColors.mainBlue}`,
    color: (props) =>
      props.percentValueOption === 'percent' ? 'white' : mainColors.mainBlue,
    marginRight: '0.5rem',
  },
  valueAvatar: {
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: (props) =>
      props.percentValueOption === 'value'
        ? mainColors.mainBlue
        : greys.grey400,
    border: `1px solid ${mainColors.mainBlue}`,
    color: (props) =>
      props.percentValueOption === 'value' ? 'white' : mainColors.mainBlue,
  },
}));

function PercentValueToggleButton({
  percentValueOption,
  updatePercentValueOption,
}: Props): ReactElement {
  const classes = useButtonStyles({ percentValueOption });
  return (
    <div className={classes.buttonContainer}>
      <Avatar
        className={classes.percentageAvatar}
        onClick={() => {
          updatePercentValueOption('percent');
        }}
      >
        <PercentIcon />
      </Avatar>
      <Avatar
        className={classes.valueAvatar}
        onClick={() => {
          updatePercentValueOption('value');
        }}
      >
        <AttachMoneyIcon />
      </Avatar>
    </div>
  );
}

export default PercentValueToggleButton;
