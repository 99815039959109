import { FC, ReactNode } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';

const TableWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <GridItem
      card
      xs={12}
      xl={6}
      cardStyle={{ height: '100%' }}
      style={{ display: 'flex', alignSelf: 'stretch' }}
    >
      {children}
    </GridItem>
  );
};

export default TableWrapper;
