import React, { ReactElement } from 'react';
import { buildEvolutionChart } from '../portfolioLiquidation/portfolioLiquidation.data';
import { mainColors } from '../../../../../../styling/theme';
import withChartWrapper from '../withChartWrapper';
import {
  roundNumberToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';
import { Dimensions } from '../portfolioLiquidation/portfolioLiquidation.component';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GenericLineChart from '../../../../../charts/GenericLineChart';

interface Props extends Dimensions {
  inputData: DataObject;
  height: number;
  // margin: {top: number, left: number, bottom: number, right: number}
}

function MaximumOneDay({
  inputData: { isFetching, data, error },
  height,
}: Props): ReactElement {
  const chartData = buildEvolutionChart(data, 'q2');
  const formatter = (value: number, name: string) => [
    `${toTwoDecimalPlaces(value)}%`,
    name === 'moabOne' ? 'MOAB: 1' : 'MOAB: 0',
  ];
  // const axisFormatter = (value: number) => Math.round(value);
  // Create arrays of the MOAB1 and MOAB0 data
  const moab0 = chartData.map((x: any) => x.moabZero);
  const moab1 = chartData.map((x: any) => x.moabOne);
  // combine into one array
  const moabData = moab0.concat(moab1);
  // Get the min and max value
  // const minPrice = sharePrices.reduce((a: number, b: number) => Math.min(a, b));
  const maxMoab = moabData.reduce((a: number, b: number) => Math.max(a, b));
  const minMoab = moabData.reduce((a: number, b: number) => Math.min(a, b));
  // If range value is less than 10 then format the y axis to 2 decimal places else to no decimals
  const axisFormatter =
    maxMoab - minMoab >= 6
      ? (value: number) => Math.round(value)
      : (value: number) => roundNumberToTwoDecimalPlaces(value);
  return (
    <>
      <GenericLineChart
        loading={isFetching}
        width="90%"
        export
        error={error}
        margin={{ top: 10, left: 0, right: 10, bottom: 10 }}
        title="Maximum One-Day Available Balance"
        showTitle
        yAxisTickFormatter={axisFormatter}
        yAxisLabel={{
          style: { textAnchor: 'middle' },
          value: '% of NAV',
          angle: -90,
          position: 'insideLeft',
        }}
        legendFormatter={(value: any, entry: any) => {
          const { color } = entry;
          let name;
          if (value === 'moabZero') {
            name = 'MOAB 0';
          } else if (value === 'moabOne') {
            name = 'MOAB 1';
          }

          return <span style={{ color }}>{name}</span>;
        }}
        showLegend
        download
        height={height}
        data={chartData}
        tooltipFormatter={formatter}
        lines={[
          {
            dataKey: 'moabZero',
            color: mainColors.mainBlue,
          },
          {
            dataKey: 'moabOne',
            color: mainColors.Alert,
          },
        ]}
      />
    </>
  );
}

export default withChartWrapper(MaximumOneDay);
