import { UnfoldLess } from '@mui/icons-material';
import LiquidationTimeSecondLevelTable from './LiquidationTimeSecondLevelTable.component';
import { LiquidityData } from './LiquidationTimeTable.component';

const LiquidationTimeSecondLevelTableWrapper = () => [
  (rowData: LiquidityData) => {
    const render = () => (
      <LiquidationTimeSecondLevelTable data={rowData.funds} />
    );
    return {
      disabled: !rowData.funds.length,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default LiquidationTimeSecondLevelTableWrapper;
