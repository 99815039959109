import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { RaptorTheme, greys } from '../../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import ESGChart from '../../esgExposure/esgExposureComponents/ESGChart';
import GeneralSelect from '../../../../selects/GeneralSelect';
import useFetchData from '../../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import HistoricalScoresLineChart from './HistoricalScoresLineChart.component';
import { Typography } from '@mui/material';

interface ESGOverviewPanelProps {
  data: any;
  fundId: string | null;
  fundName: string | null;
  positionDate?: string;
}

const useOverviewPanelStyles = makeStyles<RaptorTheme>((theme) => ({
  componentsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
  chartContainer: {
    width: '60%',
  },
  scoreContainer: {
    width: '20%',
    justifyContent: 'center',
  },
  basicInfoContainer: {
    width: '20%',
    justifyContent: 'center',
  },
  fundInfoLine: {
    display: 'flex',
  },
  individualChart: {
    marginBottom: '1rem',
    height: '20rem',
    width: '20rem',
    justifyContent: 'center',
  },
  generalSelectContainer: {
    position: 'relative',
    // height: 500,
    width: '80%',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
}));

function totalProxified(data: any): string {
  if (!data.length) return `${0}/${data.length}`;

  let count = 0;
  data.forEach((pos: any) => {
    if (pos.uses_proxy === 'yes') {
      count++;
    }
  });

  return `${count}/${data.length}`;
}

export function esgVar(data: any): number | null {
  if (!data.data.length) return null;
  if (!data.data[0].esg_risk_metrics) return null;
  // check if esg_risk_metrics is an empty object
  if (Object.keys(data.data[0].esg_risk_metrics).length === 0) return null;
  // check if esg_risk_metrics is an empty object
  if (
    Object.keys(data.data[0].esg_risk_metrics.implied_historical_ts_data)
      .length === 0
  )
    return null;
  if (data.data[0].esg_risk_metrics === 'disabled_module') return null;
  return data.data[0].esg_risk_metrics.implied_historical_ts_data.esg_data.var;
}
function esgVol(data: any): number | null {
  if (!data.data.length) return null;
  if (!data.data[0].esg_risk_metrics) return null;
  // check if esg_risk_metrics is an empty object
  if (Object.keys(data.data[0].esg_risk_metrics).length === 0) return null;
  // check if esg_risk_metrics is an empty object
  if (
    Object.keys(data.data[0].esg_risk_metrics.implied_historical_ts_data)
      .length === 0
  )
    return null;
  if (data.data[0].esg_risk_metrics === 'disabled_module') return null;
  return data.data[0].esg_risk_metrics.implied_historical_ts_data.esg_data.std;
}

export type ESGScoreKey = 'ESG_Score' | 'E_Score' | 'S_Score' | 'G_Score';

// FUnction for getting the fund esg score from the data
function getEsgScore(data: any): number {
  if (!data) return 0;
  if (!data.data.length) return 0;
  if ('no data' in data.data[0]) return 0;
  // Check the version number before returning
  const esgData = data.data[0];
  if (esgData.version === 2) return esgData['portfolio_scores']['gross']['esg'];
  else if (esgData.version === 3)
    return esgData['portfolio_scores']['ESG_Score'];
  else return esgData['portfolio_scores']['gross']['esg'];
}

const ESGPortfolioOverviewPanel: FC<ESGOverviewPanelProps> = ({
  data,
  fundId,
  fundName,
  positionDate,
}) => {
  const classes = useOverviewPanelStyles();

  const [scoreType, setScoreType] = useState<ESGScoreKey>('ESG_Score');

  const esgScore = getEsgScore(data);

  const timeSeriesData = useFetchData({
    url: `/get_fund_esg_timeseries/${fundId}/${positionDate}`,
    keyName: `esg_timeseries_data_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GridItem xs={12} card>
      <div className={classes.componentsContainer}>
        <div className={classes.basicInfoContainer}>
          <Typography variant="h2" align="left">
            Fund Information
          </Typography>
          <div className={classes.fundInfoLine}>
            <Typography variant="h3">Fund Name: </Typography>
            <Typography
              variant="h3"
              style={{
                color: greys.grey600,
                marginLeft: '1rem',
                marginBottom: '0.5rem',
              }}
            >
              {fundName}
            </Typography>
          </div>
          <div className={classes.fundInfoLine}>
            <Typography variant="h3">NAV: </Typography>
            <Typography
              variant="h3"
              style={{
                color: greys.grey600,
                marginLeft: '1rem',
                marginBottom: '0.5rem',
              }}
            >
              {addCommasToNumbersAndRound(data.data[0]['nav'])}
            </Typography>
          </div>
          <div className={classes.fundInfoLine}>
            <Typography variant="h3">Total Proxified Positions: </Typography>
            <Typography
              variant="h3"
              style={{
                color: greys.grey600,
                marginLeft: '1rem',
                marginBottom: '0.5rem',
              }}
            >
              {totalProxified(data.data[0].positions)}
            </Typography>
          </div>
          {data.data[0].esg_subscription_package !==
          'esg_premium_package' ? null : (
            <>
              <Typography
                variant="h2"
                align="left"
                style={{ marginTop: '2rem' }}
              >
                Historical ESG Volatility
              </Typography>
              <div className={classes.fundInfoLine}>
                <Typography variant="h3">ESG Volatility: </Typography>
                <Typography
                  variant="h3"
                  style={{
                    color: greys.grey600,
                    marginLeft: '1rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  {esgVol(data)
                    ? percentageToTwoDecimalPlacesNoHundred(esgVol(data))
                    : '-'}
                </Typography>
              </div>
              <div className={classes.fundInfoLine}>
                <Typography variant="h3">ESG VaR: </Typography>
                <Typography
                  variant="h3"
                  style={{
                    color: greys.grey600,
                    marginLeft: '1rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  {esgVar(data)
                    ? percentageToTwoDecimalPlacesNoHundred(esgVar(data))
                    : '-'}
                </Typography>
              </div>
            </>
          )}
          <div
            className={classes.generalSelectContainer}
            style={{
              marginTop: '2rem',
            }}
          >
            <GeneralSelect
              labels={[
                'ESG',
                'Environmental',
                'Social',
                'Corporate Governance',
              ]}
              values={['ESG_Score', 'E_Score', 'S_Score', 'G_Score']}
              selected={scoreType}
              setSelected={setScoreType}
              placeholderValue=""
              placeholderColor={greys.grey900}
              selectTitle={'Chart Score Type'}
              customStyles={{
                pickerRoot: classes.pickerRoot,
                selectRoot: classes.selectRoot,
              }}
            />
          </div>
        </div>

        <div className={classes.chartContainer}>
          <GeneralComponentErrorShield
            dataObjects={[timeSeriesData]}
            setLoadingHeight={'45rem'}
            customLoadingMessages={['Loading Historical Data...']}
            customErrorMessage={'No Historical Data Found'}
          >
            <HistoricalScoresLineChart
              data={timeSeriesData}
              scoreType={scoreType}
            />
          </GeneralComponentErrorShield>
        </div>

        <div className={classes.scoreContainer}>
          <div
            style={{
              height: '25rem',
              width: '25rem',
              display: 'flex-column',
              marginLeft: '4rem',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h1"
              align="center"
              style={{ marginBottom: '2rem', fontWeight: 100 }}
            >
              ESG Score
            </Typography>
            <ESGChart value={esgScore} />
            <Typography
              variant="h2"
              align="center"
              style={{ marginTop: '2rem' }}
            >
              {roundNumberToTwoDecimalPlaces(esgScore)}
            </Typography>
            <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
              {' '}
              / 100.00
            </Typography>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default ESGPortfolioOverviewPanel;
