import React from 'react';
import { ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GenericAreaChart from '../../../charts/GenericAreaChart';
import GridItem from '../../../layout/GridComponents/GridItem';

interface LiquidityFactorChartProps {
  data: any;
}

function buildAreaChartData(data: any) {
  if (!data.data.length) return [];
  if (!('graph' in data.data[0])) return [];
  const graphData = data.data[0].graph;
  const areaChartData: any[] = [];
  graphData.forEach((value: any) => {
    areaChartData.push({
      name: value[0],
      LiquidityFactor: value[1],
    });
  });
  return areaChartData;
}

function LiquidityFactorChart({
  data,
}: LiquidityFactorChartProps): ReactElement {
  const chartData = buildAreaChartData(data);
  const areas = [
    {
      type: 'monotone',
      lineColor: mainColors.Pass,
      fillColor: mainColors.Pass,
      dataKey: 'LiquidityFactor',
    },
  ];
  return (
    <GridItem
      card
      xs={7}
      style={{
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <GenericAreaChart
        data={chartData}
        areas={areas}
        width={'100%'}
        height={420}
        title={'Portfolio - Historical Liquidity Discount'}
        margin={{ top: 10, right: 40, left: 0, bottom: 0 }}
        error={null}
        showTitle
        tooltipFormatter={(value: any, name: any, props: any) =>
          percentageToTwoDecimalPlaces(value)
        }
        yAxisTickFormatter={(value: any) => percentageToTwoDecimalPlaces(value)}
        download
        export
        id="liquidity_factor_analysis_area_chart"
        gradientFill
      />
    </GridItem>
  );
}

export default LiquidityFactorChart;
