const getUserDetails = () => {
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  return user_details;
};

const setUserDetails = (user_details) => {
  localStorage.setItem('user_details', JSON.stringify(user_details));
};

const UserDetailsService = {
  getUserDetails,
  setUserDetails,
};

export default UserDetailsService;
