import { FC } from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import DiscountCashflowOverviewTable from './DiscountCashflowOverviewTable.component';
import DiscountCashFlowOverviewPositionsTable from './DiscountCashflowOverviewPositionsTable.component';
import DiscountCashflowOverviewChart from './DiscountCashflowOverviewChart.component';
import { Grid } from '@mui/material';

const DiscountCashflowOverview: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const peOverviewData = useFetchData({
    url: `discount_cash_flow_overview/${fundId}/${positionDate}`,
    keyName: `discount_cash_flow_overview_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  return (
    <Grid container>
      <GeneralComponentErrorShield
        dataObjects={[]}
        customLoadingMessages={['Loading Private Equity Data...']}
        customErrorMessage="No Data Available."
      >
        <DiscountCashflowOverviewTable
          data={peOverviewData}
          selectedPosition={'Portfolio'}
        />
        <DiscountCashflowOverviewChart
          data={peOverviewData}
          selectedPosition={'Portfolio'}
        />
        <DiscountCashFlowOverviewPositionsTable data={peOverviewData} />
      </GeneralComponentErrorShield>
    </Grid>
  );
};

export default DiscountCashflowOverview;
