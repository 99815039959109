import { FC } from 'react';
import { RaptorTheme } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { AgTableData } from './AggregateExposureTable';
import CustomTable from '../../../tables/CustomTable';
import GeneralCard from '../../../general/GeneralCard';
import { useTheme } from '@mui/material';

export interface AssetGrossExposureProps {
  dataForRender: DataObject;
  fundId: string;
  fundName: string;
}

export interface IVerticalData {
  data: any;
  value: any;
}

export const useAssetGrossExposureColumns =
  (): CustomColumn<IVerticalData>[] => {
    const generalStyle = {
      fontSize: '1.6rem',
    };
    const theme: RaptorTheme = useTheme();

    return [
      { title: 'DAta', field: 'data', cellStyle: { ...generalStyle } },
      {
        title: 'Field',
        field: 'value',
        cellStyle: {
          ...generalStyle,
          textAlign: 'right',
          // borderRight: theme.borders!.light,
        },
      },
    ];
  };

function convert(val: number, nav: number) {
  return `${((val / nav) * 100).toFixed(2)}%`;
}

export function buildAssetGrossExposure(data: any) {
  const { derivative, cash } = data.asset_type_gross_exposure;

  const items = [
    {
      data: 'Cash Securities',
      value: `${((cash / data.nav) * 100).toFixed(2)}%`,
    },
    {
      data: 'Derivative Securities',
      value: `${((derivative / data.nav) * 100).toFixed(2)}%`,
    },
  ];
  return items;
}

const AssetGrossExposure: FC<AssetGrossExposureProps> = (props) => {
  const { fundId, fundName } = props;
  const { data } = props.dataForRender;
  const columns = useAssetGrossExposureColumns();
  const renderedData = !data.length ? [] : buildAssetGrossExposure(data[0]);

  return (
    <GeneralCard cardStyle={{ overflow: 'auto', marginBottom: '1.6rem' }}>
      <CustomTable<AgTableData>
        multipleTablePdfParameters={{
          specifiedWidth: 4,
          tablesInRow: 3,
          groupName: 'exposure_page',
          groupOrder: 2,
        }}
        id={`${fundId}_asset_class_gross_exposure`}
        title="Asset Gross Exposure"
        showToolbar={true}
        columns={columns}
        toolbarComponents={{
          toolbarTitle: 'Asset Gross Exposure',
        }}
        data={renderedData}
        options={{
          paging: false,
          search: false,
          header: false,
          exportButton: false,
        }}
        pdfDontMoveToNewPage
      />
    </GeneralCard>
  );
};

export default AssetGrossExposure;
