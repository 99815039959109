import { greys, mainColors } from '../../../styling/theme';
import { Page, Section } from '../../../types/server-data/ClientConfig';
import { useSelector } from 'react-redux';
import { activePageSelector } from '../../../redux/pages/selectors';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  link: {
    fontSize: '1.8rem',
    textDecoration: 'none',
    marginRight: '2.4rem',
    color: 'inherit',
    '& h2': {
      marginRight: '1rem',
    },
  },
  innerLink: {
    fontSize: '1.6rem',
    color: 'inherit',
  },
  navItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.8rem',
    paddingRight: '2.4rem',
    paddingLeft: 0,
    color: greys.grey500,
    '&:hover': {
      color: greys.grey800,
    },
  },
  navItem__active: {
    color: mainColors.mainBlue,
    '&:hover': {
      color: mainColors.mainBlue,
    },
  },
  innerIcon: {
    fontSize: '1.6rem',
  },
  navItem__title: {
    fontSize: '1.4rem',
    marginLeft: '1rem',
  },
});

type NavPageProps = {
  page: Page;
  section: Section;
  handleShowing: () => void;
  Icon: any;
};

const NavPage: React.FC<NavPageProps> = ({
  Icon,
  page,
  section,
  handleShowing,
}) => {
  const classes = useStyles();

  const activePage = useSelector(activePageSelector);

  const [isPageActive, setIsPageActive] = useState<boolean>(false);

  useEffect(() => {
    setIsPageActive(activePage === page.pageKey);
  }, [activePage, page.pageKey]);

  return (
    <Link
      onClick={handleShowing}
      to={'/' + section.sectionId + '/' + page.pageKey}
      key={page.pageKey}
      className={clsx(classes.link, classes.innerLink)}
    >
      <div
        className={
          isPageActive
            ? clsx(classes.navItem, classes.navItem__active)
            : classes.navItem
        }
      >
        <Icon className={classes.innerIcon} />
        <Typography variant="h5" className={classes.navItem__title}>
          {page.pageTitle}
        </Typography>
      </div>
    </Link>
  );
};

export default NavPage;
