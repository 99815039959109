import { ReactElement } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { mainColors } from '../../../../styling/theme';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { useWindowSize } from 'react-use';
import { Typography } from '@mui/material';
// import { fundAssetColumns } from '../fundAssetLiquidationTime/table';

interface Props {
  fundName: string;
  data: any;
  stressLevel: string;
}

export type StressLevel =
  | 'standard'
  | 'stressed'
  | 'superStressed'
  | 'euSovDebtCrisisJul11'
  | 'creditCrisisSept2008';

export interface ILasdabData {
  numDays: number;
  dailyAchievable: number;
  cumulativeAchievable: number;
}

function getStressLevelColour(stressLevel: string) {
  switch (stressLevel) {
    case 'standard':
      return mainColors.mainBlue;
    case 'stressed':
      return mainColors.Alert;
    case 'superStressed':
      return mainColors.Fail;
    default:
      return mainColors.mainBlue;
  }
}
const fundAssetColumns = (stressLevel: string): CustomColumn<ILasdabData>[] => {
  return [
    {
      title: '# Days',
      field: 'numDays',
      width: '33%',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: getStressLevelColour(stressLevel),
      },
    },
    {
      title: 'Daily Achievable',
      field: 'dailyAchievable',
      width: '33%',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: getStressLevelColour(stressLevel),
      },
    },
    {
      title: 'Cumulative Achievable',
      field: 'cumulativeAchievable',
      width: '33%',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: getStressLevelColour(stressLevel),
      },
    },
  ];
};

function buildLasdabTableData(inputData: any, level: string) {
  if (!inputData.length) return [];
  if (!('lasdab' in inputData[0])) return [];
  const data = inputData[0].lasdab;
  let stressData = [];
  if (level === 'standard') {
    stressData = data['bau'];
  } else if (level === 'stressed') {
    stressData = data['stressed'];
  } else if (level === 'superStressed') {
    stressData = data['super_stressed'];
  }

  const returnData: any[] = [];
  // Remove headers
  stressData.slice(1).forEach((value: any[]) => {
    returnData.push({
      numDays: value[0],
      dailyAchievable: percentageToTwoDecimalPlacesNoHundred(value[1]),
      cumulativeAchievable: percentageToTwoDecimalPlacesNoHundred(value[2]),
    });
  });
  return returnData;
}

function LasdabTable({
  fundName,
  data,
  stressLevel,
}: Props): ReactElement | null {
  const tableData = data ? buildLasdabTableData(data.data, stressLevel) : [];
  // const columns = createFundAssetColumns(stressLevel as StressLevel);
  const columns = fundAssetColumns(stressLevel);
  // const pdfColumns = createFundAssetPdfColumns(stressLevel as StressLevel);
  const pdfColumns = fundAssetColumns(stressLevel);

  const { width, height } = useWindowSize();
  return tableData.length ? (
    <>
      <GridItem xs={3} card>
        <CustomTable<ILasdabData>
          showToolbar={true}
          loading={data.isFetching}
          // csvFields={csvFundAssetLiquidationTime}
          options={{
            paging: false,
            sorting: false,
            maxBodyHeight: height * 0.78,
            exportButton: true,
          }}
          data={tableData}
          columns={columns}
          toolbarComponents={{
            toolbarTitle: fundName,
          }}
        />
      </GridItem>
    </>
  ) : (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h1">{'No Data Available.'}</Typography>{' '}
    </DisplayAreaCenteredWrapper>
  );
}

export default LasdabTable;
