import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { mainColors } from '../../../../styling/aifTheme';
import { RaptorTheme } from '../../../../styling/theme';
import { BASE_URL } from '../../../../utilities/requestClient';
import GridItem from '../../../layout/GridComponents/GridItem';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Typography } from '@mui/material';

interface Props {
  title: string;
  prefix: string;
  predefinedUrl?: string;
}

const typogrpahyStyles = makeStyles<RaptorTheme>(() => ({
  root: {
    color: mainColors.mainBlue,
  },
}));

const useStyles = makeStyles<RaptorTheme>(() => ({
  container: {
    padding: '0.2rem 0.5rem',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  icon: {
    transform: 'scale(1.5)',
  },
}));

function FileCard({ title, prefix, ...props }: Props): ReactElement {
  const typographyClasses = typogrpahyStyles();
  const classes = useStyles();

  const readableTitle = title.replace('.pdf', '');

  return (
    <GridItem xs={12} cardStyle={{ padding: '1rem' }} card>
      <a
        href={props.predefinedUrl || `${BASE_URL}/${prefix}/${title}`}
        target="_blank"
        className={classes.container}
      >
        <PictureAsPdfIcon className={classes.icon} />
        <Typography classes={typographyClasses} variant="h5">
          {readableTitle}
        </Typography>
      </a>
    </GridItem>
  );
}

export default FileCard;
