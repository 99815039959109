import React, { FC } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';

interface MancoAssetGrossExposureProps {
  data: any;
}

interface MancoAssetGrossExposureData {
  data: string;
  value: string;
}

export function buildMancoAssetGrossExposure(data: any) {
  if (!data.data.length) return [];
  if (!('security_exposure' in data.data[0])) return [];
  if (!('derivative_exposure' in data.data[0])) return [];
  if (!('manco_nav' in data.data[0])) return [];

  const { security_exposure, derivative_exposure, manco_nav } = data.data[0];

  const items = [
    {
      data: 'Cash Securities',
      value: `${((security_exposure / manco_nav) * 100).toFixed(2)}%`,
    },
    {
      data: 'Derivative Securities',
      value: `${((derivative_exposure / manco_nav) * 100).toFixed(2)}%`,
    },
  ];
  return items;
}

const tableColumns: CustomColumn<MancoAssetGrossExposureData>[] = [
  {
    title: 'Data',
    field: 'data',
    cellStyle: {
      textAlign: 'left',
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Field',
    field: 'value',
    cellStyle: {
      textAlign: 'right',
      // borderRight: theme.borders!.light,
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
];

const MancoAssetGrossExposure: FC<MancoAssetGrossExposureProps> = (props) => {
  const { data } = props;
  const renderedData = buildMancoAssetGrossExposure(data);

  return (
    <GridItem card xs={12} cardStyle={{ height: '100%' }}>
      <CustomTable<MancoAssetGrossExposureData>
        multipleTablePdfParameters={{
          specifiedWidth: 4,
          tablesInRow: 3,
        }}
        id={'manco_asset_class_gross_exposure'}
        title="Asset Type Gross Exposure"
        showToolbar={true}
        columns={tableColumns}
        toolbarComponents={{
          toolbarTitle: 'Asset Type Gross Exposure',
        }}
        data={renderedData}
        options={{
          paging: false,
          search: false,
          header: false,
          exportButton: false,
        }}
      />
    </GridItem>
  );
};

export default MancoAssetGrossExposure;
