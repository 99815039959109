import React, { ReactElement } from 'react';
import Logo from './RaptorLogoWithText.png';

interface Props {
  width?: number;
  opacity?: number;
}

function RaptorLogo(props: Props): ReactElement {
  return (
    <img
      src={Logo}
      alt="Raptor logo"
      style={{ width: props.width || 160, opacity: props.opacity || 1 }}
    />
  );
}

export default RaptorLogo;
