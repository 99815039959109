import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { openingAnimationIsActiveSelector } from '../../../../../redux/pages/selectors';

interface MancoPieChartProps {
  data: any;
  animBegin: number;
}

function buildColor(val: string) {
  if (val === 'Pass') {
    return 'rgba(19,187,57,1)';
  } else if (val === 'Pass - Alert') {
    return 'rgba(255,115,21,1)';
  } else {
    return 'rgba(236,14,14,1)';
  }
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  pieContainer: {
    '& > div': {
      cursor: 'pointer !important',
    },
  },
}));

export const tooltipFormatter = (value: any, name: any, props: any) =>
  value + ' funds';

const MancoPieChart: React.FC<MancoPieChartProps> = ({ data, animBegin }) => {
  const classes = useStyles();
  const animationsActive = useSelector(openingAnimationIsActiveSelector);
  return (
    <ResponsiveContainer
      className={classes.pieContainer}
      width={150}
      height={150}
    >
      <PieChart>
        <Tooltip
          itemStyle={{ fontSize: '1.6rem', color: mainColors.mainBlue }}
          formatter={tooltipFormatter}
        />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={'80%'}
          innerRadius={'50%'}
          fill="#8884d8"
          animationBegin={animBegin}
          isAnimationActive={animationsActive}
          animationDuration={1000}
        >
          {data.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={buildColor(entry.name)} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MancoPieChart;
