import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { ShareClassHedgeSummaryData } from './../../ShareClassHedgeSummaryTable.component';
import ShareClassTable from './../../subComponents/shareClassTable.component.tsx/ShareClassTable.component';

const ShareClassTableWrapper = () => [
  (rowData: ShareClassHedgeSummaryData) => {
    const icon = () =>
      rowData.secondLevelData.length ? <UnfoldMore /> : <></>;
    const render = () => (
      <ShareClassTable fundId={rowData.fundId} data={rowData.secondLevelData} />
    );
    return {
      disabled: !rowData.secondLevelData.length,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default ShareClassTableWrapper;
