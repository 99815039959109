import { ReactElement } from 'react';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import makeStyles from '@mui/styles/makeStyles';
import RaptorLogoWithText from '../../../../images/RaptorLogoWithText.png';
import { RaptorTheme, mainColors } from '../../../../styling/theme';

const useStyles = makeStyles<RaptorTheme>(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    marginBottom: '10rem',
    backgroundColor: 'white',
    padding: '4rem 6rem 4rem 6rem',
    borderRadius: '2rem',
  },
  logo: {
    marginBottom: '1rem',
  },
  message: {
    fontSize: '4rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
    textAlign: 'center',
  },
  messageSecond: {
    fontSize: '3rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
    textAlign: 'center',
  },
}));

export function doesClientHavePremiumPackage(esgData: any): boolean {
  // Check if the client has a premium scubscription for esg
  if (!esgData) return true;
  if (!esgData.data) return true;
  if (!esgData.data.length) return true;
  if (!('esg_subscription_package' in esgData.data[0])) return true;
  return esgData.data[0].esg_subscription_package === 'esg_premium_package';
}

const NonPremiumSubscriptionDisplayMessage = (): ReactElement => {
  const classes = useStyles();

  return (
    <DisplayAreaCenteredWrapper>
      <div className={classes.container}>
        <div>
          <img
            src={RaptorLogoWithText}
            alt={'Raptor Logo'}
            className={classes.logo}
          />
        </div>
        <div className={classes.message}>This is an ESG premium feature.</div>
        <div className={classes.messageSecond}>
          To upgrade, please contact the RiskSystem team.
        </div>
      </div>
    </DisplayAreaCenteredWrapper>
  );
};

export default NonPremiumSubscriptionDisplayMessage;
