import { FC } from 'react';
import { EditorTileProps } from '../../ucitsKiids/editor/editorTiles/PriipsKidsEditorColumn.component';
import makeStyles from '@mui/styles/makeStyles';
import UtilityButtons from '../buttons/UtilityButtons.component';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import CustomTable from '../../../../tables/CustomTable';
import { mainColors } from '../../../../../styling/theme';
import PriipsKidsEditorGridItem from '../../priips/layout/PriipsKidsEditorGridItem.component';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    // border: '1px solid',
    // borderColor: mainColors.mainBlue,
    // borderColor: 'black',
    marginTop: '2rem',
    // backgroundColor: 'white',
    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
}));

interface PerformanceScenariosTableData {
  scenario: string;
  description: string;
  year1: string;
  year3: string;
  year5: string | null;
}

const generalPerformanceScenariosTableData: PerformanceScenariosTableData[] = [
  {
    scenario: 'Stress',
    description: 'What you might get back after costs',
    year1: '',
    year3: '',
    year5: null,
  },
  {
    scenario: '',
    description: 'Average Return Each Year',
    year1: '',
    year3: '',
    year5: null,
  },
  {
    scenario: 'Unfavorable',
    description: 'What you might get back after costs',
    year1: '0%',
    year3: '0%',
    year5: null,
  },
  {
    scenario: '',
    description: 'Average Return Each Year',
    year1: '0%',
    year3: '0%',
    year5: null,
  },
  {
    scenario: 'Moderate',
    description: 'What you might get back after costs',
    year1: '0%',
    year3: '0%',
    year5: null,
  },
  {
    scenario: '',
    description: 'Average Return Each Year',
    year1: '0%',
    year3: '0%',
    year5: null,
  },
  {
    scenario: 'Favorable',
    description: 'What you might get back after costs',
    year1: '0%',
    year3: '0%',
    year5: null,
  },
  {
    scenario: '',
    description: 'Average Return Each Year',
    year1: '0%',
    year3: '0%',
    year5: null,
  },
];

function buildPerformanceScenariosTableData(
  content: any[],
): PerformanceScenariosTableData[] {
  const tableData: PerformanceScenariosTableData[] = [
    ...generalPerformanceScenariosTableData,
  ];
  content.forEach((element: any, index: number) => {
    tableData[index].year1 = element[0];
    tableData[index].year3 = element[1];
    tableData[index].year5 = element.length > 2 ? element[2] : null;
  });
  return tableData;
}

function buildTableColumns(
  year5: boolean,
): CustomColumn<PerformanceScenariosTableData>[] {
  const columns: CustomColumn<PerformanceScenariosTableData>[] = [
    {
      title: 'Scenarios',
      field: 'scenario',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
    },
    {
      title: '',
      field: 'description',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
    },
    {
      title: '1 Year',
      field: 'year1',
      cellStyle: {
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
    },
    {
      title: year5 ? '3 Years' : '3 Years (Recommended Holding Period)',
      field: 'year3',
      cellStyle: {
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
    },
  ];
  if (year5) {
    columns.push({
      title: '5 Years (Recommended Holding Period)',
      field: 'year5',
      cellStyle: {
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        // border: '1px solid #274C77',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
    });
  }

  return columns;
}

const PerformanceScenariosEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object
  const content = JSON.parse(priipsKidsElement.content);
  // Build the table data
  const tableData = buildPerformanceScenariosTableData(content);
  const year5 = tableData[0].year5 !== null;
  // Build the table columns
  const columns = buildTableColumns(year5);

  const classes = useStyles();

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.editorContainer}>
        <div className={classes.textEditorBox}>
          <CustomTable<PerformanceScenariosTableData>
            columns={columns}
            id={'performance_scenarios_table'}
            showToolbar={false}
            data={tableData}
            options={{
              paging: false,
              search: false,
              exportButton: false,
              sorting: false,
              draggable: false,
              toolbar: false,
            }}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
          disableDelete
          disableDeleteMessage="You cannot delete the performance scenarios table."
        />
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default PerformanceScenariosEditorTile;
