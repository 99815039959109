import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { RaptorTheme } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import FundSummaryContainer from './components/FundSummaryContainer/fundSummaryContainer.component';
import MancoSummaryContainer from './components/MancoSummaryContainer/MancoSummaryContainer.component';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(0.99)',
    },
  },
}));

const EMIR: React.FC<GeneralOverviewPageRenderedComponentProps> = (props) => {
  const classes = useStyles();

  const emirData = useFetchData({
    url: 'get_EMIR_breakdown',
    keyName: 'EMIR_breakdown',
    makeFetch: true,
  });

  const [selectedKey, setSelectedKey] = useState('');
  const updateSelectedKey = (key: string) => {
    setSelectedKey(key);
  };

  return (
    <GeneralComponentErrorShield
      dataObjects={[emirData]}
      customLoadingMessages={[
        'Loading Manco Level Data',
        'Manco Level Data May Take a Few Moments to Calculate',
        '...',
      ]}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem
          card
          key="manco-overview"
          item
          classes={classes}
          cardStyle={{ height: '30rem' }}
          xs={12}
        >
          <MancoSummaryContainer
            // title={item === 'sif' ? 'sif-raif' : item}
            data={emirData}
            selectedKey={selectedKey}
            setSelectedKeyFunction={updateSelectedKey}
          />
        </GridItem>
        <GridItem
          card
          key="fund-overview"
          item
          cardStyle={{ minHeight: '30rem' }}
          xs={12}
        >
          {selectedKey !== '' ? (
            <FundSummaryContainer
              // title={item === 'sif' ? 'sif-raif' : item}
              data={emirData}
              selectedKey={selectedKey}
            />
          ) : (
            <DisplayAreaCenteredWrapper noMinHeight>
              <Typography variant="h1">
                {'Please Click on a Chart Above to Show Fund Data.'}
              </Typography>
            </DisplayAreaCenteredWrapper>
          )}
        </GridItem>
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default EMIR;
