import { DataObject } from '../../../../../types/redux/data/dataTypes';
import {
  AggregateLiquidityData,
  StressLevel,
} from '../../fundAssetLiquidationTime/table';
import React, { ReactElement } from 'react';
import {
  buildMainData,
  createAgTableColumns,
  createAgTableColumnsPDF,
  csvAggregateLiquidation,
} from './ag-table.data';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { liquidityMap } from '../component';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';

interface Props {
  selectedLevel: StressLevel;
  liquidityData: DataObject;
}

export const liquidityLabels = ['Standard', 'Stressed', 'Super Stressed'];
function AggregateLiquidationTable(props: Props): ReactElement {
  const { isFetching, data } = props.liquidityData;

  const tableData = data.length ? buildMainData(data[0]) : [];
  const columns = createAgTableColumns(props.selectedLevel);
  const pdfColumns = createAgTableColumnsPDF(props.selectedLevel);

  return (
    <GridItem xs={12} card>
      <CustomTable<AggregateLiquidityData>
        data={tableData}
        csvFields={csvAggregateLiquidation}
        showToolbar
        toolbarComponents={{
          toolbarTitle: liquidityMap[props.selectedLevel].name,
        }}
        additionalDetailForPdf={`Stress Level: ${
          liquidityMap[props.selectedLevel].name
        }`}
        columns={columns as unknown as CustomColumn<AggregateLiquidityData>[]}
        options={{
          paging: false,
          exportButton: true,
          pageSizeOptions: [5, 10, 20, tableData.length],
        }}
        loading={isFetching}
        // pdfColumns={pdfColumns}
      />
    </GridItem>
  );
}

export default AggregateLiquidationTable;
