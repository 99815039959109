import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';

interface PeProxyModelTableProps {
  data: any;
}

interface PeProxyModelTableData {
  dcf_pl: number;
  base_currency: string;
  ebit_growth: number;
  ebit_mult: number;
  ebit_vol: number;
  gm_id: string;
  gm_name_long: string;
  gross_exposure: number;
  industry: string;
  maturity: string;
  sector: string;
  wacc: number;
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<PeProxyModelTableData>[] = [
  {
    title: 'Long Name',
    field: 'gm_name_long',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currrency',
    field: 'base_currency',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Country',
    field: 'location',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Industry',
    field: 'industry',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Strategy',
    field: 'strategy',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Location Index/ Weight',
    field: 'location_index',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Industry Index/ Weight',
    field: 'industry_index',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Strategy Index / Weight',
    field: 'strategy_index',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const buildTableData = (inputData: any): PeProxyModelTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: any[] = [];
    inputData.data[0].map((asset: any) => {
      returnData.push({
        ...asset,
        dcf_pl: asset['DCF_P/L'],
      });
    });
    return returnData;
  }
};

const PeProxyModelTable: React.FC<PeProxyModelTableProps> = (props) => {
  const builtTableData = buildTableData(props.data);

  return (
    <GridItem xs={12} card>
      <CustomTable<PeProxyModelTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={'Proxy Model Configuration Table'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default PeProxyModelTable;
