import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RaptorLoading from '../feedback/RaptorLoading';
import { Theme } from '@mui/material';

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  loadingWrapper: {
    position: 'relative',
    width: '100%',
    // "&:after": {
    //   content: '""',
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   height: "100%",
    //   width: "100%",
    //   backgroundColor: "white",
    //   opacity: 0.7,
    //   display: (props) => (props.loading ? "block" : "none"),
    //   zIndex: 1,
    // },
  },
  loadingIcon: {
    zIndex: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface Props {
  loading: boolean;
  style: React.CSSProperties;
  loadingHeight?: string;
}

interface StyleProps {
  loading: boolean;
}

function TableLoadingCover({
  loading,
  ...props
}: React.PropsWithChildren<Props>): ReactElement {
  const classes = useStyles({
    loading,
  } as StyleProps);
  return (
    <tbody className={classes.loadingWrapper} style={{ ...props.style }}>
      <tr
        style={{
          height: props.style.minHeight,
        }}
      >
        <td style={{ textAlign: 'center' }}>
          <RaptorLoading setWidth="4rem" />
        </td>
      </tr>
    </tbody>
  );
}

export default TableLoadingCover;
