import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import { activePageSelector } from '../../../../redux/pages/selectors';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import KPIOverview from './KPIOverview.component';
import ESGOverviewPanel from './OverviewPanel.component';
import NonPremiumSubscriptionDisplayMessage, {
  doesClientHavePremiumPackage,
} from '../NonPremiumSubscriptionDisplay/NonPremiumSubscriptionDisplayMessage.component';

const IndividualESGPage: FC<FundInfoComponentProps> = ({
  fundName,
  fundId,
  positionDate,
}) => {
  const currentPage = useSelector(activePageSelector);

  const esgData = useFetchData({
    url: `get_fund_esg_data/${fundId}/${positionDate}`,
    keyName: `${fundId}_esg_fund_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const rulesData = useFetchData({
    keyName: 'esg_code_descriptions',
    url: 'get_esg_code_descriptions',
    makeFetch: true,
  });

  const doesClientHavePremiumSubscription =
    doesClientHavePremiumPackage(esgData);

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgData, rulesData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found'}
    >
      {doesClientHavePremiumSubscription ? (
        <>
          <ESGOverviewPanel
            data={esgData}
            currentPage={currentPage}
            fundId={fundId}
            fundName={fundName}
            positionDate={positionDate}
          />
          <KPIOverview
            data={esgData}
            currentPage={currentPage}
            rulesData={rulesData}
          />
        </>
      ) : (
        <NonPremiumSubscriptionDisplayMessage />
      )}
    </GeneralComponentErrorShield>
  );
};

export default IndividualESGPage;
