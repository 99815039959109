import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getControlBarComponentsSelector,
  isControlBarShowingSelector,
} from '../../../redux/ui/controlbar/selectors';
import theme, { mainColors } from '../../../styling/theme';
import { zIndexes } from '../../../styling/zIndexes';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  controlBarRoot: {
    height: '6rem',
    width: '100vw',
    padding: '1rem 2rem 1rem 0.5rem',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: zIndexes.titleBar,
  },
  controlBarContents: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  toggleIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mainColors.Fail,
    cursor: 'pointer',
    '& svg': {
      fontSize: '3rem',
    },
    '&:hover': {
      color: mainColors.Fail_darker,
    },
    '&:active': {
      color: mainColors.Fail_even_darker,
    },
  },
});

const ControlBar: FC = () => {
  const classes = useStyles();
  const controlBarComponents = useSelector(getControlBarComponentsSelector);

  return (
    <div className={classes.controlBarRoot}>
      <div className={classes.controlBarContents}>
        {controlBarComponents
          ? controlBarComponents.map(
              (controlBarComponent: ReactElement, index: number) => (
                <React.Fragment key={index}>
                  {controlBarComponent}
                </React.Fragment>
              ),
            )
          : null}
      </div>
    </div>
  );
};

export default ControlBar;
