import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import LiquidityFactorAnalysisTable from './LiquidityDiscountAnalysisTable.component';
import LiquidityFactorChart from './LiquidityDiscountChart.component';
import LiquidityFactorMetricsTable from './LiquidityDiscountMetricsTable.component';
import { Grid } from '@mui/material';

const LiquidityFactorAnalysis: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const liquidityFactorData = useFetchData({
    url: `getPeHistoricaldiscountdata/${fundId}/${positionDate}`,
    keyName: `pe_historical_discount_data_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[liquidityFactorData]}
      customLoadingMessages={['Loading Historical Liquidity Discount Data']}
    >
      <Grid container spacing={2} style={{ margin: 0 }}>
        <LiquidityFactorMetricsTable data={liquidityFactorData} height={400} />
        <LiquidityFactorChart data={liquidityFactorData} />
        <LiquidityFactorAnalysisTable data={liquidityFactorData} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default LiquidityFactorAnalysis;
