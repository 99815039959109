//STORE ========
/*
 * The dataStore is a cache for fetched data
 * it is simply a key-value object
 * We construct unique keys depending on what data is called.
 * These keys are in the following format
 * positionDate__page__{other identifiers, e.g counterparty}__fund
 */

export interface DataObject {
  data: any[]; // the actual data object
  error: Error | null; // an error message associated with the data fetch
  isFetching: boolean;
}

/*

 * The dataStore is a cache for fetched data
 * it is simply a key-value object
 * We construct unique keys depending on what data is called.
 * These keys are in the following format
 * positionDate__page__{other identifiers, e.g counterparty}__fund
 */
export type DataKey = string;
export interface DataStore {
  [key: string]: DataObject;
}

// constants =========

export const FETCH_START = 'FETCH_START';
export type FETCH_START = typeof FETCH_START;
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export type FETCH_SUCCESS = typeof FETCH_SUCCESS;
export const FETCH_FAILURE = 'FETCH_FAILURE';
export type FETCH_FAILURE = typeof FETCH_FAILURE;
export const DELETE_FROM_STATE = 'DELETE_FROM_STATE';
export type DELETE_FROM_STATE = typeof DELETE_FROM_STATE;

export interface IFetchStart {
  type: FETCH_START;
  payload: DataKey;
}

export interface IFetchSuccess {
  type: FETCH_SUCCESS;
  payload: {
    keyName: DataKey;
    data: any;
  };
}
export interface IFetchFailure {
  type: FETCH_FAILURE;
  payload: {
    keyName: DataKey;
    errorMessage: Error;
  };
}

export interface IDeleteFromState {
  type: DELETE_FROM_STATE;
  payload: {
    keyName: DataKey;
  };
}

export type DataAction =
  | IFetchStart
  | IFetchSuccess
  | IFetchFailure
  | IDeleteFromState;

// ENUMS => Statuses, that sort of thing

export enum Status {
  Pass = 'Pass',
  Alert = 'Alert',
  PassAlert = 'Pass - Alert',
  Fail = 'Fail',
  NA = 'N/A',
  alteredPosition = 'Altered Position',
  noChange = 'No Change',
  newPosition = 'New Position',
  removedPosition = 'Removed Position',
  multiplePositions = 'Multiple Positions',
}
