import React, { FC } from 'react';
import LoggedInRoute from '../../../../routes/LoggedInRoute';
import FactSheetsOverview from './overview/FactSheetsOverview.component';
import { Redirect } from 'react-router-dom';

const FactSheets: FC = (props) => {
  return (
    // Here we route to different sections of the delegated acts functionality
    <>
      <Redirect
        exact
        from="/raidr/fact-sheets"
        to="/raidr/fact-sheets/overview"
      />
      <LoggedInRoute
        exact
        path="/raidr/fact-sheets/overview"
        Component={FactSheetsOverview}
      />
    </>
  );
};

export default FactSheets;
