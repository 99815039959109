import { InputLabel } from './TextInput.component';
import { ReactElement } from 'react';
import { mainColors } from '../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Button, ButtonProps, Theme } from '@mui/material';

interface Props extends ButtonProps {
  subtitle?: {
    handler?: () => void;
    text: string;
  };
}

const useButtonStyles = makeStyles<Theme>((theme) => ({
  containedPrimary: {
    backgroundColor: mainColors.mainBlue,
    '&:hover, &:focus': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  bottomLabel: {
    transition: 'all .2s',
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

function Raptor2Button({ children, ...props }: Props): ReactElement {
  const classes = useButtonStyles();
  return (
    <>
      <Button
        classes={classes}
        disableElevation
        disableRipple
        variant="contained"
        {...props}
      >
        {children}
      </Button>
      {props.subtitle && (
        <div
          style={{ cursor: props.subtitle.handler ? 'pointer' : 'auto' }}
          onClick={props.subtitle.handler}
        >
          <InputLabel bottomLabel additionalClasses={classes.bottomLabel}>
            {props.subtitle.text}
          </InputLabel>
        </div>
      )}
    </>
  );
}

export default Raptor2Button;
