import React, { FC } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';

interface MancoStaticExposureTableProps {
  dataForRender: DataObject;
}

interface MancoStaticExposureData {
  name: string;
  value: string;
}

function buildMancoStaticExposureData(data: any): MancoStaticExposureData[] {
  if (!data.length) return [];
  const mancoData = data[0];
  const nav = mancoData.manco_nav;
  const tableData: MancoStaticExposureData[] = [
    {
      name: 'Position Dates',
      value: mancoData.fund_dates,
    },
    {
      name: 'Base Currency',
      value: mancoData.manco_base_currency,
    },
    {
      name: 'NAV',
      value: addCommasToNumbersAndRound(mancoData.manco_nav),
    },
    {
      name: 'Net Exposure',
      value: addCommasToNumbersAndRound(mancoData.manco_net_exposure),
    },
    {
      name: 'Net Exposure Leverage',
      value: percentageToTwoDecimalPlaces(mancoData.manco_net_exposure / nav),
    },
    {
      name: 'Gross Exposure',
      value: addCommasToNumbersAndRound(mancoData.manco_gross_exposure),
    },
    {
      name: 'Gross Exposure Leverage',
      value: percentageToTwoDecimalPlaces(mancoData.manco_gross_exposure / nav),
    },
  ];
  return tableData;
}

const tableColumns: CustomColumn<MancoStaticExposureData>[] = [
  {
    title: 'Data',
    field: 'name',
    width: '40%',
    cellStyle: {
      textAlign: 'left',
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Field',
    field: 'value',
    width: '60%',

    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const MancoStaticExposureTable: FC<MancoStaticExposureTableProps> = (props) => {
  const { isFetching, error, data } = props.dataForRender;
  const tableData = buildMancoStaticExposureData(data);

  return (
    <GridItem card xs={12} cardStyle={{ height: '100%' }}>
      <CustomTable<MancoStaticExposureData>
        // selectedPositionDate={data[0].selected_position_date}
        id="manco_agg_static_exposure"
        title={'Aggregate Static Exposure'}
        // csvFields={aggregateExposureCsvFields}
        showToolbar={true}
        multipleTablePdfParameters={{
          specifiedWidth: 4,
          tablesInRow: 3,
        }}
        loading={isFetching}
        columns={tableColumns}
        data={tableData}
        options={{
          paging: false,
          search: false,
          header: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default MancoStaticExposureTable;
