import {
  ColumnData,
  FilterStateType,
  MinMaxStateType,
  PresetData,
  SortingStateType,
} from './DataTypes';

// these are the default column selections that will be shown to the clients
// there are some values available in the data which have been commented out so as to not give them as an option to the client
const standardColumns: ColumnData[] = [
  {
    field: 'id',
    title: 'Rule Number',
    show: true,
  },
  {
    field: 'indicator',
    title: 'Name',
    show: true,
  },
  {
    field: 'description',
    title: 'Description',
    show: true,
  },
  {
    field: 'value',
    title: 'Value',
    show: true,
  },
];

const noSortingState: SortingStateType = {};
const noFilterState: FilterStateType = {};
const noMinMaxState: MinMaxStateType = {};

const StandardPreset: PresetData = {
  id: 'standard',
  name: 'Standard',
  columns: standardColumns,
  sortingState: noSortingState,
  filterState: noFilterState,
  minMaxState: noMinMaxState,
};

export default StandardPreset;
