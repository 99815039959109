import React, { FC } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { FundData } from './LiquidationTimeTable.component';

interface LiquidationTimeSecondLevelTableProps {
  data: FundData[];
}

const FundColumns: CustomColumn<FundData>[] = [
  {
    title: 'Fund ID',
    field: 'fundId',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Fund Name',
    field: 'fundName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '30%',
  },
  {
    title: 'Date',
    field: 'date',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Position',
    field: 'position',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: FundData) => addCommasToNumbersAndRound(rowData.position),
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: FundData) =>
      addCommasToNumbersAndRound(rowData.grossExposure),
  },
  {
    title: 'Holding %',
    field: 'holdingPc',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: FundData) =>
      percentageToTwoDecimalPlaces(rowData.holdingPc),
  },
];

const LiquidationTimeSecondLevelTable: FC<
  LiquidationTimeSecondLevelTableProps
> = (props) => {
  return (
    <GridItem xs={12} card style={{ margin: '1rem' }}>
      <CustomTable<FundData>
        title={`Funds Holding Asset`}
        columns={FundColumns}
        showToolbar={true}
        data={props.data}
        options={{
          paging: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default LiquidationTimeSecondLevelTable;
