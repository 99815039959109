import React from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import { ESGChartProps } from './ESGChart';

const EnvironmentalChart = (props: ESGChartProps) => {
  const { value } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const boxHeight = ((100 - value) / 100) * 16;
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="4 4 16 16"
      xmlSpace="preserve"
    >
      {/* <linearGradient
                x1='0%'
                y1='100%'
                x2='0%'
                y2='0%'
                id="fill"
            >
                <stop offset={percentage} stopColor="#31a822" ></stop>
                <stop offset={percentage} stopColor="#bababa"></stop>
            </linearGradient> */}
      <defs>
        <clipPath id="cut-off-environmental">
          <rect x="4" y="4" width="200" height={boxHeight} />
        </clipPath>
      </defs>
      {/* <path
                d="M6.05 8.05c-2.73 2.73-2.73 7.15-.02 9.88 1.47-3.4 4.09-6.24 7.36-7.93-2.77 2.34-4.71 5.61-5.39 9.32 2.6 1.23 5.8.78 7.95-1.37C19.43 14.47 20 4 20 4S9.53 4.57 6.05 8.05z"
                fill="url(#fill)"
            /> */}
      <path
        id="leaf"
        d="M6.05 8.05c-2.73 2.73-2.73 7.15-.02 9.88 1.47-3.4 4.09-6.24 7.36-7.93-2.77 2.34-4.71 5.61-5.39 9.32 2.6 1.23 5.8.78 7.95-1.37C19.43 14.47 20 4 20 4S9.53 4.57 6.05 8.05z"
        fill={greys.grey400}
      />
      <path
        id="leaf"
        d="M6.05 8.05c-2.73 2.73-2.73 7.15-.02 9.88 1.47-3.4 4.09-6.24 7.36-7.93-2.77 2.34-4.71 5.61-5.39 9.32 2.6 1.23 5.8.78 7.95-1.37C19.43 14.47 20 4 20 4S9.53 4.57 6.05 8.05z"
        fill={mainColors.Pass_darker}
      />
      <use clipPath="url(#cut-off-environmental)" xlinkHref="#leaf" />
    </svg>
  );
};

export default React.memo(EnvironmentalChart);
