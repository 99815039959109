export const validatePassword = (password: string) => {
  // At least one upper case English letter, (?=.*?[A-Z])
  // At least one lower case English letter, (?=.*?[a-z])
  // At least one digit, (?=.*?[0-9])
  // At least one special character, (?= !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~)
  // Minimum twelve in length .{12,} (with the anchors)
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{12,}$/;
  return passwordRegex.test(password);
};

export const validateUsername = (username: string) => {
  // we are using a modified version of gmail username standards
  // Can only contain lowercase letters, numbers, underscores, hyphens and full stops
  // Cannot contain more than one special character in a row
  // Must be between 1 and 30 characters long
  // Cannot start or end with an underscore, hyphen or full stop
  const usernameRegex = /^[a-z0-9](?!.*?[._-]{2})[a-z0-9._-]{0,29}(?<![._-])$/;
  return usernameRegex.test(username);
};
