import React, { ReactElement } from 'react';
import {
  ILiquidationTimeCostTableData,
  buildBauTableData,
  buildStressedTable,
  liquidationColumns,
} from '../data/shared.data';
import CustomTable from '../../../../../tables/CustomTable';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';

interface Props {
  bauOrStressed: 'bau' | 'stressed';
  timeCostData: DataObject;
  section?: string;
}

function TimeCostTable({
  bauOrStressed,
  timeCostData,
  ...props
}: Props): ReactElement {
  const { data, isFetching, error } = timeCostData;

  const tableData =
    bauOrStressed === 'bau'
      ? buildBauTableData(data)
      : buildStressedTable(data);

  const cols = liquidationColumns(props.section);
  return (
    <GridItem xs={6} card>
      <CustomTable<ILiquidationTimeCostTableData>
        id={`${bauOrStressed}_table`}
        loading={isFetching}
        options={{ paging: false, exportButton: true }}
        data={tableData}
        columns={cols}
        csvFields={cols.map((col) => col.field as string)}
        showToolbar={true}
        toolbarComponents={{
          toolbarTitle: `Liquidation Time Cost Scenario: ${
            bauOrStressed === 'bau' ? 'BAU' : 'Stressed'
          }`,
        }}
        title={`Liquidation Time Cost Scenario: ${
          bauOrStressed === 'bau' ? 'BAU' : 'Stressed'
        }`}
      />
    </GridItem>
  );
}

export default TimeCostTable;
