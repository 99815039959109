import React from 'react';
import { greys, mainColors } from '../../../../../styling/theme';

export interface ESGChartProps {
  value: number;
}
const ESGChart = (props: ESGChartProps) => {
  const { value } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const boxHeight = ((100 - value) / 100) * 156;
  return (
    <svg
      version="1.1"
      id="esg_chart"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 156 156"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="cut-off-esg">
          <rect x="0" y="0" width="156" height={boxHeight} />
        </clipPath>
      </defs>
      <path
        d="M156 78C156 98.6869 147.782 118.526 133.154 133.154C118.526 147.782 98.6869 156 78 156C57.3131 156 37.4735 147.782 22.8457 133.154C8.21783 118.526 0 98.6869 0 78C0 57.3131 8.21783 37.4735 22.8457 22.8457C37.4735 8.21783 57.3131 0 78 0C98.6869 0 118.526 8.21783 133.154 22.8457C147.782 37.4735 156 57.3131 156 78V78Z"
        fill={greys.grey400}
        id="globe"
      />
      <path
        d="M156 78C156 98.6869 147.782 118.526 133.154 133.154C118.526 147.782 98.6869 156 78 156C57.3131 156 37.4735 147.782 22.8457 133.154C8.21783 118.526 0 98.6869 0 78C0 57.3131 8.21783 37.4735 22.8457 22.8457C37.4735 8.21783 57.3131 0 78 0C98.6869 0 118.526 8.21783 133.154 22.8457C147.782 37.4735 156 57.3131 156 78V78Z"
        fill={mainColors.mainBlue}
        id="globe"
      />

      <use clipPath="url(#cut-off-esg)" xlinkHref="#globe" />

      <path
        id="countries"
        fill="#ffffff"
        // inkscape:connector-curvature="0"
        d="m8 78c0 15.6 5 29.8 13.6 41.4l-3.2-22.2zm15-43.4 9.2-2.2c7.8-11.4 18.8-17.4 28.8-19.2l-15.8 11 15.6-3.8 11.2-6.2-3.2-5.6c-18.4 2.4-34.8 12-45.8 26zm10.2 43.2 6.6 13.2 19.6 1.8 10.6 40h13l14.8-18-0.8-11.2c6.4-5 11.4-11.2 13.4-19.2l-10 2.4-13-20.4 3-2 12.8 18 12.6-11c-6.6-0.8-11-4-12.8-9.6l22.8 3.6 8.2 16.8 3.6-22.4 10.2 12c-2.6-29.6-23.8-54-51.8-61.4 0 6.8-3.8 12.4-9.2 15.6l-7.2-4.2-13.8 8 4.8 4.6 7.4-8.8-1.4 10.8c-9 0-13.4 1.4-19 4.4l-1.2 5.6-6.2-1.4-2.6 7 5.4 1.6c3-3.8 5.4-6.2 11.2-7.6l6 6.6-3.6 1.2 2.2 1.8 3.2-3.8-4.6-6.4c4.6 0 7.2 2.8 8.8 9.8l8.2-12 10 4.2-1 2.6-11.8 1v4.4l9 0.6-2.8 7.2c-16.4 0-21.2-0.4-24.6-7.8l-13.6-0.8c-10 6-15 13.8-16.4 23.2zm19.4-41.4 8 2v-8zm44.4 7.8 4-2.2 5.4 11.2-3.6 0.8zm2.2 79.8 3.6 1.4 6-13.8-7.6 4.8z"
      />
      <path
        id="countries"
        fill={mainColors.Pass}
        // inkscape:connector-curvature="0"
        d="m8 78c0 15.6 5 29.8 13.6 41.4l-3.2-22.2zm15-43.4 9.2-2.2c7.8-11.4 18.8-17.4 28.8-19.2l-15.8 11 15.6-3.8 11.2-6.2-3.2-5.6c-18.4 2.4-34.8 12-45.8 26zm10.2 43.2 6.6 13.2 19.6 1.8 10.6 40h13l14.8-18-0.8-11.2c6.4-5 11.4-11.2 13.4-19.2l-10 2.4-13-20.4 3-2 12.8 18 12.6-11c-6.6-0.8-11-4-12.8-9.6l22.8 3.6 8.2 16.8 3.6-22.4 10.2 12c-2.6-29.6-23.8-54-51.8-61.4 0 6.8-3.8 12.4-9.2 15.6l-7.2-4.2-13.8 8 4.8 4.6 7.4-8.8-1.4 10.8c-9 0-13.4 1.4-19 4.4l-1.2 5.6-6.2-1.4-2.6 7 5.4 1.6c3-3.8 5.4-6.2 11.2-7.6l6 6.6-3.6 1.2 2.2 1.8 3.2-3.8-4.6-6.4c4.6 0 7.2 2.8 8.8 9.8l8.2-12 10 4.2-1 2.6-11.8 1v4.4l9 0.6-2.8 7.2c-16.4 0-21.2-0.4-24.6-7.8l-13.6-0.8c-10 6-15 13.8-16.4 23.2zm19.4-41.4 8 2v-8zm44.4 7.8 4-2.2 5.4 11.2-3.6 0.8zm2.2 79.8 3.6 1.4 6-13.8-7.6 4.8z"
      />
      <use clipPath="url(#cut-off-esg)" xlinkHref="#countries" />
    </svg>
  );
};

export default React.memo(ESGChart);
