import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React from 'react';
import { greys, mainColors } from '../../../styling/theme';
import {
  AdminPanelSettingsOutlined,
  LogoutOutlined,
  ManageAccountsOutlined,
} from '@mui/icons-material';
import { logOut } from '../../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userIdSelector } from '../../../redux/auth/selectors';
import UserDetailsService from '../../services/user-details-service';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  button: {
    margin: '0 0.5rem',
    height: '4rem',
    width: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mainColors.mainBlue,
    fontSize: '1.4rem',
    fontWeight: 600,
    border: `1px solid ${mainColors.mainBlue}`,
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: greys.grey100,
    '&:hover': {
      boxShadow: `0 0 0 3px ${greys.grey200}`,
    },
  },
  menu: {
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    width: '30rem',
    backgroundColor: 'white',
    borderRadius: '0.4rem',
    color: mainColors.mainBlue,
    border: `2px solid ${mainColors.mainBlue}`,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue_lighter,
      borderRadius: '1rem',
    },
  },
  menuHeader: {
    display: 'flex',
    padding: '1rem',
  },
  menuHeaderIcon: {
    height: '4rem',
    width: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mainColors.mainBlue,
    fontSize: '1.4rem',
    fontWeight: 600,
    border: `1px solid ${mainColors.mainBlue}`,
    userSelect: 'none',
    backgroundColor: greys.grey100,
  },
  menuHeaderContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '1rem',
  },
  menuHeaderName: {
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  menuHeaderAction: {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: mainColors.mainBlue_lightAlt,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      color: mainColors.mainBlue_lighter,
    },
  },
  divider: {
    width: '100%',
    borderTop: `1px solid ${mainColors.mainBlue}`,
  },
  menuItem: {
    display: 'flex',
    padding: '1rem',
    fontSize: '1.4rem',
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
    gap: '1rem',
    '&:hover': {
      backgroundColor: greys.grey100,
    },
  },
  menuItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: '2rem',
    },
  },
});

const UserOptionsMenu: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const userName = useSelector(userIdSelector);

  const [isOpen, setIsOpen] = React.useState(false);

  const userDetails = UserDetailsService.getUserDetails();

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-end',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const handleLogOut = () => {
    dispatch(logOut());
  };

  const handleGoToMyAccount = (e: React.MouseEvent) => {
    setIsOpen(false);
    history.push(`/settings/my-account`);
  };

  const handleGoToManageAccounts = (e: React.MouseEvent) => {
    setIsOpen(false);
    history.push(`/settings/manage-accounts`);
  };

  const handleGoToSecuritySettings = (e: React.MouseEvent) => {
    setIsOpen(false);
    history.push(`/settings/security-settings`);
  };

  const handleGoToDocumentation = (e: React.MouseEvent) => {
    setIsOpen(false);
    history.push(`/documentation/introduction`);
  };

  return (
    <>
      <div
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
        className={classes.button}
      >
        {userName && userName.length ? userName[0].toUpperCase() : ''}
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={classes.menu}
          >
            <div className={classes.menuHeader}>
              <div className={classes.menuHeaderIcon}>
                {userName && userName.length ? userName[0].toUpperCase() : ''}
              </div>
              <div className={classes.menuHeaderContent}>
                <div className={classes.menuHeaderName}>{userName}</div>
                <div
                  className={classes.menuHeaderAction}
                  onClick={handleGoToMyAccount}
                >
                  My Account Settings
                </div>
              </div>
            </div>
            <div className={classes.divider}></div>
            {userDetails &&
            userDetails.user_type === 'external_administrator' ? (
              <div
                className={classes.menuItem}
                onClick={handleGoToManageAccounts}
              >
                <div className={classes.menuItemIcon}>
                  <ManageAccountsOutlined />
                </div>
                <div>Manage Accounts</div>
              </div>
            ) : null}
            {userDetails &&
            userDetails.user_type === 'external_administrator' ? (
              <div
                className={classes.menuItem}
                onClick={handleGoToSecuritySettings}
              >
                <div className={classes.menuItemIcon}>
                  <AdminPanelSettingsOutlined />
                </div>
                <div>Security Settings</div>
              </div>
            ) : null}
            {/* <div className={classes.menuItem}>
              <div className={classes.menuItemIcon}>
                <NotificationsOutlined />
              </div>
              <div>Notifications</div>
            </div> */}
            {/* <div
                className={classes.menuItem}
              >
                <div
                  className={classes.menuItemIcon}
                >
                  <ExtensionOutlined />
                </div>
                <div>
                  Resources
                </div>
              </div> */}
            {/* <div className={classes.menuItem} onClick={handleGoToDocumentation}>
              <div className={classes.menuItemIcon}>
                <HelpCenterOutlined />
              </div>
              <div>Documentation</div>
            </div> */}
            {/* <div className={classes.menuItem}>
              <div className={classes.menuItemIcon}>
                <SupportAgentOutlined />
              </div>
              <div>Support</div>
            </div> */}
            <div className={classes.divider}></div>
            <div className={classes.menuItem} onClick={handleLogOut}>
              <div className={classes.menuItemIcon}>
                <LogoutOutlined />
              </div>
              <div>Logout</div>
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};

export default UserOptionsMenu;
