import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import YieldCurve from './subComponents/YieldCurve';
import YieldTable from './subComponents/YieldTable';
import { DataObject } from '../../../types/redux/data/dataTypes';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 3fr',
    padding: '2rem',
    gap: '2rem',
    '@media (min-width: 1024px) and (max-width: 1440px)': {
      padding: '1rem',
      gap: '1rem',
      gridTemplateColumns: '1fr 2fr',
    },
  },
});

interface YieldPageProps {
  response: DataObject;
}

export interface YieldDataType {
  matur: number;
  par: number;
  spot: number;
  forward: number;
}

export interface YieldDataProps {
  yieldData: YieldDataType[];
}

const YieldPage: React.FC<YieldPageProps> = ({ response }) => {
  const [yieldData, setYieldData] = useState<YieldDataType[]>([]);

  useEffect(() => {
    type YieldCurveRow = [number, number, number, number];
    const parsedData = response.data[0].yield_curve_data
      .slice(1)
      .map((row: YieldCurveRow) => {
        return {
          matur: row[0], // Maturity
          par: row[1], // Par
          spot: row[2], // Spot
          forward: row[3], // Forward
        };
      });
    setYieldData(parsedData);
  }, [response]);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <YieldTable yieldData={yieldData} />
      <YieldCurve yieldData={yieldData} />
    </div>
  );
};

export default YieldPage;
