import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import HairCutTable from './components/HariCutTable.component';
import PositionsTable from './components/PositionsTable.component';
import StressTestTable from './components/StressTestTable.component';
import { Grid } from '@mui/material';

const CollateralMonitor: React.FC<FundInfoComponentProps> = (props) => {
  const { fundId, positionDate, section, pageId, fundName } = props;

  const collateralMonitorData = useFetchData({
    url: positionDate
      ? `get_collateral_stress_test_data/${fundId}/${positionDate}`
      : `get_collateral_stress_test_data/${fundId}`,
    keyName: positionDate
      ? `collateral_monitor_${fundId}_${positionDate}`
      : `collateral_monitor_${fundId}`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[collateralMonitorData]}
      customLoadingMessages={['Loading Collateral Monitor Data', '...']}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <StressTestTable data={collateralMonitorData} />
        <HairCutTable data={collateralMonitorData} />
        <PositionsTable data={collateralMonitorData} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default CollateralMonitor;
