import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../../hooks/useFetchData';
import { clientNameSelector } from '../../../../../redux/auth/selectors';
import { mainColors } from '../../../../../styling/theme';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { Fund } from '../../../../../types/server-data/ClientConfig';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import CustomTable from '../../../../tables/CustomTable';
import {
  CounterpartyDetailData,
  CssfDetailDataDefault,
  generateCounterpartyDetailColumns,
  generateCounterpartyDetailData,
  generateCssfDetailColumns,
  generateCssfDetailData,
} from './CssfDetail.data';
import { useTheme } from '@mui/material';

type DetailProps = {
  rule: string;
  fundName: string;
  data: DataObject;
};

export const CssfDetailTableCounterparty = ({
  fundName,
  rule,
  data,
}: DetailProps) => {
  const columns = generateCounterpartyDetailColumns();
  const tableData = generateCounterpartyDetailData(data);
  return (
    <GeneralComponentErrorShield dataObjects={[data]} noMinHeightOnError>
      <CustomTable<CounterpartyDetailData>
        options={{
          exportButton: true,
          search: true,
          paging: false,
        }}
        data={tableData}
        columns={columns}
        showToolbar
        title={`${fundName} - ${rule}`}
      />
    </GeneralComponentErrorShield>
  );
};

export const CssfDetailTableDefault = ({
  fundName,
  rule,
  data,
}: DetailProps): ReactElement => {
  const clientName = useSelector(clientNameSelector);
  const columns = generateCssfDetailColumns();
  const detailData = generateCssfDetailData(data, clientName || 'mersenne');

  const theme = useTheme();
  return (
    <GeneralComponentErrorShield dataObjects={[data]} noMinHeightOnError>
      <CustomTable<CssfDetailDataDefault>
        options={{
          paging: false,
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor: theme.palette.grey[100],
          }),
        }}
        toolbarComponents={{
          titleStyle: {
            fontSize: '2rem',
            fontWeight: 400,
            color: mainColors.mainGold,
          },
          toolbarTitle: `${rule}`,
        }}
        title={`${rule}: ${fundName}`}
        showToolbar={true}
        data={detailData}
        columns={columns}
        id={`pdf_${fundName}_${rule}`}
      />
    </GeneralComponentErrorShield>
  );
};

interface CssfDetailProps {
  fundDetails: Fund;
  positionDate?: string;
  ruleNumber: number;
  isCounterparty: boolean;
  ruleTitle: string;
}
const CssfDetailComponent = (props: CssfDetailProps) => {
  const { positionDate, ruleNumber, fundDetails, ruleTitle } = props;
  const { id, name } = fundDetails;
  const detailData = useFetchData({
    url: `cssf_risk_spreading_inscope_positions/${id}/${ruleNumber}${
      positionDate ? `/${positionDate}` : '/'
    }`,
    keyName: `cssf_risk_spreading_inscope_positions/${id}/${ruleNumber}${
      positionDate ? `/${positionDate}` : formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  return props.isCounterparty ? (
    <CssfDetailTableCounterparty
      data={detailData}
      rule={ruleTitle}
      fundName={name}
    />
  ) : (
    <CssfDetailTableDefault
      data={detailData}
      rule={ruleTitle}
      fundName={name}
    />
  );
};

export default CssfDetailComponent;
