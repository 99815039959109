import React, { FC } from 'react';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { percentageToTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';

interface WcapitalCustomGraphsProps {
  section?: string;
  timeCostData: DataObject;
}

export interface BauLiquidationTimeCostTableData {
  description: string;
  oneDay: number;
  oneWeek: number;
  twoWeeks: number;
  threeWeeks: number;
  oneMonth: number;
  threeMonths: number;
  sixMonths: number;
  nineMonths: number;
  oneYear: number;
}

export interface StressedLiquidationTimeCostTableData {
  description: string;
  oneDay: number;
  fiveDays: number;
  twentyDays: number;
  oneMonth: number;
}

export const BauDescriptionNamesWcapital = [
  'BAU Redemptions',
  'Redeemed',
  'Cost',
  'Total Redeemed',
];
export const StressedDescriptionNamesWcapital = [
  'Stressed Redemptions',
  'Redeemed',
  'Cost',
  'Total Redeemed',
];

export const BauDaysToShowWcapital = [1, 7, 14, 21, 30, 90, 180, 270, 360];
export const StressedDaysToShowWcapital = [1, 5, 20, 30];

export const BauKeysForObjectsWcapital = [
  'oneDay',
  'oneWeek',
  'twoWeeks',
  'threeWeeks',
  'oneMonth',
  'threeMonths',
  'sixMonths',
  'nineMonths',
  'oneYear',
];

export const StressedKeysForObjectsWcapital = [
  'oneDay',
  'fiveDays',
  'twentyDays',
  'oneMonth',
];

function buildLTCData(data: any, key: 'bau' | 'stressed') {
  // this takes in the data and "bau" or "stressed"

  if (data.daily_redemption.length < 1) {
    return [];
  }

  const data1: any = [];
  const data2: any = [];
  const data3: any = [];
  const data4: any = [];
  const data5: any = [];
  const dates = data['funding_data']['dates'];
  const nav = data.funding_data.indicative_info.nav;
  const d1 = data.daily_redemption;
  const d2 = data.daily_cost_of_redemption;
  const d3 = data.funding_data.fund_redemption[key]; // could be "bau" or "stressed"

  for (let i = 0; i < d1.length; i++) {
    data1.push([dates[i], d3[i] / nav]); // bau
    data2.push([dates[i], d1[i] / nav]); // daily
    data3.push([dates[i], d2[i] / nav]); // daily cost
    data4.push([dates[i], (d1[i] + d2[i]) / nav]); // total redeemed
  }

  return [data1, data2, data3, data4, data5];
}

const buildTableData = (data: any, type: 'bau' | 'stressed') => {
  if (!data.length) return [];

  const relevantData =
    type === 'bau'
      ? data[0].funding_liquidity_bau
      : data[0].funding_liquidity_stressed;

  const descriptionNames =
    type === 'bau'
      ? BauDescriptionNamesWcapital
      : StressedDescriptionNamesWcapital;

  const daysToShow =
    type === 'bau' ? BauDaysToShowWcapital : StressedDaysToShowWcapital;

  const keysForObjects =
    type === 'bau' ? BauKeysForObjectsWcapital : StressedKeysForObjectsWcapital;

  const descriptions = buildLTCData(relevantData, type);

  const returnData: any = [];

  descriptions.forEach((description, index: number) => {
    const descriptionObject: any = {};
    descriptionObject['description'] = descriptionNames[index];
    let counter = 0; // this is for getting the key name from keys for objects

    for (let i = 0; i < description.length; i++) {
      if (daysToShow.includes(i)) {
        descriptionObject[keysForObjects[counter++]] = description[i][1];
      }
    }

    if (Object.keys(descriptionObject).length > 1) {
      returnData.push(descriptionObject);
    }
  });

  return returnData;
};

export const BauColumnsWcapital = [
  {
    field: 'description',
    title: 'Description',
  },
  {
    field: 'oneDay',
    title: '1 Day',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.oneDay),
  },
  {
    field: 'oneWeek',
    title: '1 Week',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.oneWeek),
  },
  {
    field: 'twoWeeks',
    title: '2 Weeks',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.twoWeeks),
  },
  {
    field: 'threeWeeks',
    title: '3 Weeks',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.threeWeeks),
  },
  {
    field: 'oneMonth',
    title: '1 Month',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.oneMonth),
  },
  {
    field: 'threeMonths',
    title: '3 Months',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.threeMonths),
  },
  {
    field: 'sixMonths',
    title: '6 Months',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.sixMonths),
  },
  {
    field: 'nineMonths',
    title: '9 Months',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.nineMonths),
  },
  {
    field: 'oneYear',
    title: '1 Year',
    render: (rowData: BauLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.oneYear),
  },
];

export const StressedColumnsWcapital = [
  {
    field: 'description',
    title: 'Description',
  },
  {
    field: 'oneDay',
    title: '1 Day',
    render: (rowData: StressedLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.oneDay),
  },
  {
    field: 'fiveDays',
    title: '5 Days',
    render: (rowData: StressedLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.fiveDays),
  },
  {
    field: 'twentyDays',
    title: '20 Days',
    render: (rowData: StressedLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.twentyDays),
  },
  {
    field: 'oneMonth',
    title: '1 Month',
    render: (rowData: StressedLiquidationTimeCostTableData) =>
      percentageToTwoDecimalPlaces(rowData.oneMonth),
  },
];

const WcapitalCustomGraphs: FC<WcapitalCustomGraphsProps> = (props) => {
  const { data, isFetching, error } = props.timeCostData;

  const bauTableData = buildTableData(data, 'bau');
  const stressedTableData = buildTableData(data, 'stressed');

  return (
    <>
      <GridItem xs={8} card>
        <CustomTable<BauLiquidationTimeCostTableData>
          id={`bau_table`}
          loading={isFetching}
          options={{ paging: false, exportButton: true }}
          data={bauTableData}
          columns={BauColumnsWcapital}
          csvFields={BauColumnsWcapital.map((col) => col.field as string)}
          showToolbar={true}
          toolbarComponents={{
            toolbarTitle: `Liquidation Time Cost Scenario: BAU`,
          }}
          title={`Liquidation Time Cost Scenario: BAU`}
        />
      </GridItem>
      <GridItem xs={4} card>
        <CustomTable<StressedLiquidationTimeCostTableData>
          id={`stressed_table`}
          loading={isFetching}
          options={{ paging: false, exportButton: true }}
          data={stressedTableData}
          columns={StressedColumnsWcapital}
          csvFields={StressedColumnsWcapital.map((col) => col.field as string)}
          showToolbar={true}
          toolbarComponents={{
            toolbarTitle: `Liquidation Time Cost Scenario: Stressed`,
          }}
          title={`Liquidation Time Cost Scenario: Stressed`}
        />
      </GridItem>
    </>
  );
};

export default WcapitalCustomGraphs;
