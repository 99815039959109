import React from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import OverallStatusPage from '../../sharedComponents/overallStatusPage/component';

const ProspectusRestrictionsOverview: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  return (
    <OverallStatusPage
      overviewDataPageKey="Risk_Compliance"
      positionDate={props.positionDate}
    />
  );
};

export default ProspectusRestrictionsOverview;
