import React, { Dispatch, FC, SetStateAction } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../../../../styling/theme';
import { IconButton, Tooltip, TooltipProps } from '@mui/material';

export interface DisplayToggleProps {
  choice: boolean;
  setChoice: Dispatch<SetStateAction<boolean>>;
  Icon1: React.FC;
  Icon2: React.FC;
  tooltip?: {
    tooltipPlacement: TooltipProps['placement'];
    choice1Text: string;
    choice2Text: string;
  };
  iconRootStyles?: string;
}

const useDisplayToggleStyles = makeStyles((theme: RaptorTheme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
}));

const DisplayToggle: FC<DisplayToggleProps> = (props) => {
  const handleClick = () => {
    props.setChoice(!props.choice);
  };
  const classes = useDisplayToggleStyles();

  const Icon = props.choice ? props.Icon1 : props.Icon2;

  const renderIcon = props.tooltip ? (
    <Tooltip
      classes={classes}
      placement={props.tooltip.tooltipPlacement}
      title={
        props.choice ? props.tooltip.choice1Text : props.tooltip.choice2Text
      }
    >
      <IconButton
        classes={{
          root: props.iconRootStyles,
        }}
        onClick={handleClick}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton onClick={handleClick}>
      <Icon />
    </IconButton>
  );
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>{renderIcon}</div>
  );
};

export default DisplayToggle;
