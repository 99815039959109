import { CustomColumn } from '../../../../../types/components/tables/tableTypes';

export interface MoabData {
  asset: string;
  exposure: number;
}

export const generateMoabColumns = (): CustomColumn<MoabData>[] => {
  return [
    {
      title: 'Asset',
      field: 'asset',
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) => rowData.exposure + '%',
      width: 5,
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
};

export function buildMoabData(data: any[]) {
  if (!data || !data.length) return [];
  try {
    return data.map((datum) => {
      return {
        asset: datum[0],
        exposure: datum[1],
      };
    });
  } catch (err) {
    console.error('problem rendering moab data', err);
    return [];
  }
}
