import React from 'react';
import { mapStatusToColor } from '../../../utilities/colorUtilities';
import { ArticleProps } from './EsgChartsData';

const Article6Chart = (props: ArticleProps) => {
  const { status } = props;
  // Calculate the height the box should be based on how much should be filled green.
  const colour = mapStatusToColor(status);
  return (
    <svg
      version="1.1"
      id="leaf_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 122.88"
      xmlSpace="preserve"
    >
      <path
        id="article9_circle"
        fillRule="evenodd"
        d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0Z"
        fill={colour}
      />
      <path
        id="number"
        fill="white"
        d="M62.2,85a22.61,22.61,0,0,1-7.57-1.27,17,17,0,0,1-6.32-4A18.6,18.6,0,0,1,44,72.65a31.83,31.83,0,0,1-1.59-10.59A33.77,33.77,0,0,1,43.82,52a22.26,22.26,0,0,1,4-7.62,17.49,17.49,0,0,1,6.27-4.81,19.61,19.61,0,0,1,8.14-1.66,19.32,19.32,0,0,1,8.88,1.95,16.28,16.28,0,0,1,6.13,5.24A15,15,0,0,1,80,52.33H67.73a4.32,4.32,0,0,0-2-3,6.64,6.64,0,0,0-3.5-.93,6,6,0,0,0-5.71,3.41,19.65,19.65,0,0,0-1.87,9.09H55A9.49,9.49,0,0,1,57.6,57.3,12.87,12.87,0,0,1,61.71,55a15.29,15.29,0,0,1,4.95-.8,13.76,13.76,0,0,1,7.25,1.87,13.11,13.11,0,0,1,4.86,5.13,15.48,15.48,0,0,1,1.72,7.45A15.23,15.23,0,0,1,71.72,83a21.36,21.36,0,0,1-9.52,2Zm-.1-9.37a6.37,6.37,0,0,0,3.29-.85,6,6,0,0,0,2.26-2.33,7.5,7.5,0,0,0,0-6.7,6,6,0,0,0-2.26-2.31,6.28,6.28,0,0,0-3.29-.85,6.68,6.68,0,0,0-2.51.48,6.23,6.23,0,0,0-2,1.36,6.5,6.5,0,0,0-1.33,2.08,7.08,7.08,0,0,0-.5,2.6,6.71,6.71,0,0,0,.85,3.34,6.14,6.14,0,0,0,2.25,2.33,6.25,6.25,0,0,0,3.23.85Z"
      />
    </svg>
  );
};

export default React.memo(Article6Chart);
