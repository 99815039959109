import { ContentState, Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { greys } from '../../../../../../styling/theme';
import { Typography } from '@mui/material';

interface AnnexQuestionEditorTileProps {
  delegatedActsElement: string;
  delegatedActsData: any[];
  setDelegatedActsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
}

const AnnexQuestionEditorTile: FC<AnnexQuestionEditorTileProps> = (props) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  const {
    delegatedActsElement,
    delegatedActsData,
    setDelegatedActsData,
    index,
    setHaveChangesBeenMade,
  } = props;

  // Convert the data into an object.
  const dataJson = JSON.parse(delegatedActsElement);

  const contentStateResponse = ContentState.createFromText(dataJson.value);
  const initialStateResponse =
    EditorState.createWithContent(contentStateResponse);
  const [editorStateResponse, setEditorStateResponse] =
    React.useState<EditorState>(initialStateResponse);
  const question = dataJson.question;

  const handleTextChange = (updatedEditorState: EditorState) => {
    setEditorStateResponse(updatedEditorState);
    // Get The text from the editor state
    let text = stateToHTML(updatedEditorState.getCurrentContent());

    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Now update the element in the overall data
    const allContent = delegatedActsData;
    // Check which data point of data needs to be updated dataJson
    dataJson.value = text;
    // Check if the text has changes and if so indicate it
    if (allContent[index].content !== delegatedActsElement)
      setHaveChangesBeenMade(true);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJson);
    // Update the overall data
    setDelegatedActsData(allContent);
  };

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div>
        <Typography
          variant="h3"
          style={{ fontSize: '1.5rem', color: greys.grey400 }}
        >
          Annex 4
        </Typography>
        <Typography
          variant="h3"
          style={{ marginLeft: '1rem', marginTop: '1rem' }}
        >
          {question}
        </Typography>
        <div
          style={{
            padding: '1rem',
            fontSize: '1.5rem',
            minHeight: '5rem',
            display: 'flex',
          }}
        >
          <div
            style={{
              border: '0.5px solid',
              minHeight: '2rem',
              marginTop: '0.5rem',
              backgroundColor: 'white',
              minWidth: '10rem',
              width: '100%',
            }}
          >
            <Editor
              editorState={editorStateResponse}
              onChange={(updatedEditorState: EditorState) => {
                handleTextChange(updatedEditorState);
              }}
              stripPastedStyles={true}
              //   handleKeyCommand={handleKeyCommand}
              //   customStyleMap={styleMap}
              //   readOnly={disabled}
              // blockRendererFn={mediaBlockRenderer}
            />
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default AnnexQuestionEditorTile;
