export default function (ruleString: string) {
  try {
    const toArr = ruleString.split('_');
    const firstLetter = toArr[0].substr(0, 1);
    toArr[0] = firstLetter.toUpperCase() + toArr[0].substr(1);
    return toArr.join(' ');
  } catch (err) {
    console.error(err);
    return 'Unformattable string';
  }
}
