import { UiAction } from './actions';
import {
  TOGGLE_CONTROLBAR_SHOWING_ACTION,
  UPDATE_CONTROLBAR_COMPONENTS_ACTION,
} from './controlbar/actions';
import controlBarReducer, {
  ControlBarStore,
  defaultControlBarStore,
} from './controlbar/reducer';
import {
  TOGGLE_SNACKBAR_LOADING_ACTION,
  UPDATE_SNACKBAR_MESSAGE_ACTION,
} from './snackbar/actions';
import snackBarReducer, {
  SnackbarStore,
  defaultSnackbarStore,
} from './snackbar/reducer';

export interface UiStore {
  snackbarState: SnackbarStore;
  controlBarState: ControlBarStore;
}

const uiDefaultState: UiStore = {
  snackbarState: defaultSnackbarStore,
  controlBarState: defaultControlBarStore,
};

function uiReducer(state: UiStore = uiDefaultState, action: UiAction): UiStore {
  switch (action.type) {
    case TOGGLE_SNACKBAR_LOADING_ACTION:
    case UPDATE_SNACKBAR_MESSAGE_ACTION:
      return {
        ...state,
        snackbarState: snackBarReducer(state.snackbarState, action),
      };
    case TOGGLE_CONTROLBAR_SHOWING_ACTION:
    case UPDATE_CONTROLBAR_COMPONENTS_ACTION:
      return {
        ...state,
        controlBarState: controlBarReducer(state.controlBarState, action),
      };
    default:
      return state;
  }
}

export default uiReducer;
