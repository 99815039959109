import React, { ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import CoverageRatioSecondLevelTableWrapper from './SecondLevelTableWrapper.component';
import RaptorStatusBox from '../../../feedback/RaptorStatusBox.component';
import { specifyStatus } from '../../../../utilities/generalMappings';

interface FundTableProps {
  data: any;
  issuer: string;
}

export interface IFundTableData {
  fundId: string;
  fundName: string;
  percentage: number;
  percentageLimit: number;
  limit: number;
  status: string;
  value: number;
  underlyingAssets: any;
}

function buildFundTableData(data: any, issuer: string): IFundTableData[] {
  if (!data.data.length) return [];
  if (issuer === '') return [];
  const underlyingFunds = data.data[0].underlying_funds;
  const tableData: IFundTableData[] = [];

  underlyingFunds.forEach((fund: any) => {
    const fundData = {
      fundId: fund.fund_id,
      fundName: fund.fund_name,
      percentage: 0,
      limit: 0,
      status: 'Pass',
      value: 0,
      percentageLimit: 0,
      underlyingAssets: [],
    };
    // get the data specific to the desired issuer.
    fund.issuers.forEach((currentIssuer: any) => {
      if (currentIssuer.Issuer === issuer) {
        fundData['percentage'] = currentIssuer.Percentage_value;
        fundData['percentageLimit'] = currentIssuer.Percentage_limit;
        fundData['limit'] = currentIssuer.limit;
        fundData['status'] = currentIssuer.status;
        fundData['value'] = currentIssuer.value;
        fundData['underlyingAssets'] = currentIssuer.underlying_assets;
      }
    });
    tableData.push(fundData);
  });

  return tableData;
}

function buildFundTableColumns(): CustomColumn<IFundTableData>[] {
  return [
    {
      title: 'Fund Id',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '10%',
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '20%',
    },
    {
      title: 'Value',
      field: 'value',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.value),
    },
    {
      title: 'Limit',
      field: 'limit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.limit),
    },

    {
      title: 'Percentage Limit',
      field: 'percentageLimit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.percentageLimit),
    },
    {
      title: 'Percentage',
      field: 'percentage',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.percentage),
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '10%',
      render: (rowData: IFundTableData) => (
        <RaptorStatusBox status={specifyStatus(rowData.status)} />
      ),
    },
  ];
}

function FundTable({ data, issuer }: FundTableProps): ReactElement | null {
  const tableData = buildFundTableData(data, issuer);
  const tableColumns = buildFundTableColumns();

  return (
    <GridItem xs={12} card>
      <CustomTable<IFundTableData>
        title={`${issuer} Fund Level Data`}
        showToolbar={true}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          rowStyle: (rowData: any) => ({
            backgroundColor:
              rowData.issuerName === issuer
                ? mainColors.mainBlue_lightAlt
                : undefined,
          }),
        }}
        data={tableData}
        columns={tableColumns}
        emptyDataSourceMessage="Please click on an issuer Status above to populate Table."
        detailPanel={CoverageRatioSecondLevelTableWrapper()}
      />
    </GridItem>
  );
}

export default FundTable;
