import React, { FC } from 'react';
import AirEmissions from './air_emissions.svg';
import EnergyManagement from './energy_management.svg';
import EnvironmentalManagement from './environmental_management.svg';
import HabitatProtection from './habitat_protection.svg';
import WasteManagement from './waste_management.svg';
import WaterResourcesManagement from './water_resources_management.svg';
// import AnimalWelfare from './animal_welfare.svg';
import CommunityDevelopment from './community_development.svg';
import ConsumerRightsDataPrivacy from './consumer_rights_data_privacy.svg';
import ConsumerRightsProtection from './consumer_rights_protection.svg';
import ConsumerRightsSatisfaction from './consumer_rights_satisfaction.svg';
import HumanCapitalDiversity from './human_capital_diversity.svg';
import HumanCapitalHumanRights from './human_capital_human_rights.svg';
import HumanCapitalSafetyAndHealth from './human_capital_safety_health.svg';
import HumanCapitalWorkingConditions from './human_capital_working_conditions.svg';
import HumanRightsDiversity from './human_rights_diversity.svg';
import StakeholdersManagement from './stakeholders_management.svg';
import SupplyChainManagement from './supply_chain_management.svg';
import AntiCompetition from './anti_competition.svg';
import AntiCorruption from './anti_corruption.svg';
import BusinessEthics from './business_ethics.svg';
import Management from './management.svg';
import ReportingAndTransparency from './reporting_transparency.svg';

// 'Air Emissions':
// 'Energy Management':
// 'Environmental Management':
// 'Habitat Protection':
// 'Waste Management':
// 'Water Resources Management':

export interface LogoRenderProps {
  iconKey: string | null;
}
export function handleIcon(iconKey: string | null) {
  switch (iconKey) {
    case 'Air Emissions':
      return AirEmissions;
    case 'Energy Management':
      return EnergyManagement;
    case 'Environmental Management':
      return EnvironmentalManagement;
    case 'Habitat Protection':
      return HabitatProtection;
    case 'Waste Management':
      return WasteManagement;
    case 'Water Resources Management':
      return WaterResourcesManagement;
    // case 'Animal Welfare':
    //   return AnimalWelfare;
    case 'Community Development':
      return CommunityDevelopment;
    case 'Consumer Rights - Data Privacy':
      return ConsumerRightsDataPrivacy;
    case 'Consumer Rights - Protection':
      return ConsumerRightsProtection;
    case 'Consumer Rights - Satisfaction':
      return ConsumerRightsSatisfaction;
    case 'Human Capital - Diversity':
      return HumanCapitalDiversity;
    case 'Human Capital - Human Rights':
      return HumanCapitalHumanRights;
    case 'Human Capital - Safety & Health':
      return HumanCapitalSafetyAndHealth;
    case 'Human Capital - Working Conditions':
      return HumanCapitalWorkingConditions;
    case 'Human Rights - Diversity':
      return HumanRightsDiversity;
    case 'Stakeholders Management':
      return StakeholdersManagement;
    case 'Supply Chain Management':
      return SupplyChainManagement;
    case 'Anti-Competition':
      return AntiCompetition;
    case 'Anti-Corruption':
      return AntiCorruption;
    case 'Business Ethics':
      return BusinessEthics;
    case 'Management':
      return Management;
    case 'Reporting & Transparency':
      return ReportingAndTransparency;
    default:
      return AirEmissions;
  }
}

const ESGIconRenderer: FC<LogoRenderProps> = ({ iconKey }) => {
  const Logo = handleIcon(iconKey);

  return (
    <img
      alt={`${iconKey} icon`}
      style={{
        height: '12rem',
        opacity: 0.3,
      }}
      src={Logo}
    />
  );
};

export default ESGIconRenderer;
