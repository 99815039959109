import React, { FC } from 'react';
import HistoricalTable from './table/table.component';
import HistoricalLineChart from './chart/chart.component';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import { ValuesType } from '../controlBar/controlBar.component';

export interface IDataViewProps {
  inputData: DataObject;
  fourShowing: boolean;
  showTable: boolean;
  keysAndDates: ValuesType;
  external: boolean;
  adjustedHeight: number;
  dimensions: { width: number; height: number };
}

const HistoricalDataView: FC<IDataViewProps> = (props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {props.inputData ? (
        !props.showTable ? (
          <HistoricalTable
            external={props.external}
            datesAndKeys={props.keysAndDates}
            dimensions={props.dimensions}
            inputData={props.inputData}
            height={props.adjustedHeight}
          />
        ) : (
          <HistoricalLineChart
            fourShowing={props.fourShowing}
            height={props.adjustedHeight}
            dimensions={props.dimensions}
            inputData={props.inputData}
            datesAndKeys={props.keysAndDates}
          />
        )
      ) : null}
    </div>
  );
};

export default HistoricalDataView;
/*
 *
 *

 * */
