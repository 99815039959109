import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import FirstLevelTable from './srriShareClassOverviewComponents/FirstLevelTable.component';

const overviewData = {
  isFetching: false,
  error: null,
  data: [
    [
      'ID',
      'Fund Name',
      'Fund Type',
      'Share Class Gmid',
      'Share Class Name',
      'Share Class Currency',
      'Share Class ISIN',
      'Is Default Share Class',
      'Published SRRI',
      'Calculated SRRI',
      'Calculated Volatility',
      'Calculation Date',
      'Week of Deviation',
    ],
    [
      'gem102',
      'EM Absolute Debt Fund',
      'Absolute Return Fund',
      62172000568,
      'EURI',
      'EUR',
      'LU0501220775',
      'No',
      5,
      4,
      0.14755639912474264,
      '2021-08-25',
      0,
    ],
    [
      'gem102',
      'EM Absolute Debt Fund',
      'Absolute Return Fund',
      62172000559,
      'EURR',
      'EUR',
      'LU0501220858',
      'No',
      5,
      5,
      0.14755639912474264,
      '2021-08-25',
      0,
    ],
    [
      'gem102',
      'EM Absolute Debt Fund',
      'Absolute Return Fund',
      63112000709,
      'USDI',
      'USD',
      'LU1209899795',
      'Yes',
      5,
      5,
      0.14755639912474264,
      '2021-08-25',
      0,
    ],
    [
      'gem103',
      'EM Debt Fund',
      'Market Fund',
      62132000023,
      'CHFI',
      'CHF',
      'LU1750992635',
      'No',
      4,
      4,
      0.08566185234503126,
      '2021-08-25',
      0,
    ],
    [
      'gem103',
      'EM Debt Fund',
      'Market Fund',
      62172000564,
      'EURI',
      'EUR',
      'LU0616502026',
      'No',
      4,
      4,
      0.08564190477811716,
      '2021-08-25',
      0,
    ],
    [
      'gem103',
      'EM Debt Fund',
      'Market Fund',
      63112000710,
      'USDI',
      'USD',
      'LU1209901104',
      'Yes',
      4,
      4,
      0.08550519040040906,
      '2021-08-25',
      0,
    ],
    [
      'gem201',
      'Conning Global High Dividend Equity Fund',
      'Market Fund',
      63112000712,
      'USDI',
      'USD',
      'LU2073870458',
      'Yes',
      6,
      5,
      0.15092139782251118,
      '2021-08-25',
      0,
    ],
  ],
};

// srri_overview_data
const SrriShareClassOverview: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const { positionDate } = props;

  const srriOverviewData = positionDate
    ? useFetchData({
        url: `srri_overview_data/${positionDate}`,
        keyName: `srri_overview_data_${positionDate}`,
        makeFetch: true,
      })
    : useFetchData({
        url: `srri_overview_data/${formatDateForCheckingState(new Date())}`,
        keyName: 'srri_overview_data',
        makeFetch: true,
      });

  return (
    <GeneralComponentErrorShield dataObjects={[srriOverviewData]}>
      <FirstLevelTable data={srriOverviewData} />
    </GeneralComponentErrorShield>
  );
};

export default SrriShareClassOverview;
