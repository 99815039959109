import React, { FC } from 'react';
import { EditorTileProps } from './PriipsKidsEditorColumn.component';
import {
  ContentState,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import StyleFormatButtons from '../../../components/buttons/StyleFormatButtons.component';
import makeStyles from '@mui/styles/makeStyles';
import PriipsKidsFieldTypeSelect from '../../buttons/PriipsKidsFieldTypeSelect.component';
import { PriipsKidsData } from '../KidsEditor';
import UtilityButtons from '../../../components/buttons/UtilityButtons.component';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    border: '1px solid',
    // borderColor: mainColors.mainBlue,
    borderColor: 'black',
    marginTop: '2rem',
    backgroundColor: 'white',
    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const TextEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Create an editor state object that will store the current text and allow it to be edited
  // Convert the conteny to html
  const blocksFromHTML = convertFromHTML(priipsKidsElement.content);
  // Now create cintent state object from the html
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
  );
  // Store the initial state
  const initialState = EditorState.createWithContent(contentState);
  // Create a state object to store the editor state
  const [editorState, setEditorState] =
    React.useState<EditorState>(initialState);
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field id
  const fieldId = priipsKidsElement.fieldId;

  // Create a function for handling text changes
  const handleTextChange = (updatedEditorState: EditorState) => {
    // Update data
    setEditorState(updatedEditorState);
    // Get The text from the editor state
    let text = stateToHTML(updatedEditorState.getCurrentContent());
    // Format some of the html tags to fit in with the pdf generator expected format
    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = text;
      return newContent;
    });
  };

  // Function for han handling keyboard shorthcuts for styling
  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // Create a function for handling key commands
  const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    // Apply the desired inline style to the highlighted text
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    // Get the updated state as plain text
    let text = stateToHTML(editorState.getCurrentContent());
    // Format some of the html tags to fit in with the pdf generator expected format
    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = text;
      return newContent;
    });
  };

  const classes = useStyles();

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.formatContainer}>
        <StyleFormatButtons handleTogggleClick={handleTogggleClick} />
        <PriipsKidsFieldTypeSelect
          initialType={priipsKidsElement.tag}
          updateFieldType={updateFieldType}
          index={priipsKidsElement.kiidIndex}
        />
      </div>
      <div className={classes.editorContainer}>
        <div className={classes.textEditorBox}>
          <Editor
            editorState={editorState}
            onChange={(updatedEditorState: EditorState) => {
              handleTextChange(updatedEditorState);
            }}
            stripPastedStyles={true}
            handleKeyCommand={handleKeyCommand}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
        />
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default TextEditorTile;
