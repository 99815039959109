import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import AssetLevelTable from './AssetLevelTable.component';
import { IFundLevelTableData } from './FundSummaryContainer.component';

const AssetLevelTableWrapper = () => [
  (rowData: IFundLevelTableData) => {
    const icon = () => (rowData.assets.length ? <KeyboardArrowRight /> : <></>);
    const render = () => <AssetLevelTable data={rowData.assets} />;
    return {
      disabled: !rowData.assets.length,
      icon,
      openIcon: KeyboardArrowDown,
      render,
    };
  },
];

export default AssetLevelTableWrapper;
