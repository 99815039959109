// function to check if a string is a valid number
export function isValidNumber(value: string) {
  const num = Number(value);
  return !isNaN(num);
}

export const isPrimitive = (value: any): boolean => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol'
  );
};
