import { UnfoldLess } from '@mui/icons-material';
import ArticleScannerSecondLevelTable from './ArticleScannerSecondLevelTable.component';
import { ArticleScannerTableData } from './ArticleScannerTable.component';

const ArticleScannerSecondLevelTableWrapper = (nav: number) =>
  // current: boolean,
  // activeFund: { fundId: string; fundName: string }
  [
    (rowData: ArticleScannerTableData) => {
      const render = () => (
        <ArticleScannerSecondLevelTable data={rowData.positions} nav={nav} />
      );
      return {
        disabled: !rowData.positions.length,
        openIcon: UnfoldLess,
        render,
      };
    },
  ];

export default ArticleScannerSecondLevelTableWrapper;
