import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { specifyStatus } from '../../../../../utilities/generalMappings';
import GenericStatusCell from '../../../../tables/GenericStatusCell';

export interface IRiskComparisonTablePositionChangesData {
  number: number;
  genericMarket: string;
  instrument: string;
  info: string;
  positionReference: number | string;
  comparisonPosition: string;
  market: number | string;
  risk: number;
  portfolio: number | string;
  status: string;
}
export const csvPosition = [
  'number',
  'genericMarket',
  'instrument',
  'info',
  'positionReference',
  'comparisonPosition',
  'market',
  'risk',
  'portfolio',
  'status',
];

export const positionChangesColumns: CustomColumn<IRiskComparisonTablePositionChangesData>[] =
  [
    {
      field: 'number',
      title: '#',
      width: '0.3rem',
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'genericMarket',
      title: 'Asset Name',
      width: '36rem',
    },
    {
      field: 'instrument',
      title: 'Instrument Type',
      // width: '6rem',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'info',
      title: 'Maturity',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'positionReference',
      title: 'Old Position',
      // width: '20rem',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },

    {
      field: 'comparisonPosition',
      title: 'New Position',
      // width: '20rem',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },

    {
      field: 'market',
      title: 'Market Price',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'status',
      title: 'Status',
      render: (rowData) => (
        <GenericStatusCell status={specifyStatus(rowData.status)} />
      ),
      cellStyle: {
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '30rem',
    },
  ];
