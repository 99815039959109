import { Typography } from '@mui/material';
import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface NotFoundProps {
  section: string;
  positionDate?: string;
}

const NotFound: FC<RouteComponentProps<NotFoundProps>> = () => {
  return <Typography variant="h1">Error 404 Page not found</Typography>;
};

export default NotFound;
