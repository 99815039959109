import { UnfoldLess } from '@mui/icons-material';
import { CurrencyVsFxTableData } from './CurrencyVsFxTable.component';
import SecondLevelTable from './SecondLevelTable.component';

const SecondLevelTableWrapper = () => [
  (rowData: CurrencyVsFxTableData) => {
    const render = () => (
      <SecondLevelTable
        title={'Underling Assets'}
        data={rowData.underlyingAssets}
      />
    );
    return {
      openIcon: UnfoldLess,
      render,
      disabled: rowData.underlyingAssets.length ? false : true,
    };
  },
];

export default SecondLevelTableWrapper;
