import {
  ColumnData,
  FilterStateType,
  MinMaxStateType,
  PresetData,
  SortingStateType,
} from './DataTypes';

// these are the default column selections that will be shown to the clients
// there are some values available in the data which have been commented out so as to not give them as an option to the client
const standardColumns: ColumnData[] = [
  {
    field: 'asset',
    title: 'Asset',
    show: true,
  },
  {
    field: 'isin',
    title: 'ISIN',
    show: true,
  },
  {
    field: 'sector',
    title: 'Sector',
    show: true,
  },
  {
    field: 'asset_class',
    title: 'Asset Class',
    show: true,
  },
  {
    field: 'currency',
    title: 'Currency',
    show: true,
  },
  {
    field: 'position',
    title: 'Position',
    show: true,
  },
  {
    field: 'market_price',
    title: 'Market Price',
    show: true,
  },
  {
    field: 'exposure',
    title: 'Exposure',
    show: true,
  },
  {
    field: 'gross_exposure',
    title: 'Gross Exposure',
    show: true,
  },
  {
    field: 'gross_exposure_pc',
    title: 'Gross Exposure %',
    show: true,
  },
  {
    field: 'proxified',
    title: 'Proxified',
    show: true,
  },
  {
    field: 'environmental',
    title: 'Environmental',
    show: true,
  },
  {
    field: 'social',
    title: 'Social',
    show: true,
  },
  {
    field: 'governance',
    title: 'Governance',
    show: true,
  },
  {
    field: 'esg',
    title: 'ESG',
    show: true,
  },
];

const noSortingState: SortingStateType = {};
const noFilterState: FilterStateType = {};
const noMinMaxState: MinMaxStateType = {};

const StandardPreset: PresetData = {
  id: 'standard',
  name: 'Standard',
  columns: standardColumns,
  sortingState: noSortingState,
  filterState: noFilterState,
  minMaxState: noMinMaxState,
};

export default StandardPreset;
