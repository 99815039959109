import { Select } from '../../../types/redux/pages/PagesStore';

/**
 * This function is used when you want to change one of the titlebar select values
 */
function updateRelevantTitleBarSelect({
  selectKey,
  newValue,
  selects,
}: {
  selectKey: string;
  newValue: string;
  selects: Select[];
}) {
  // We go over the selects
  const newSelects = [...selects];
  newSelects.forEach((select: Select) => {
    // if the key matches the one we're looking for, we update the value
    if (select.titleBarKey === selectKey) {
      select.currentValue = newValue;
    }
  });

  return newSelects;
}

export default updateRelevantTitleBarSelect;
