import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  generalCardRoot: {
    background: 'white',
    borderRadius: 8,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    width: (props) => props.width,
  },
}));

interface Props {
  height?: number | string;
  width?: number | string;
  cardStyle?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
}

interface StyleProps {
  height: number | string;
  width: number | string;
}

const GeneralCard: FunctionComponent<Props> = ({ children, ...props }) => {
  const styleProps = {
    width: props.width || '100%',
    height: props.height || 'auto',
  };
  const classes = useStyles(styleProps);

  return (
    <div
      style={props.cardStyle}
      className={clsx(classes.generalCardRoot, props.className)}
    >
      {children}
    </div>
  );
};

export default GeneralCard;
