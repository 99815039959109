import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import CashFlowStressTestsTable from './CashFlowStressTestsTable.component';
import { Grid } from '@mui/material';

const CashflowStressTests: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const cashFlowStressTestsData = useFetchData({
    url: `dcf_stress_test_overview/${fundId}/${positionDate}`,
    keyName: `dcf_stress_test_overvie_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  return (
    <Grid container>
      <GeneralComponentErrorShield
        dataObjects={[cashFlowStressTestsData]}
        customLoadingMessages={['Loading Private Equity Data...']}
        customErrorMessage="No Data Available."
      >
        <CashFlowStressTestsTable data={cashFlowStressTestsData} />
      </GeneralComponentErrorShield>
    </Grid>
  );
};

export default CashflowStressTests;
